import { Route } from "react-router-dom";
import { ProtectedRoute } from "Components/Routers";
import Browse from "./Browse";
import New from "./New";

export default [
  <Route
    key="invites-browse"
    path="/invites"
    element={<ProtectedRoute admin component={Browse} />}
  />,
  <Route key="invite-one" path="/invites/new" element={<ProtectedRoute admin component={New} />} />,
  <Route
    key="invite-many"
    path="/invites/bulk"
    element={<ProtectedRoute admin isBulk component={New} />}
  />,
];
