import ModalBackdrop from "./ModalBackdrop";
import Modal from "./Modal";
import ModalButton from "./Buttons/ModalButtons";

const ForcedOffDutyModal = ({ hide }) => {
  return (
    <ModalBackdrop>
      <Modal title="You are now off duty" closeModal={hide}>
        <div className="modal-idle-copy">
          You&apos;ve been marked as off duty due to inactivity.
        </div>

        <div className="modal-footer modal-idle-footer d-flex">
          <ModalButton.Submit onClick={hide} text="Got it." />
        </div>
      </Modal>
    </ModalBackdrop>
  );
};

export default ForcedOffDutyModal;
