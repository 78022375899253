import { camelCaseKeys } from "util/string";

export default (axios, errorHandler) => {
  // QueueScheduleService is outside of api namespace, so needs custom baseUrl
  const requestConfig = {
    baseURL: process.env.REACT_APP_SERVER + "/queue_schedule_service",
  };

  const getLearnerQueueSchedule = () => {
    return axios
      .get("/learner_queue_schedule", requestConfig)
      .then(response => camelCaseKeys(response.data.queue_schedule))
      .catch(errorHandler);
  };

  return {
    getLearnerQueueSchedule,
  };
};
