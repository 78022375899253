export default (axios, errorHandler) => {
  const getLocations = () => {
    return axios
      .get(`/locations`)
      .then(response => {
        return response.data.locations || [];
      })
      .catch(err => {
        errorHandler(err);
      });
  };

  return {
    getLocations,
  };
};
