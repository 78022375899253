import "./ModalButtons.scss";

const Back = ({ text, onClick, disabled }) => {
  return (
    <button className={"modal-btn back"} onClick={onClick} disabled={disabled}>
      {text || "Back"}
    </button>
  );
};

const Submit = ({ text, onClick, disabled }) => {
  return (
    <button className="modal-btn submit" onClick={onClick} disabled={disabled}>
      {text || "Submit"}
    </button>
  );
};

const Close = ({ closeModal }) => {
  return (
    <button type="button" className="close mentor" data-cy="modal-close" onClick={closeModal}>
      <span aria-hidden="true">&times;</span>
      <span className="sr-only">Close</span>
    </button>
  );
};

const Toggle = ({ text, onClick }) => {
  return (
    <button className="modal-btn toggle" onClick={onClick}>
      {text || ""}
    </button>
  );
};

const Delete = ({ text = "", onClick, disabled }) => {
  return (
    <button className="modal-btn delete" onClick={onClick} disabled={disabled}>
      {text} <i className="fa fa-fw fa-trash" />
    </button>
  );
};

const Edit = ({ text = "", icon = null, onClick, disabled }) => {
  return (
    <button className="modal-btn edit" onClick={onClick} disabled={disabled}>
      {text} {icon && <i className={icon} />}
    </button>
  );
};

const Outline = ({ text = "", type, onClick, disabled }) => {
  return (
    <button
      className={`modal-btn outline ${type || "primary"}`}
      onClick={onClick}
      disabled={disabled}
    >
      {text}
    </button>
  );
};

const ModalButton = {
  Back,
  Submit,
  Close,
  Toggle,
  Delete,
  Edit,
  Outline,
};

export default ModalButton;
