import logo from "../Assets/images/lhl-logo.svg";
import "../Layouts/Layouts.scss";

const NotFound = () => {
  return (
    <div className="center-page-content">
      <h1 className="oh-snap">404</h1>
      <p>Sorry, we couldn&apos;t find what you were looking for.</p>
      <img className="med-logo" src={logo} alt="lighthouse labs logo"></img>
    </div>
  );
};

export default NotFound;
