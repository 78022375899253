import { useAppContext } from "Context/AppProvider";
import PaddedContent from "Layouts/PaddedContent";
import TaskCreationForm from "../TaskCreationForm.jsx";

const TaskCreationFormQueue = () => {
  const {
    currentUser,
    tasksHandler: { error },
  } = useAppContext();

  return (
    <PaddedContent>
      {error && (
        <div className="alert alert-danger">
          <strong>{error}</strong>
        </div>
      )}
      <div className="queue-by-location queue-show">
        {currentUser.isAdmin && <TaskCreationForm user={currentUser} />}
      </div>
    </PaddedContent>
  );
};

export default TaskCreationFormQueue;
