import ModalBackdrop from "Components/ModalBackdrop";
import "Assets/styles/scrollable-modal.scss";
import { isBrowserExtension } from "util/env";
import { ModalTitle } from "Components/Typography/Heading";

const CompletionModal = () => {
  return (
    <ModalBackdrop>
      <div className="modal-dialog" style={{ marginTop: isBrowserExtension() ? "2rem" : "5rem" }}>
        <div className="modal-content">
          <div className="modal-header">
            <ModalTitle>Feedback Submitted Successfully!</ModalTitle>
          </div>
          <div className="modal-body">
            <p>Your feedback has been successfully submitted. You may now close this tab.</p>
            <p>
              <strong>Thank you!</strong>
            </p>
          </div>
        </div>
      </div>
    </ModalBackdrop>
  );
};

export default CompletionModal;
