import { useEffect, useReducer } from "react";
import {
  selectAllOpen,
  selectAllOpenForUser,
  selectGeneralRequests,
  selectAIOnlyTasks,
  selectInProgress,
  tasksWithUpdates,
  selectFeedbacks,
  selectAIFeedbacks,
  selectPendingFeedbacks,
  selectPendingAIFeedbacks,
  selectNeedsReview,
  selectAllOpenSecondaryTasks,
  selectAllOpenSecondaryTasksForUser,
} from "Selectors/queue_selectors";
import _ from "lodash/core";
import requests from "Requests";
import { startTransaction } from "util/instrumentation";

const initialState = {
  lastPendingFeedback: null,
  tasks: [],
  pendingArs: [],
  error: null,
};

const taskReducer = (state, action) => {
  switch (action.type) {
    case "lastPendingFeedback":
      return { ...state, lastPendingFeedback: action.data };
    case "setTasks": {
      const tasks = action.tasks.reduce((result, item) => ({ ...result, [item.id]: item }), {});
      return { ...state, tasks, pendingArs: action.pendingArs };
    }
    case "claimAssistance":
    case "startAssistance":
      return {
        ...state,
        pendingArs: state.pendingArs.filter(ar => ar.id !== action.assistanceRequestId),
      };
    case "refreshPendingArs":
      return { ...state, pendingArs: action.pendingArs };
    case "skipRequest": {
      const { [action.taskId]: _, ...remainingTasks } = state.tasks;
      return { ...state, tasks: remainingTasks };
    }
    case "addUpdates":
      return { ...state, tasks: tasksWithUpdates(state.tasks, action.data) };
    case "error":
      return { ...state, error: action.data };
    default:
      throw new Error();
  }
};

/**
 * @param {Object[]} updates - queueSocket.queueUpdates - Array of QueueTask objects.
 *   Updates any time a "queueUpdate" socket message is received.
 * @param {Object} user - The current user
 *   Note: Starts off as the initial state of useCurrentUser.jsx, then resolves to the actual user object.
 * @param {Object[]} pendingArs Updates to the pending ARs as received through the socket
 */
const useTasks = (updates, user, pendingArsFromSocket) => {
  const [taskState, dispatchTaskState] = useReducer(taskReducer, initialState);

  const fetchData = async () => {
    try {
      const { task } = await requests.getLastTaskWithPendingFeedback();
      if (task) dispatchTaskState({ type: "lastPendingFeedback", data: task });
    } catch {
      dispatchTaskState({
        type: "error",
        data: "Unable to retrieve task with pending feedback!",
      });
    }

    try {
      const { tasks, pendingArs } = await requests.getTasks();
      dispatchTaskState({
        type: "setTasks",
        tasks: _.sortBy(tasks, "startAt"),
        pendingArs,
      });
    } catch {
      dispatchTaskState({
        type: "error",
        data: "Could not fetch queue data!",
      });
    }
  };

  useEffect(() => {
    if (user.uid) fetchData();
  }, [user.uid]);

  useEffect(() => {
    dispatchTaskState({ type: "addUpdates", data: updates });
  }, [updates]);

  useEffect(() => {
    dispatchTaskState({ type: "refreshPendingArs", pendingArs: pendingArsFromSocket });
  }, [pendingArsFromSocket]);

  const arrayOfTasks = () => _.sortBy(Object.values(taskState.tasks), "startAt");

  /**
    Queue selectors
  **/

  // Returns all pending resource requests with type Project Evaluation
  const pendingEvaluations = () => selectGeneralRequests(arrayOfTasks());

  // Returns all in progress queue tasks
  const inProgress = () => selectInProgress(arrayOfTasks()).reverse();

  const inProgressAIOnly = () => selectAIOnlyTasks(arrayOfTasks().reverse());

  // Returns all the pending tasks / ARs that are assigned to this teacher.
  const pendingAssistanceRequests = () => taskState.pendingArs;

  // Returns all open (assigned) routed ARs
  const allOpenTasks = () => selectAllOpen(arrayOfTasks(), user);

  // Returns all open (assigned) routed ARs assigned to this teacher
  const allOpenTasksForUser = () => selectAllOpenForUser(arrayOfTasks(), user);

  // Returns all assistances that have not received feedback
  const pendingFeedbacks = () => {
    return [...selectPendingFeedbacks(arrayOfTasks()), ...selectPendingAIFeedbacks(arrayOfTasks())];
  };

  // Returns all open (assigned) task requests
  const allOpenSecondaryTasks = () => selectAllOpenSecondaryTasks(arrayOfTasks(), user);

  // Returns all open (assigned) task requests assigned to this teacher
  const allOpenSecondaryTasksForUser = () =>
    selectAllOpenSecondaryTasksForUser(arrayOfTasks(), user);

  // Returns all feedbacks
  const feedbacks = () => {
    return [...selectFeedbacks(arrayOfTasks()), ...selectAIFeedbacks(arrayOfTasks())].sort(
      (a, b) => b.startAt - a.startAt
    );
  };

  // Returns the last assistance that has not received feedback
  const lastPendingFeedback = () => taskState.lastPendingFeedback;

  // Returns all assistances for a teacher that have been finished but not reviewed
  const needsReview = () => selectNeedsReview(arrayOfTasks(), user.uid);

  const startAssistance = assistanceRequestId =>
    dispatchTaskState({ type: "startAssistance", assistanceRequestId });

  const claimAssistance = assistanceRequestId => {
    startTransaction("e2e-claimAR", assistanceRequestId);
    dispatchTaskState({ type: "claimAssistance", assistanceRequestId });
  };

  const skipRequest = taskId => dispatchTaskState({ type: "skipRequest", taskId });

  return {
    pendingEvaluations,
    inProgress,
    inProgressAIOnly,
    pendingAssistanceRequests,
    allOpenTasks,
    allOpenTasksForUser,
    pendingFeedbacks,
    lastPendingFeedback,
    feedbacks,
    startAssistance,
    claimAssistance,
    needsReview,
    skipRequest,
    allOpenSecondaryTasks,
    allOpenSecondaryTasksForUser,
    error: taskState.error,
  };
};

export default useTasks;
