import QueueItem from "Components/QueueItem";
import StudentInfo from "Components/StudentInfo";
import RequestDetails from "Components/RequestDetails";
import AssistanceActions from "./AssistanceActions";
import { confirm } from "Components/Confirmation";
import { useAppContext } from "Context/AppProvider";
import { toast } from "react-toastify";
import { TOAST_OPTIONS } from "constants/toast";

const AIAssistance = ({ admin, task }) => {
  const {
    queueChannel,
    settingsHandler: { settings },
  } = useAppContext();

  const { assistanceRequest } = task;
  const student = assistanceRequest.requestor;
  const request = assistanceRequest.request;

  const handleCloseAssistance = async () => {
    const confirmed = await confirm(
      "Are you sure you would like to close this AI Assistance?",
      "Yes",
      "No"
    );
    if (confirmed) {
      queueChannel
        .closeAssistance(assistanceRequest.id)
        .catch(err => toast.error(err.response?.data?.error, TOAST_OPTIONS.error));
    }
  };

  return (
    admin && (
      <QueueItem
        label={"Larry AI-only"}
        type="Assistance"
        disabled={false}
        standOut={false}
        thickDividers={settings?.ui?.thickQueueItemDividers}
      >
        <StudentInfo
          student={student}
          showDetails={true}
          when={assistanceRequest.startAt}
          tags={assistanceRequest.tags}
          is_lead={false}
          excludeStudentTags
        />
        <div className="blurb">
          <RequestDetails
            categoryName={request.categoryName}
            day={request.info?.day}
            activityUrl={request.resourceLink}
            activityTitle={request.resourceName}
            reason={request.reason}
          />
        </div>
        <AssistanceActions
          assistanceRequest={assistanceRequest}
          type="AIOnly"
          finishResourceUrl={assistanceRequest.conferenceLink}
          handleCloseAssistance={handleCloseAssistance}
          admin={admin}
        />
      </QueueItem>
    )
  );
};

export default AIAssistance;
