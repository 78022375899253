import ModalBackdrop from "Components/ModalBackdrop";
import { ModalTitle } from "./Typography/Heading";

const IFrameModal = ({ title, src, hide, height }) => {
  return (
    <ModalBackdrop>
      <div className="modal-dialog" style={{ marginTop: "5rem" }}>
        <div className="modal-content">
          <div className="modal-header">
            {title && <ModalTitle>{title}</ModalTitle>}
            <button type="button" className="close" onClick={hide}>
              <span aria-hidden="true">&times;</span>
              <span className="sr-only">Close</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="ml-3">
              <iframe
                src={src}
                title={title}
                width="100%"
                height={height}
                frameBorder="0"
                sandbox="allow-same-origin allow-scripts allow-forms"
              />
            </div>
          </div>
        </div>
      </div>
    </ModalBackdrop>
  );
};

export default IFrameModal;
