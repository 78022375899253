import { useEffect } from "react";
import { useAppContext } from "Context/AppProvider";
import FeedbackButton from "Components/HelpDashboard/FeedbackButton";
import AssistanceStatusButton from "Components/AssistanceStatusButton";
import Spinner from "Components/Spinner";
import requests from "Requests";
import { OverlayTrigger, Tooltip, Button } from "react-bootstrap";
import SettingsConditional from "Components/SettingsConditional";
import PoweredBy from "Components/PoweredBy";

const HelpDashboard = ({ activity, token }) => {
  const {
    queueChannel: { pendingFeedbackCount, rejected },
    currentUser,
  } = useAppContext();

  useEffect(() => {
    if (token) requests.postIdToken(token).catch(err => console.log(err));
  }, [token]);

  return (
    <>
      <SettingsConditional flag={SettingsConditional.DASHBOARD_NOTE} />
      <div className="dashboard">
        {currentUser.isPresent ? (
          <>
            {currentUser.isStudent && (
              <>
                <OverlayTrigger
                  placement="bottom"
                  overlay={<Tooltip>{"Provide Feedback"}</Tooltip>}
                >
                  <button className="dashboard-button mr-2">
                    <FeedbackButton user={currentUser} count={pendingFeedbackCount} />
                  </button>
                </OverlayTrigger>
                <AssistanceStatusButton activity={activity} forDashboard={true} />
              </>
            )}
          </>
        ) : (
          <Button variant={`outline-${rejected ? "danger" : "warning"}`}>
            {rejected ? <i className="fa fa-fw fa-xmark" /> : <Spinner />}
          </Button>
        )}
      </div>

      <div className="center-page-content powered-by-holder">
        <PoweredBy />
      </div>
    </>
  );
};

export default HelpDashboard;
