// https://fkhadra.github.io/react-toastify/api/toast/
const baseOptions = {
  position: "top-center",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  pauseOnFocusLoss: true,
  draggable: true,
  progress: undefined,
  theme: "light",
};

export const TOAST_OPTIONS = {
  success: { ...baseOptions },
  info: { ...baseOptions },
  warn: { ...baseOptions },
  error: { ...baseOptions },
};
