import Feedback from "./Feedback";
import AIFeedback from "./AIFeedback";
import ListGroup from "Components/ListGroup";

const FeedbackList = ({ tasks, title }) => {
  return (
    <ListGroup icon={tasks.length} title={title}>
      {tasks.map(task =>
        task.assistance ? (
          <Feedback key={`request-${task.id}`} task={task} />
        ) : (
          <AIFeedback key={`request-${task.id}`} task={task} />
        )
      )}
    </ListGroup>
  );
};

export default FeedbackList;
