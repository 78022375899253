import { Navbar } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./Navbar.scss";
import { useAppContext } from "Context/AppProvider";

const MainNavigation = ({ children }) => {
  const {
    currentUser,
    settingsHandler: { isLoading: loading, settings },
  } = useAppContext();

  const logoTitle = settings?.ui?.whiteLabel?.logoTitle || "Rudder by Lighthouse Labs";

  return (
    !loading && (
      <Navbar className="lhl-nav" bg="dark" data-bs-theme="dark" expand="md" variant="dark">
        <div className="lhl-nav-links">
          <Link to="/" title={logoTitle}>
            {settings.ui?.whiteLabel?.logoUrl ? (
              <img
                src={settings.ui?.whiteLabel?.logoUrl}
                style={{ height: 40 }}
                alt={settings.ui?.whiteLabel?.logoTitle}
              />
            ) : (
              <h1 className="brand">rudder</h1>
            )}
          </Link>
          {currentUser.isAdmin && (
            <Link to="/queue/admin" title="Admin Queue">
              <h1 className="admin-queue">admin</h1>
            </Link>
          )}
        </div>

        {children}
      </Navbar>
    )
  );
};

export default MainNavigation;
