import { Badge } from "react-bootstrap";
import "../Components.scss";

const HoverBadge = ({ title, pill, bg }) => {
  return (
    <div className="badge-container dashboard">
      <Badge pill={pill} bg={bg} text="light">
        {title}
      </Badge>
    </div>
  );
};

export default HoverBadge;
