import ModalBackdrop from "Components/ModalBackdrop";
import { Button } from "react-bootstrap";
import "./OAuthModal.scss";
import { Keys, getItem, setItem } from "../storage";
import { ModalTitle } from "./Typography/Heading";

const OAuthModal = ({ appName, authLink, message, hide }) => {
  const ignore = getItem(Keys[`IGNORE_${appName.toUpperCase()}_OAUTH`]);

  const onIgnore = () => {
    setItem(Keys[`IGNORE_${appName.toUpperCase()}_OAUTH`], true);
    hide();
  };

  return ignore ? null : (
    <ModalBackdrop blur center>
      <div className="modal-dialog" style={{ maxWidth: 600 }}>
        <div className="modal-content">
          <div className="modal-header">
            <ModalTitle>Authorize {appName}</ModalTitle>

            <button className="close" onClick={hide} type="button" data-dismiss="modal">
              <span aria-hidden="true">&times;</span>
              <span className="sr-only">Close</span>
            </button>
          </div>
          <div className="modal-body">
            <div dangerouslySetInnerHTML={{ __html: message }} />
            <div className="btns">
              <Button as="a" href={authLink}>
                Authorize
              </Button>
              <Button variant="danger" onClick={onIgnore}>
                Don&apos;t Authorize
              </Button>
            </div>
          </div>
        </div>
      </div>
    </ModalBackdrop>
  );
};

export default OAuthModal;
