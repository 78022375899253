import { useEffect, useRef } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import queryString from "query-string";
import UsersList from "./UsersList";
import useUsers from "Hooks/useUsers";
import PaddedContent from "Layouts/PaddedContent";
import { Form, InputGroup } from "react-bootstrap";
import ModalButton from "Components/Buttons/ModalButtons";
import Spinner from "Components/Spinner";
import Pagination from "Components/Pagination";
import { toInteger } from "lodash";
import "./Browse.scss";

const Browse = () => {
  const inputRef = useRef(null);
  const PAGINATION_LENGTH = 10;
  const { search } = useLocation();
  const { page, query } = queryString.parse(search);
  const navigate = useNavigate();
  const searchQuery = query || "";
  const pageNumber = /^[0-9]+$/.test(page) ? toInteger(page) : 1;

  const {
    data,
    updateUser: edit,
    isLoading,
    isUpdating,
    error,
    refetch,
  } = useUsers(pageNumber, searchQuery);

  useEffect(() => {
    refetch();
  }, [refetch, pageNumber, searchQuery]);

  const handleSearch = query => navigate(`?${queryString.stringify({ page: 1, query })}`);

  return (
    <PaddedContent>
      <div className="title-container">
        <h1>Users</h1>
        <Link to="/invites/new">
          <i className="fa fa-fw fa-plus" />
        </Link>
        <span className="loading-container">{isLoading && <Spinner />}</span>
      </div>
      {error ? (
        <p>Something went wrong. {error}</p>
      ) : (
        <>
          {data.last_page && (
            <Pagination
              routeKey={"/users"}
              pageNumber={pageNumber}
              lastPage={data.last_page}
              paginationLength={PAGINATION_LENGTH}
              ariaLabel="Users pagination"
            />
          )}
          <InputGroup className="mb-3">
            <Form.Control
              ref={inputRef}
              placeholder="Search by name or email..."
              aria-label="Search"
              aria-describedby="basic-addon2"
              onKeyPress={e => {
                if (e.key === "Enter") handleSearch(e.target.value.trim());
              }}
            />
            <ModalButton.Submit
              text="Search"
              onClick={() => handleSearch(inputRef.current.value.trim())}
            />
            <ModalButton.Back
              text="Clear"
              id="button-addon2"
              onClick={e => {
                e.preventDefault();
                inputRef.current.value = "";
                handleSearch(inputRef.current.value.trim());
              }}
            />
          </InputGroup>
          <UsersList users={pageNumber ? data?.users : data} edit={edit} isUpdating={isUpdating} />
        </>
      )}
    </PaddedContent>
  );
};

export default Browse;
