import PropTypes from "prop-types";
import { Modal } from "react-bootstrap";
import ModalButton from "./Buttons/ModalButtons";
import { confirmable, createConfirmation } from "react-confirm";
import "./Confirmation.scss";

export const Confirmation = ({
  okLabel = "OK",
  cancelLabel = "Cancel",
  title = "Confirmation",
  confirmation,
  show,
  proceed,
  enableEscape = true,
}) => {
  return (
    <div className="static-modal">
      <Modal
        animation={false}
        show={show}
        onHide={() => proceed(false)}
        backdrop={enableEscape ? true : "static"}
        keyboard={enableEscape}
      >
        <Modal.Header>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{confirmation}</Modal.Body>
        <Modal.Footer>
          <ModalButton.Back
            data-cy="confirm-no"
            text={cancelLabel}
            onClick={() => proceed(false)}
          />
          <ModalButton.Submit data-cy="confirm-yes" text={okLabel} onClick={() => proceed(true)} />
        </Modal.Footer>
      </Modal>
    </div>
  );
};

Confirmation.propTypes = {
  okLabel: PropTypes.string,
  cancelLabel: PropTypes.string,
  title: PropTypes.string,
  confirmation: PropTypes.string,
  show: PropTypes.bool,
  proceed: PropTypes.func, // called when ok button is clicked.
  enableEscape: PropTypes.bool,
};

export function confirm(confirmation, okLabel = "OK", cancelLabel = "cancel", options = {}) {
  return createConfirmation(confirmable(Confirmation))({
    confirmation,
    okLabel,
    cancelLabel,
    ...options,
  });
}
