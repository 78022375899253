export default (axios, errorHandler) => {
  const getInvites = (pageNumber, searchQuery, userType) => {
    const params = { page: pageNumber, search_query: searchQuery, user_type: userType };
    return axios
      .get("/invites", { params: params })
      .then(response => {
        return response.data;
      })
      .catch(err => {
        errorHandler(err);
      });
  };

  const createInvite = inviteData => {
    return axios
      .post("/invites", inviteData)
      .then(response => {
        return response.data;
      })
      .catch(err => {
        errorHandler(err);
      });
  };

  const createInvites = inviteData => {
    return axios
      .post("/bulk-invite", inviteData)
      .then(response => {
        return response.data;
      })
      .catch(err => {
        errorHandler(err);
      });
  };

  const deleteInvite = invite => {
    return axios
      .delete(`/invites/${invite.id}`)
      .then(response => {
        return response.data;
      })
      .catch(err => {
        errorHandler(err);
      });
  };

  return {
    getInvites,
    createInvite,
    createInvites,
    deleteInvite,
  };
};
