import { useEffect } from "react";
import { useAppContext } from "Context/AppProvider";
import { useNavigate } from "react-router-dom";
import { Tooltip, Button, OverlayTrigger } from "react-bootstrap";
import { confirm } from "Components/Confirmation";
import AIFeedbackModal from "Screens/feedback/Feedbacks/AIFeedbackModal";
import RequestModal from "../RequestModal";
import NoMentorModal from "../NoMentorModal";
import CancelRequestModal from "../CancelRequestModal";
import ViewRequestModal from "../ViewRequest/ViewRequestModal";
import SettingsConditional from "../SettingsConditional";
import ConferenceButton from "./ConferenceButton";
import ButtonContainer from "./ButtonContainer";
import MainAssistanceButton from "./MainAssistanceButton";
import useLearnerQueueSchedule from "Hooks/useLearnerQueueSchedule";
import { CLIENT_TYPES } from "constants/app";
import { DateTime } from "luxon";
import "../HelpDashboard/AssistanceButtons.scss";
import "../HelpDashboard/DashboardButton.scss";
import { useAssistanceModalContext } from "Context/AssistanceModalProvider";
import { inIframe } from "util/env";
import useRequestState from "Hooks/useRequestState";
import useExternalChannel from "Hooks/useExternalChannel";

const TIME_LIMIT_TO_DISPLAY_LAST_PENDING_FEEDBACK = 10;

const AssistanceStatusButton = ({ forDashboard }) => {
  const {
    tasksHandler: { lastPendingFeedback },
    queueChannel,
    currentUser,
    teacherHandler: { teachers },
    clientType,
    settingsHandler: { settings },
  } = useAppContext();

  const podUuid = currentUser.info?.pod_uuid;
  const { externalChannel } = useExternalChannel(podUuid);
  const { queueClosed, queueSchedule } = useLearnerQueueSchedule();
  const { RequestStatuses, requestState } = useRequestState();
  const navigate = useNavigate();
  const allowAIOnly = settings?.featureFlags?.isAIOnly;
  const isAIOnly = queueClosed && allowAIOnly;
  const inPrep = currentUser.inPrep;
  const chatAssistance = settings?.features?.chatAssistance;

  const {
    showCreateRequestModal,
    setShowCreateRequestModal,
    showNoMentorModal,
    setShowNoMentorModal,
    showViewRequestModal,
    setShowViewRequestModal,
    showCancelRequestModal,
    setShowCancelRequestModal,
    showFeedbackModal,
    setShowFeedbackModal,
    exitedFeedback,
    setExitedFeedback,
    showAIOnlyModal,
    setShowAIOnlyModal,
    showAIFeedbackModal,
    setShowAIFeedbackModal,
  } = useAssistanceModalContext();

  // show/hide the feedback modal based on updates received from queueChannel
  useEffect(() => {
    const latestUpdate = queueChannel.feedbackUpdates.slice(-1)[0];

    if (!inIframe() && latestUpdate?.assistance?.feedback === null) setShowFeedbackModal(true);

    if (
      !inIframe() &&
      isAIOnly &&
      latestUpdate?.assistanceRequest?.feedback === null &&
      latestUpdate?.assistanceRequest?.type === "UserRequest"
    ) {
      setShowAIFeedbackModal(true);
    }
  }, [setShowFeedbackModal, setShowAIFeedbackModal, queueChannel.feedbackUpdates, isAIOnly]);

  useEffect(() => {
    const pendingFeedback = lastPendingFeedback();
    if (
      !inIframe() &&
      pendingFeedback &&
      !exitedFeedback &&
      DateTime.now().diff(DateTime.fromISO(pendingFeedback?.assistance?.endAt)).as("minutes") <=
        TIME_LIMIT_TO_DISPLAY_LAST_PENDING_FEEDBACK
    ) {
      setShowFeedbackModal(true);
    }
  }, [lastPendingFeedback, exitedFeedback, setShowFeedbackModal]);

  const currentRequestState = requestState(queueChannel.requestUpdates.slice(-1)[0]);

  // checks if learner has no name and is using a client where profile can be edited (not embedded)
  // extend this definition if we want to require more profile fields before making ARs -Q 2022-06-28
  const profileIncomplete = clientType !== CLIENT_TYPES.embedded && !currentUser.fullName;

  const redirectToProfileEdit = () => navigate("/profile/edit?initial_setup=true");

  const cancelAssistance = async (reason, notes) => {
    const confirmed = await confirm(
      `Are you sure you want to ${isAIOnly ? "close this assistance" : "cancel this request"}?`,
      "Yes",
      "No"
    );

    if (confirmed) {
      queueChannel.cancelAssistanceRequest({
        id: currentRequestState.id,
        cancellationReason: reason,
        cancellationNotes: notes,
      });
    }
  };

  const finishRequest = async () => {
    const confirmed = await confirm(
      "Are you sure you want to end this in-progress request?",
      "Yes",
      "No"
    );

    if (confirmed) queueChannel.finishRequest({ id: currentRequestState.id });
  };

  const statusLookup = {
    [RequestStatuses.WAITING]: {
      onStop: () => setShowCancelRequestModal(true),
      buttonText: isAIOnly || inPrep ? "Close Assistance" : "Cancel Assistance",
      buttonTitle: allowAIOnly
        ? "Click to end this assistance."
        : "Click to remove yourself from the request queue.",
      statusText: "Your request is in the queue.",
      variant: "outline-danger",
    },
    [RequestStatuses.IN_PROGRESS]: {
      onStop: finishRequest,
      buttonText: "End In-Progress Assistance",
      buttonTitle: "Click to cancel your in-progress assistance.",
      statusText: `Your request is being addressed by ${currentRequestState.assistorName}.`,
      variant: "outline-warning",
    },
    [RequestStatuses.NONE]: {},
  };

  const { onStop, buttonText, buttonTitle, statusText, variant } =
    statusLookup[currentRequestState.status];

  const feedbackTask =
    queueChannel.feedbackUpdates.slice(-1)[0] || (!exitedFeedback && lastPendingFeedback());

  return (
    <ButtonContainer forDashboard={forDashboard}>
      {currentRequestState.conferenceLink && !!teachers.length && (
        <ConferenceButton
          conferenceType={currentRequestState.conferenceType}
          conferenceLink={currentRequestState.conferenceLink}
          assistorName={currentRequestState.assistorName}
          forDashboard={forDashboard}
          withText={forDashboard}
        />
      )}
      {currentRequestState.childConference && (
        <ConferenceButton
          conferenceType={currentRequestState.childConference.platform}
          conferenceLink={currentRequestState.childConference.link}
          assistorName={currentRequestState.assistorName}
          forDashboard={forDashboard}
          withText={forDashboard}
        />
      )}
      <MainAssistanceButton
        chatAssistance={chatAssistance}
        externalChannel={externalChannel}
        allowAsync={settings.features?.asyncAssistanceRequests}
        queueChannel={queueChannel}
        currentRequestState={currentRequestState}
        teachers={teachers}
        queueClosed={queueClosed}
        forDashboard={forDashboard}
        profileIncomplete={profileIncomplete}
        editProfile={redirectToProfileEdit}
        requestBeforeQueueEnabled={!!queueSchedule?.requestBeforeQueueEnabled}
        allowAIOnly={allowAIOnly}
        inPrep={inPrep}
      />
      {!forDashboard && (
        // since modals use context for state, don't render these modals under dashboard button
        // prevents stacked duplicates of modals on help dashboard
        // TODO: these should probably be rendered somewhere else, ideally, to avoid this
        <>
          {showCreateRequestModal && (
            <RequestModal
              queueChannel={queueChannel}
              hide={() => setShowCreateRequestModal(false)}
              onRequestSubmit={() => setShowViewRequestModal(true)}
              queueSchedule={queueSchedule}
            />
          )}
          {showNoMentorModal && <NoMentorModal hide={() => setShowNoMentorModal(false)} />}
          {showAIOnlyModal && (
            <RequestModal
              queueChannel={queueChannel}
              hide={() => setShowAIOnlyModal(false)}
              onRequestSubmit={() => setShowViewRequestModal(true)}
              queueSchedule={queueSchedule}
              isAIOnly={isAIOnly}
              inPrep={inPrep}
            />
          )}
          {showViewRequestModal && currentRequestState.request && (
            <ViewRequestModal
              assistanceRequest={currentRequestState}
              statusText={statusText}
              hide={() => setShowViewRequestModal(false)}
              queueClosed={queueClosed}
            >
              <ConferenceButton
                conferenceType={currentRequestState.conferenceType}
                conferenceLink={currentRequestState.conferenceLink}
                assistorName={currentRequestState.assistorName}
                withText={true}
              />
              {currentRequestState.childConference && (
                <ConferenceButton
                  conferenceType={currentRequestState.childConference.platform}
                  conferenceLink={currentRequestState.childConference.link}
                  assistorName={currentRequestState.assistorName}
                  withText={true}
                />
              )}
              <OverlayTrigger placement="bottom" overlay={<Tooltip>{buttonTitle}</Tooltip>}>
                <Button variant={variant} onClick={onStop}>
                  {buttonText}
                </Button>
              </OverlayTrigger>
            </ViewRequestModal>
          )}
          {showFeedbackModal && !forDashboard && (
            <SettingsConditional
              flag={SettingsConditional.EXTERNAL_FEEDBACK_FROM_LEARNER_URL}
              task={feedbackTask}
              hide={() => {
                setShowFeedbackModal(false);
                setExitedFeedback(true);
              }}
            />
          )}
          {showAIFeedbackModal && !forDashboard && (
            <AIFeedbackModal
              task={feedbackTask}
              hide={() => {
                setShowAIFeedbackModal(false);
                setExitedFeedback(true);
              }}
            />
          )}
          {showCancelRequestModal && (
            <CancelRequestModal
              cancel={cancelAssistance}
              hide={() => setShowCancelRequestModal(false)}
              isAIOnly={isAIOnly || inPrep}
            />
          )}
        </>
      )}
    </ButtonContainer>
  );
};

export default AssistanceStatusButton;
