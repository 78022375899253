import Navbar from "Components/Navbar/Navbar";
import NavActions from "./NavActions";

const MainNavigation = ({ hideActions }) => {
  return (
    <Navbar>
      <NavActions hideActions={hideActions} />
    </Navbar>
  );
};

export default MainNavigation;
