export const generateProfileUrl = ({ currentUser, studentUid, userId }) => {
  const { isAdmin, isSuperAdmin, isTeacher } = currentUser;

  if (isAdmin || isSuperAdmin || isTeacher) {
    // Generate the url for learners
    if (studentUid) return `/learners/${studentUid}`;

    if (isAdmin || isSuperAdmin) {
      // if the current user has the permissions, you can see the users/id profile
      if (userId) return `/users/${userId}`;
    }
  }

  // Defaults to nothing
  return;
};
