import { OverlayTrigger, Tooltip } from "react-bootstrap";
import "./SocialIcons.scss";

const socialUrls = {
  github: "http://github.com/",
  email: "mailto:",
  discord: "https://discord.com/users/",
};

const getSocialLink = (socialPlatform, handle) => {
  return socialUrls[socialPlatform] ? socialUrls[socialPlatform].concat(handle) : "#";
};

const SocialIcons = ({ user }) => {
  const icons = Object.entries(user.socials || {})
    .filter(([, handle]) => !!handle)
    .map(([socialPlatform, handle]) => {
      const icon = socialPlatform === "email" ? "inbox" : socialPlatform;
      const iconSet = socialPlatform === "email" ? "fas" : "fab";

      return (
        <Icon
          key={socialPlatform}
          tooltip={handle}
          iconSet={iconSet}
          icon={icon}
          url={getSocialLink(socialPlatform, handle)}
          label={socialPlatform}
        />
      );
    });

  return (
    <div className="icons-list">
      {icons}
      {user.profileLink && (
        <Icon
          key="lms"
          tooltip="LMS Profile"
          iconSet="fas"
          icon="user"
          url={user.profileLink}
          label="LMS Profile"
        />
      )}
    </div>
  );
};

const Icon = ({ iconSet, icon, tooltip, url, label = "" }) => {
  return (
    <div className="list-inline-item">
      <OverlayTrigger placement="bottom" overlay={<Tooltip>{tooltip}</Tooltip>}>
        <a
          className="social-anchor"
          href={url || "#"}
          target={url ? "_blank" : ""}
          rel="noreferrer"
          aria-label={label}
        >
          <span className="fa-stack fa-lg social-icon-muted">
            <i className="far fa-square fa-stack-2x" />
            <i className={`${iconSet} fa-${icon} fa-stack-1x`} style={{ top: -1 }} />
          </span>
        </a>
      </OverlayTrigger>
    </div>
  );
};

export default SocialIcons;
