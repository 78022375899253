const Pronoun = ({ pronoun }) => {
  if (pronoun) {
    return (
      <span title="Pronoun" className="pronoun">
        ({pronoun})
      </span>
    );
  }
  return null;
};

export default Pronoun;
