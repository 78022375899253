import { useAppContext } from "Context/AppProvider";
import Queue from "./queue/Queue";
import StudentIndex from "./student/StudentIndex";
import LandingPage from "./info/LandingPage";

const Home = () => {
  const { currentUser } = useAppContext();

  return (
    <>
      {currentUser.isTeacher ? (
        <Queue />
      ) : currentUser.isStudent ? (
        <StudentIndex />
      ) : (
        <LandingPage />
      )}
    </>
  );
};

export default Home;
