import { startCase } from "lodash";
import { routerDescriptions } from "constants/settingsDescriptions";
import ModalButton from "Components/Buttons/ModalButtons";

const QueueSettingsForm = ({ onSubmit, queueSettings, setQueueSettings }) => {
  const input = (name, value) => (
    <input
      className="form-control"
      type="number"
      step="1"
      name={name}
      onChange={e =>
        setQueueSettings(prevSettings => ({
          ...prevSettings,
          [name]: isNaN(e.target.value) ? "" : parseFloat(e.target.value),
        }))
      }
      value={value || 0}
      min={0}
      max={1000}
    />
  );

  const options = () => {
    if (!queueSettings) return <></>;

    return Object.keys(routerDescriptions)
      .filter(name => routerDescriptions[name].editable !== false)
      .map(name => {
        return (
          <div className="option" key={name}>
            <div className="description">
              <label>{startCase(name)}</label>
              <small>{routerDescriptions[name]["description"]}</small>
            </div>
            {input(name, queueSettings[name])}
          </div>
        );
      });
  };

  return (
    <div>
      <div className="d-flex mb-2 justify-content-between align-items-center">
        <h2>Queue Task Router Settings</h2>
      </div>
      <div>
        <form onSubmit={onSubmit} className="queue-weights">
          {options()}
          <div className="option">
            <div className="description"></div>
            <ModalButton.Submit type="submit" text="Submit" />
          </div>
        </form>
      </div>
    </div>
  );
};

export default QueueSettingsForm;
