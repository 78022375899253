import { useState } from "react";
import classNames from "classnames";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const checkCollapsed = title => window.localStorage.getItem(`${title} - collapsed`) === "y";

const ListGroup = ({ children, icon, title, header, className }) => {
  const [collapsed, setCollapsed] = useState(checkCollapsed(title));

  const handleToggleCollapse = () => {
    setCollapsed(!collapsed);
    collapsed
      ? window.localStorage.setItem(`${title} - collapsed`, "n")
      : window.localStorage.setItem(`${title} - collapsed`, "y");
  };

  return (
    <div
      className={classNames("list-group", "card", "card-default", {
        collapsed: collapsed,
        open: !collapsed,
      })}
    >
      <div className="card-header-back"></div>
      <div className="card-header-border"></div>
      <div className="card-header" onClick={handleToggleCollapse}>
        <h4 className="card-title">
          {icon !== null && (
            <>
              {title === "Assigned Secondary Tasks" ? (
                <OverlayTrigger
                  placement="bottom"
                  overlay={
                    <Tooltip id="task-count-tooltip">
                      This counter indicates the total number of assigned tasks available to you.
                      Select from the tasks shown to you, or if necessary, skip a displayed task to
                      view the next available task in your queue. Please work on these tasks when
                      there are no student assistance requests in the queue. These secondary tasks
                      do not mark you as "busy" and should not be given precedence over assisting
                      students.
                    </Tooltip>
                  }
                >
                  <span className="count">{icon}</span>
                </OverlayTrigger>
              ) : (
                <span className="count">{icon}</span>
              )}
            </>
          )}
          <span className="title">{title}</span>
        </h4>
        {header}
      </div>
      <div className={`list-group ${className}`}>{children}</div>
    </div>
  );
};

export default ListGroup;
