const UsedVideoConferenceInput = ({ field, disabled }) => {
  const radioLabels = {
    0: "only via chat",
    1: "via chat and video",
  };
  const { value } = field;
  return (
    <div className="d-flex justify-content-between">
      {Object.keys(radioLabels).map(key => {
        return (
          <div key={key} className="form-check form-check-inline">
            <input
              className="form-check-input"
              name="requestAccuracy"
              type="radio"
              id={`used-video-option-${key}`}
              {...field}
              disabled={disabled}
              value={key}
              checked={value === key}
            />
            <label className="form-check-label" htmlFor={`used-video-option-${key}`}>
              {radioLabels[key]}
            </label>
          </div>
        );
      })}
    </div>
  );
};

export default UsedVideoConferenceInput;
