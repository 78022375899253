import { useState } from "react";
import QueueItem from "Components/QueueItem";
import StudentInfo from "Components/StudentInfo";
import TaskMasterInfo from "Components/TaskMasterInfo";
import RequestDetails from "Components/RequestDetails";
import SettingsConditional from "Components/SettingsConditional";
import Button from "Components/Buttons/AssistanceButtons";
import { confirm } from "Components/Confirmation";
import { useAppContext } from "Context/AppProvider";
import DelegateRequestModal from "./DelegateRequestModal";
import Requests from "Requests";
import FormResults from "Components/FormResults";
import { toast } from "react-toastify";
import { TOAST_OPTIONS } from "constants/toast";
import RequestPriority from "./RequestPriority";
import { startCase } from "lodash";

const getRequestType = type => {
  switch (type) {
    case "Evaluation":
      return "evaluations";
    case "CodeReview":
      return "code_reviews";
    case "UserRequest":
      return "assistance_requests";
    case "TaskRequest":
      return "task_requests";
    case "TechInterview":
      return "tech_interviews";
    case "ManualRequest":
      return "manual_requests";
    default:
      return "assistance_requests";
  }
};

const PendingAssistanceRequest = ({ task }) => {
  const {
    currentUser,
    queueChannel,
    tasksHandler: { claimAssistance },
    teacherHandler: { teachers },
    settingsHandler: { settings },
  } = useAppContext();
  const [disabled, setDisabled] = useState(false);
  const [showDelegateModal, setShowDelegateModal] = useState(false);
  const { assistanceRequest, assistors } = task;
  const [priority, setPriority] = useState(assistanceRequest.priority);
  const requestType = getRequestType(assistanceRequest.type);
  const isUserRequest = assistanceRequest.type === "UserRequest";
  const isEvaluation = assistanceRequest.type === "Evaluation";
  const isTaskRequest = assistanceRequest.type === "TaskRequest";
  const createdAt = new Date(assistanceRequest.createdAt);
  const twentyMinutesAgo = new Date(Date.now() - 20 * 60 * 1000); // 20 minutes in milliseconds
  const twentyFourHoursAgo = new Date(Date.now() - 24 * 60 * 60 * 1000); // 24 hours in milliseconds

  const isPriority =
    priority === -1 ||
    (isUserRequest && createdAt < twentyMinutesAgo) ||
    (isEvaluation && createdAt < twentyFourHoursAgo);

  const taskCategory =
    assistanceRequest.request.taskType ||
    assistanceRequest.categoryName ||
    startCase(assistanceRequest.request.project);

  const TeacherName = ({ index, assistor }) => {
    const teacher = teachers.find(teacher => teacher.uid === assistor.uid);

    const getTextColour = teacher => {
      if (!teacher) return "text-secondary";
      if (teacher.busy) return "text-warning";
      return "text-success";
    };

    const textColour = getTextColour(teacher);

    return (
      <span className={textColour}>
        {assistor && assistor.fullName}
        {assistors.length > index + 1 && ", "}
      </span>
    );
  };

  const handleClaimAssistance = () => {
    setDisabled(true);
    queueChannel
      .claimAssistance(assistanceRequest)
      .then(() => claimAssistance(assistanceRequest.id))
      .catch(err => {
        setDisabled(false);
        toast.error(err.response?.data?.error, TOAST_OPTIONS.error);
      });
  };

  const handleRemoveAssistanceRequest = async () => {
    const confirmed = await confirm(
      "Are you sure you want to remove this request from the queue?",
      "Remove",
      "Cancel"
    );

    if (confirmed) {
      setDisabled(true);
      queueChannel
        .cancelAssistanceRequest({
          ...assistanceRequest,
          cancellationReason: "admin",
        })
        .catch(err => {
          setDisabled(true);
          toast.error(err.response?.data?.error, TOAST_OPTIONS.error);
        });
    }
  };

  const handleDelegateRequest = async assistorUid => {
    await Requests.claimAssistanceRequestForTeacher(assistanceRequest.id, assistorUid).catch(err =>
      toast.error(err.response?.data?.error, TOAST_OPTIONS.error)
    );
    setShowDelegateModal(false);
  };

  const handlePrioritizeRequest = () => {
    queueChannel
      .prioritizeRequest(assistanceRequest.id)
      .then(val => setPriority(val))
      .catch(err => toast.error(err.response?.data?.error, TOAST_OPTIONS.error));
  };

  return (
    <QueueItem
      label={isTaskRequest ? "Task" : assistanceRequest.request?.resourceType}
      type="Request"
      disabled={disabled}
      priority={isPriority}
      thickDividers={settings.ui.thickQueueItemDividers}
    >
      {isTaskRequest ? (
        <TaskMasterInfo
          tags={assistanceRequest.tags}
          when={assistanceRequest.createdAt}
          taskType={assistanceRequest.request.taskType}
        />
      ) : (
        <StudentInfo
          student={assistanceRequest.requestor}
          showDetails={true}
          when={assistanceRequest.createdAt}
          tags={assistanceRequest.tags}
          excludeStudentTags
          platform={assistanceRequest.request?.info?.platform}
        />
      )}

      <div className="blurb">
        {assistanceRequest.formResults ? (
          <FormResults
            formName={assistanceRequest.formResults.form_name}
            results={assistanceRequest.formResults.results}
          />
        ) : (
          <RequestDetails
            requestedBy={assistanceRequest.request.requestedBy}
            categoryName={taskCategory}
            day={assistanceRequest.request.info?.day}
            activityUrl={assistanceRequest.request.link || assistanceRequest.request.resourceLink}
            activityTitle={assistanceRequest.request.resourceName}
            reason={assistanceRequest.request.reason}
          />
        )}
        <div className="blurb">
          <RequestPriority priority={priority} />
          {assistors && (
            <span>
              <strong> Assigned to: &nbsp;</strong>
              {assistors.map((assistor, i) => (
                <TeacherName key={`assigned-to-${i}`} index={i} assistor={assistor} />
              ))}
            </span>
          )}
          {currentUser.isSuperAdmin && (
            <div>
              <p>
                <small>
                  <a
                    href={`${process.env.REACT_APP_SERVER}/activeadmin/${requestType}/${assistanceRequest.id}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Open in ActiveAdmin
                  </a>
                </small>
              </p>
            </div>
          )}
        </div>
      </div>

      <div className="actions float-right">
        {assistanceRequest.type === "UserRequest" && (
          <>
            <Button.ConferenceLink
              url={assistanceRequest.conference?.link}
              type={assistanceRequest.conference?.platform}
            />
            <Button.Warn
              text={settings.features?.skipClaim ? "Start" : "Claim"}
              onClick={handleClaimAssistance}
              disabled={disabled}
            />
          </>
        )}
        {assistanceRequest.type === "Evaluation" && (
          <Button.External url={assistanceRequest.request.finishResourceUrl} text="Start Marking" />
        )}
        {isTaskRequest && <Button.External url={assistanceRequest.request.link} text="Open" />}

        {settings.features.adminRequestAssignment && (
          <Button.Info
            text="Delegate"
            onClick={() => setShowDelegateModal(true)}
            disabled={disabled}
          />
        )}
        {settings.featureFlags.prioritizeRequests && (
          <Button.Danger
            text="Prioritize"
            onClick={() => handlePrioritizeRequest()}
            disabled={disabled}
          />
        )}
        <SettingsConditional flag={SettingsConditional.HIDE_REMOVE_BUTTON}>
          <Button.Light text="Remove" onClick={handleRemoveAssistanceRequest} disabled={disabled} />
        </SettingsConditional>
      </div>
      {showDelegateModal && (
        <DelegateRequestModal
          mentors={teachers}
          onClickMentor={handleDelegateRequest}
          hide={() => setShowDelegateModal(false)}
        />
      )}
    </QueueItem>
  );
};

export default PendingAssistanceRequest;
