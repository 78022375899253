import { Formik, Form } from "formik";
import { Alert, Badge, Row, Col } from "react-bootstrap";
import { useState } from "react";
import * as Yup from "yup";
import InputField from "Components/Form/InputField";
import "./Tags.scss";
import ModalButton from "Components/Buttons/ModalButtons";

const TagField = props => <InputField prefix="tag" {...props} />;

const getFormValues = tag => ({
  name: tag.name || "",
  match_bonus_multiplier: tag.matchBonusMultiplier || "",
  mismatch_penalty_multiplier: tag.mismatchPenaltyMultiplier || "",
  queue_note: tag.queueNote || "",
  background: tag.background || "",
  text: tag.text || "",
});

const ColorButton = ({ backgroundColor, textColor, onClick, selected, text }) => {
  const handleClick = e => {
    e.preventDefault();
    onClick();
  };

  return (
    <button
      className={`selector-button ${selected ? "selected" : ""}`}
      selected={selected}
      onClick={handleClick}
    >
      <Badge pill className={`bg-${backgroundColor || "warning"} text-${textColor || "dark"}`}>
        {text}
      </Badge>
    </button>
  );
};

const TagForm = ({ tag = {}, onSubmit, onValidate }) => {
  const tagSchema = Yup.object().shape({
    name: Yup.string()
      .required("Required")
      .matches(/^[^A-Z]*$/, "Tag name must not contain uppercase letters"),
  });

  const [selectedBackground, setSelectedBackground] = useState(tag.background || "");
  const [selectedText, setSelectedText] = useState(tag.text || "");

  return (
    <Formik
      initialValues={getFormValues(tag)}
      validationSchema={tagSchema}
      onSubmit={(values, { setSubmitting }) => {
        values.background = selectedBackground;
        values.text = selectedText;
        onSubmit(values).then(setSubmitting(false));
      }}
      validate={onValidate}
      validateOnChange={false}
      validateOnBlur={false}
    >
      {({ isSubmitting, errors }) => (
        <Form>
          <Alert variant="danger" hidden={Object.keys(errors).length < 1}>
            {Object.keys(errors).map((errorKey, index) => (
              <li key={index}>{`${errorKey}: ${errors[errorKey]}`}</li>
            ))}
          </Alert>
          <TagField name="name" label="Tag Name" required />
          <TagField name="match_bonus_multiplier" label="Match Bonus Multiplier" type="number" />
          <TagField
            name="mismatch_penalty_multiplier"
            label="Mismatch Penalty Multiplier"
            type="number"
          />
          <TagField name="queue_note" label="Queue Note" as="textarea" />
          <TagField name="category" label="Category" as="select">
            <option value="">Select a category</option>
            <option value="Accommodation">Accommodation</option>
            <option value="Program">Program</option>
            <option value="Request Type">Request Type</option>
            <option value="Demo">Demo</option>
            <option value="Topic">Topic</option>
          </TagField>
          <Row>
            <Col md={3}>
              <strong>Background Color</strong>
              <div className="tag-form-container color-picker" style={{ marginBottom: "1rem" }}>
                <ColorButton
                  backgroundColor={"warning"}
                  onClick={() => setSelectedBackground("warning")}
                  selected={selectedBackground === "warning"}
                  text={"Traditional"}
                />
                <ColorButton
                  backgroundColor={"info"}
                  onClick={() => setSelectedBackground("info")}
                  selected={selectedBackground === "info"}
                  text={"Attention"}
                />
                <ColorButton
                  backgroundColor={"danger"}
                  onClick={() => setSelectedBackground("danger")}
                  selected={selectedBackground === "danger"}
                  text={"Accommodation"}
                />
              </div>
            </Col>
            <Col md={2}>
              <strong>Text Color</strong>
              <div className="tag-form-container color-picker" style={{ marginBottom: "1rem" }}>
                <ColorButton
                  textColor={"dark"}
                  onClick={() => setSelectedText("dark")}
                  selected={selectedText === "dark"}
                  text={"Dark"}
                />
                <ColorButton
                  textColor={"light"}
                  onClick={() => setSelectedText("light")}
                  selected={selectedText === "light"}
                  text={"Light"}
                />
              </div>
            </Col>
          </Row>
          <ModalButton.Submit
            text="Submit"
            type="submit"
            disabled={isSubmitting}
            onClick={() => window.scrollTo(0, 0)}
          />
        </Form>
      )}
    </Formik>
  );
};

export default TagForm;
