export default (axios, errorHandler) => {
  const getTeachers = () => {
    return axios
      .get(`/teachers`)
      .then(response => {
        return response.data.teachers || [];
      })
      .catch(err => {
        errorHandler(err);
      });
  };

  const toggleDuty = payload => axios.patch("/teachers/toggle_duty", payload).catch(errorHandler);

  return {
    getTeachers,
    toggleDuty,
  };
};
