import {
  Requestor,
  AssistanceResponse,
  AssistanceRequest,
  AssistanceRequestSchema,
  AssistanceRequestsResponse,
  AssistanceRequestsSchema,
} from "./types";
import { snakeCaseKeys } from "util/string";
import { ErrorHandler } from "Requests";

export default (axios: any, errorHandler: ErrorHandler) => {
  const claimAssistanceRequest = (id: number): Promise<AssistanceRequest> => {
    return axios
      .post(`/assistances/${id}/claim`)
      .then((response: AssistanceResponse) =>
        AssistanceRequestSchema.parse(response.data.assistanceRequest)
      )
      .catch(errorHandler);
  };

  const claimAssistanceRequestForTeacher = (
    id: number,
    assistorUid: string
  ): Promise<AssistanceRequest> => {
    return axios
      .post(`/assistances/${id}/claim_for_other_teacher`, {
        assistor_uid: assistorUid,
      })
      .then((response: AssistanceResponse) =>
        AssistanceRequestSchema.parse(response.data.assistanceRequest)
      )
      .catch(errorHandler);
  };

  type PrioritizeResponse = {
    prioritize: {
      request_id: number;
      priority: number;
    };
  };

  const prioritizeRequest = (id: number): Promise<AssistanceRequest> => {
    return axios
      .post(`/assistance_requests/${id}/prioritize`)
      .then((response: AssistanceResponse) =>
        AssistanceRequestSchema.parse(response.data.assistanceRequest)
      )
      .catch(errorHandler);
  };

  const provideManualAssistance = (
    student: Requestor,
    assistanceDetails: any
  ): Promise<AssistanceRequest> => {
    return axios
      .post("/assistances/manual", {
        requestor: student,
        assistance_details: assistanceDetails,
      })
      .then((response: AssistanceResponse) =>
        AssistanceRequestSchema.parse(response.data.assistanceRequest)
      )
      .catch(errorHandler);
  };

  const startAssistance = (id: number): Promise<AssistanceRequest> => {
    return axios
      .patch(`/assistances/${id}/start`)
      .then((response: AssistanceResponse) =>
        AssistanceRequestSchema.parse(response.data.assistanceRequest)
      )
      .catch(errorHandler);
  };

  const cancelAssistance = (id: number): Promise<AssistanceRequest> => {
    return axios
      .patch(`/assistances/${id}/cancel`)
      .then((response: AssistanceResponse) =>
        AssistanceRequestSchema.parse(response.data.assistanceRequest)
      )
      .catch(errorHandler);
  };

  type FinishPayload = {
    finish: {
      request_id: number;
      assistance_id: number;
    };
  };

  const finishAssistance = (id: number, payload: FinishPayload): Promise<AssistanceRequest> => {
    return axios
      .patch(`/assistances/${id}/finish`, payload)
      .then((response: AssistanceResponse) =>
        AssistanceRequestSchema.parse(response.data.assistanceRequest)
      )
      .catch(errorHandler);
  };

  const closeAssistanceRequest = (id: number): Promise<AssistanceRequest> => {
    return axios
      .patch(`/assistance_requests/${id}/close`)
      .then((response: AssistanceResponse) =>
        AssistanceRequestSchema.parse(response.data.assistanceRequest)
      )
      .catch(errorHandler);
  };

  // todo type out data
  const reviewAssistance = (data: any): Promise<AssistanceRequest> => {
    const { requestId, ...payload } = data;
    return axios
      .patch(`/assistances/${requestId}/review`, {
        assistance_details: snakeCaseKeys(payload),
      })
      .then((response: AssistanceResponse) =>
        AssistanceRequestSchema.parse(response.data.assistanceRequest)
      )
      .catch(errorHandler);
  };

  type SuggestedAssistanceRequestsData = {
    arType: string;
    podUuid: string;
    resourceUuid: string;
    platform: string;
    limit: number;
  };

  const getSuggestedAssistanceRequests = (
    data: SuggestedAssistanceRequestsData
  ): Promise<AssistanceRequest[]> => {
    const params = snakeCaseKeys(data);
    return axios
      .get(`/assistance_requests`, { params })
      .then((response: AssistanceRequestsResponse) =>
        AssistanceRequestsSchema.parse(response.data.assistance_requests)
      )
      .catch(errorHandler);
  };

  return {
    claimAssistanceRequest,
    claimAssistanceRequestForTeacher,
    prioritizeRequest,
    provideManualAssistance,
    startAssistance,
    cancelAssistance,
    finishAssistance,
    closeAssistanceRequest,
    reviewAssistance,
    getSuggestedAssistanceRequests,
  };
};
