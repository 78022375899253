import { Route } from "react-router-dom";
import { ProtectedRoute } from "Components/Routers";
import Queue from "./Queue";

export default [
  <Route
    key="queue-explicit"
    path="/queue"
    element={<ProtectedRoute teacher component={Queue} />}
  />,
];
