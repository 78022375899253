import ModalBackdrop from "Components/ModalBackdrop";
import ViewRequest from "Components/ViewRequest/ViewRequest";
import "./ViewRequestModal.scss";

const ViewRequestModal = ({ assistanceRequest, statusText, hide, children, queueClosed }) => {
  return (
    <ModalBackdrop blur center>
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title">Your Assistance Request</h4>
            <button className="close" onClick={hide} type="button" data-dismiss="modal">
              <span aria-hidden="true">&times;</span>
              <span className="sr-only">Close</span>
            </button>
          </div>
          <div className="modal-body">
            <ViewRequest
              statusText={statusText}
              assistanceRequest={assistanceRequest}
              queueClosed={queueClosed}
            />
            <div className="btns">{children}</div>
          </div>
        </div>
      </div>
    </ModalBackdrop>
  );
};

export default ViewRequestModal;
