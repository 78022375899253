import { useEffect, useReducer, useState, useCallback, useMemo } from "react";
import { useLocation } from "react-router-dom";
import requests from "Requests";
import * as Sentry from "@sentry/react";
import { isBrowserExtension } from "util/env";

const updateCurrentUser = (user, updates) => {
  if (!updates) return user;

  const latestUpdate = updates
    .slice()
    .reverse()
    .find(update => user.uid === update.object.uid);
  return latestUpdate ? { ...user, onDuty: latestUpdate.object.onDuty } : user;
};

// not used in the extension context
const fetchUserFromLocalStorage = () => {
  try {
    const jsonDetails = localStorage.getItem("currentUser");
    if (jsonDetails) return JSON.parse(jsonDetails);
  } catch (err) {
    return null;
  }
};

const initialUser = userUpdates =>
  userUpdates[userUpdates.length - 1]?.object || fetchUserFromLocalStorage();

const currentUserReducer = (state, action) => {
  let currentUser = {};
  switch (action.type) {
    case "addUpdates":
      currentUser = updateCurrentUser(state, action.data);
      break;
    case "setUser":
      currentUser = { ...initialUser(action.data), details: {} };
      break;
    case "setUserFromChromeStorage":
      currentUser = { ...action.data, details: {} };
      break;
    case "updateUser":
      currentUser = { ...state, ...action.data };
      break;
    case "updateUserDetails":
      currentUser = { ...state, details: action.data };
      break;
    case "resetUser":
      currentUser = {};
      break;
    default:
      throw new Error();
  }
  if (!isBrowserExtension()) {
    localStorage.setItem("currentUser", JSON.stringify(currentUser));
  }

  return currentUser;
};

const useCurrentUser = (teacherUpdates, userUpdates, rejected) => {
  const [authStatus, setAuthStatus] = useState();
  const [currentUser, dispatchCurrentUser] = useReducer(currentUserReducer, null);
  const location = useLocation();

  useEffect(() => {
    if (isBrowserExtension()) {
      const storeChanged = function (changes) {
        for (let [key, { newValue }] of Object.entries(changes)) {
          if (key === "currentUser") {
            const userFromStorage = newValue;

            // if user is not blank and has id, update sentry user
            if (userFromStorage?.id) {
              Sentry.setUser({
                id: userFromStorage.id,
                uid: userFromStorage.uid,
                email: userFromStorage.socials?.email,
                fullName: userFromStorage.fullName,
              });
            }

            dispatchCurrentUser({
              type: "setUserFromChromeStorage",
              data: userFromStorage,
            });
          }
          if (key === "authStatus") {
            setAuthStatus(newValue);
          }

          if (newValue === "dropped" && location.pathname !== "/sign-in") {
            dispatchCurrentUser({ type: "resetUser", data: {} });
          }
        }
      };

      chrome.storage.onChanged.addListener(storeChanged);

      return () => {
        chrome.storage.onChanged.removeListener(storeChanged);
      };
    }
  }, [location.pathname]);

  useEffect(() => {
    if (userUpdates && authStatus !== "dropped") {
      dispatchCurrentUser({ type: "setUser", data: userUpdates });
    }
  }, [userUpdates, authStatus]);

  useEffect(() => {
    dispatchCurrentUser({ type: "addUpdates", data: teacherUpdates });
  }, [teacherUpdates]);

  useEffect(() => {
    if (rejected) {
      dispatchCurrentUser({ type: "resetUser", data: {} });
    }
  }, [rejected]);

  const checkRole = useCallback(role => currentUser?.access?.includes(role), [currentUser]);

  const hasField = useCallback(
    field => {
      if (field === "githubUsername") {
        return currentUser.socials && !!currentUser.socials["github"];
      }
      return (
        !!currentUser[field] ||
        (currentUser.details && !!currentUser.details[field]) ||
        (currentUser.socials && !!currentUser.socials[field])
      );
    },
    [currentUser]
  );

  const getOAuthToken = useCallback(
    platform => currentUser?.oauthTokens?.find(token => token.platform === platform),
    [currentUser]
  );

  const getTagsBySettings = useCallback(
    settings => {
      const hasSettings = t => settings.every(s => t.hasOwnProperty("settings") && t.settings[s]);
      return currentUser?.tags?.filter(hasSettings);
    },
    [currentUser]
  );

  const updateUserDetails = useCallback(
    updates => {
      return requests.updateUser(currentUser, updates, checkRole("student")).then(response => {
        dispatchCurrentUser({ type: "updateUserDetails", data: response.userDetails });
        return response;
      });
    },
    [currentUser, checkRole]
  );

  const getUserDetails = useCallback(() => {
    dispatchCurrentUser({ type: "updateUserDetails", data: { uid: currentUser.uid } });
    requests
      .getUserDetails(currentUser)
      .then(user => dispatchCurrentUser({ type: "updateUserDetails", data: user }))
      .catch(err => console.error(err));
  }, [currentUser]);

  const inPrep = useMemo(() => {
    const currentUserInfo = currentUser?.info || {};
    return currentUserInfo["is_ai_only"] && currentUserInfo["force_queue_open"];
  }, [currentUser?.info]);

  return {
    ...currentUser,
    isTeacher: useMemo(() => checkRole("teacher"), [checkRole]),
    isAdmin: useMemo(() => checkRole("admin"), [checkRole]),
    isSuperAdmin: useMemo(() => checkRole("super-admin"), [checkRole]),
    isSystemAdmin: useMemo(() => checkRole("system-admin"), [checkRole]),
    isStudent: useMemo(() => checkRole("student"), [checkRole]),
    isPresent: useMemo(() => !!currentUser?.uid, [currentUser]),
    hasField,
    getOAuthToken,
    getTagsBySettings,
    updateUserDetails,
    getUserDetails,
    inPrep,
  };
};

export default useCurrentUser;
