import { Link } from "react-router-dom";

const UserDetail = ({ user }) => {
  let userDetails = <>{user.type || "No Role"}</>;

  if (user.type === "Student") {
    userDetails = <>{user.cohort && user.cohort.name}</>;
  } else if (user.type === "Teacher") {
    userDetails = <>Teacher</>;
  }

  return (
    <div className="clearfix">
      <img
        style={{ height: 60 }}
        className="float-left rounded avatar mr-2 d-none d-md-block"
        src={user.avatarUrl}
      />
      <div className="float-left">
        {user.firstName} {user.lastName}
        &nbsp;
        <Link to={`/users/${user.id}`} className="resource-id">
          [#{user.id}]
        </Link>
        <br />
        <small className="text-muted text-sm">{userDetails}</small>
      </div>
    </div>
  );
};

export default UserDetail;
