import classNames from "classnames";
import { Field, ErrorMessage } from "formik";
import "./InputField.scss";

/**
 * Generate an input field to be used in a Formik form.
 * @param {string} name Name of the input variable, will be used when submitting form.
 * @param {string} label Text to appear on the label for the input.
 * @param {string} info Text to appear underneath the label in small font, offering additional explanation of the field
 * @param {boolean} required Whether or not the variable is required. Only changes appearance, form itself will have to validate requirements.
 * @param {string} prefix Prefix to apply to the field's id to prevent collisions. Should ideally be one word, describing what the form is for.
 */
const InputField = ({
  name,
  label,
  info,
  required,
  prefix,
  children,
  placeholder = "",
  ...props
}) => {
  return props.type === "checkbox" ? (
    <div
      className={classNames("form-check", {
        required: required,
      })}
    >
      <Field name={name} id={`${prefix}-input-${name}`} className="form-check-input" {...props}>
        {children}
      </Field>
      <label htmlFor={`${prefix}-input-${name}`} className="form-check-label">
        {label}
        {info && <div className="field-info">{info}</div>}
      </label>
      <ErrorMessage name={name} component="span" className="text-danger" />
    </div>
  ) : (
    <div
      className={classNames("form-group", {
        required: required,
      })}
    >
      {label && (
        <label htmlFor={`${prefix}-input-${name}`}>
          {label}
          {info && <div className="field-info">{info}</div>}
        </label>
      )}

      <Field
        name={name}
        id={`${prefix}-input-${name}`}
        className="form-control"
        placeholder={placeholder}
        {...props}
      >
        {children}
      </Field>
      <ErrorMessage name={name} component="span" className="text-danger" />
    </div>
  );
};

export default InputField;
