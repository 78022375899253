import { useEffect, useState } from "react";
import requests from "Requests";

const useLocations = () => {
  const [locations, setLocations] = useState([]);

  useEffect(() => {
    requests.getLocations().then(setLocations);
  }, []);

  return {
    locations,
  };
};

export default useLocations;
