import { useState } from "react";
import requests from "Requests";

const useUserSearch = () => {
  const [search, setSearch] = useState({
    loading: false,
    users: [],
  });

  const searchUsers = keywords => {
    setSearch(state => ({ ...state, loading: true }));
    return requests.getUsers(keywords).then(users => {
      setSearch({ users, loading: false });
    });
  };

  return {
    users: search.users,
    loading: search.loading,
    searchUsers,
  };
};

export default useUserSearch;
