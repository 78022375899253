import ModalBackdrop from "Components/ModalBackdrop";
import CancelRequestForm from "Components/CancelRequestForm";
import { ModalTitle } from "./Typography/Heading";

const CancelRequestModal = ({ cancel, hide, isAIOnly = false }) => {
  const cancelAssistance = formValues => {
    cancel(...Object.values(formValues));
    hide();
  };

  return (
    <ModalBackdrop blur center>
      <div className="modal-dialog" style={{ maxWidth: 700 }}>
        <div className="modal-content">
          <div className="modal-header">
            <ModalTitle>{isAIOnly ? "Close Assistance" : "Cancel Assistance Request"}</ModalTitle>
            <button className="close" onClick={hide} type="button" data-dismiss="modal">
              <span aria-hidden="true">&times;</span>
              <span className="sr-only">Close</span>
            </button>
          </div>
          <div className="modal-body">
            <CancelRequestForm onSubmit={cancelAssistance} isAIOnly={isAIOnly} />
          </div>
        </div>
      </div>
    </ModalBackdrop>
  );
};

export default CancelRequestModal;
