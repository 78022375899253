import Select from "react-select";

/**
 * Generate list of option buttons to be used in a Formik form.
 * @param {array} selectableTags The tags from which to select
 * @param {object} field Object containing Formik field properties and callbacks, supplied by Formik automatically if used with as a Field component.
 * @param {array} options Array of options to select from. Each contained object needs the properties "name" and "id" to be compatible.
 */
const TagSelectInput = ({ selectableTags, isMulti, field }) => {
  const { name, value, onChange } = field;

  const onSelectTag = selectedTagOptions => {
    const value = Array.isArray(selectedTagOptions)
      ? selectedTagOptions.map(option => option.value)
      : [selectedTagOptions.value];
    onChange({ target: { name, value } });
  };

  const toOption = t => ({
    value: { id: t.id, name: t.name },
    label: t.name,
  });

  return (
    <Select
      value={value.map(toOption)}
      isMulti={isMulti}
      options={selectableTags.map(toOption)}
      className="basic-multi-select"
      classNamePrefix="select"
      onChange={onSelectTag}
    />
  );
};

export default TagSelectInput;
