import Assistance from "Components/Assistance";
import ListGroup from "Components/ListGroup";
import { useAppContext } from "Context/AppProvider";

const InProgressList = ({ tasks }) => {
  const { currentUser } = useAppContext();

  const isMine = task =>
    task.assistance &&
    task.assistance.assistor &&
    task.assistance.assistor.uid &&
    task.assistance.assistor.uid === currentUser.uid;

  const excludePrivate = task =>
    task.assistance?.assistor?.uid === currentUser.uid ||
    task.assistanceRequest?.request?.private !== true;

  // while sort is technically NOT stable by spec, most browsers implement a stable version, so we will go with that assumption. If not true, then this will do more than just push my tasks to the top. NBD though. - KV
  // source: https://stackoverflow.com/questions/23921683/javascript-move-an-item-of-an-array-to-the-front
  const tasksWithMineFirst = tasks =>
    [...tasks].sort((x, y) => (isMine(x) ? -1 : isMine(y) ? 1 : 0));

  const privateRemovedTasks = tasks.filter(excludePrivate);

  return (
    <ListGroup icon={privateRemovedTasks.length} title="In Progress" className="in-progress">
      {tasksWithMineFirst(privateRemovedTasks).map(task => (
        <Assistance key={`assistance-${task.id}`} task={task} />
      ))}
    </ListGroup>
  );
};

export default InProgressList;
