const getCurrentCookieDomain = () =>
  `.${process.env.REACT_APP_SERVER.split("//")[1].split(":")[0]}`;

// This is used in the extension content script to determine
// wether or not to start a port connection and get user auth cookies
const isRudderUrl = () => window.location.href.includes(process.env.REACT_APP_SERVER);

const isBrowserExtension = () => process.env.RUDDER_BROWSER_EXTENSION || false;
// there's no way to set the node env var for compass as it doesn't
// have it's own build process so we're checking the current url
const isEmbedded = () => window.location.href.includes("compass");

const getCurrentSource = () => {
  if (isBrowserExtension()) return "extension:chrome";
  if (isEmbedded()) return "web:compass";
  if (isRudderUrl()) return "web:rudder";
};

const inIframe = () => {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
};

// Note: using module.exports because this is also used
// in the build scripts
module.exports = {
  isBrowserExtension,
  getCurrentCookieDomain,
  isRudderUrl,
  isEmbedded,
  getCurrentSource,
  inIframe,
};
