import { Route } from "react-router-dom";
import { ProtectedRoute } from "Components/Routers";
import Queue from "./Queue";
import DeploymentSettingsQueue from "./Queue/DeploymentSettingsQueue";
import TaskCreationFormQueue from "./Queue/TaskCreationFormQueue";

export default [
  <Route key="admin-queue" path="/queue/admin" element={<ProtectedRoute component={Queue} />} />,
  <Route
    key="admin-deployment-settings"
    path="/queue/admin/deployment-settings"
    element={<ProtectedRoute component={DeploymentSettingsQueue} />}
  />,
  <Route
    key="admin-task-creation"
    path="/queue/admin/task-creation"
    element={<ProtectedRoute component={TaskCreationFormQueue} />}
  />,
];
