import { StrictMode } from "react";
import { render } from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import initializers from "./initializers";
import { CookiesProvider } from "react-cookie";
import { storageSet } from "BrowserExtension/utils/storage";
import LoadingProvider from "Context/LoadingProvider";

storageSet({ isFirstAppLoad: true });

initializers.forEach(initializer => initializer());

const queryClient = new QueryClient();

render(
  <StrictMode>
    <LoadingProvider>
      <QueryClientProvider client={queryClient}>
        <CookiesProvider>
          <Router>
            <App />
          </Router>
        </CookiesProvider>
      </QueryClientProvider>
    </LoadingProvider>
  </StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
