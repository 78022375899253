const RequestQuote = ({ quote, title, length = 200, inline = false }) => {
  if (!quote || quote.trim().length === 0) return null;

  const displayTitle = title ? `${title}: ` : "";

  const truncate = (str, maxLength) => {
    if (str.length <= maxLength) return str;
    return str.substring(0, maxLength) + "...";
  };

  const truncatedQuote = truncate(quote, length);

  if (inline) {
    return (
      <span>
        {displayTitle}
        <em>&ldquo;{truncatedQuote}&rdquo;</em>
      </span>
    );
  }

  return (
    <blockquote title={quote}>
      {displayTitle}&ldquo;{truncatedQuote}&rdquo;
    </blockquote>
  );
};

export default RequestQuote;
