import Spinner from "Components/Spinner";

const QueueWaitTableRows = props => {
  return props.rows.map(row => {
    if (props.show === false) return null;

    return (
      <tr key={row.label}>
        <th className="aligned">{row.label}</th>
        <td className="aligned">{props.loading ? <Spinner /> : row.value}</td>
      </tr>
    );
  });
};

export default QueueWaitTableRows;
