import { useEffect, useState } from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { useAppContext } from "Context/AppProvider";
import useUser from "Hooks/useUser";
import useLocations from "Hooks/useLocations";
import { Card, Alert } from "react-bootstrap";
import NotAuthorized from "Components/NotAuthorized";
import NotFound from "Components/NotFound";
import PaddedContent from "Layouts/PaddedContent";
import ProfileForm from "./ProfileForm";

const Edit = props => {
  const { id } = useParams();
  const {
    currentUser,
    settingsHandler: {
      settings: { requirements },
    },
  } = useAppContext();
  const requiredFields = requirements?.userProfileFields || {
    githubUsername: true,
    email: true,
  };
  const { locations } = useLocations();
  const navigate = useNavigate();

  // edit current user if no id provided, like when visiting from /profile/edit
  const userParams = id ? [id] : currentUser.isStudent ? [currentUser.uid, true] : [currentUser.id];
  const { user, userDetails, updateUser } = useUser(...userParams);

  const path = useLocation();
  const params = new URLSearchParams(path.search);
  const initialSetup = params.get("initial_setup") || props.initialSetup;

  const [statusMsg, setStatusMsg] = useState({
    message: "",
    variant: "",
  });

  const ownProfile = currentUser.isStudent
    ? user.uid === currentUser.uid
    : user.id === currentUser.id;

  // if userDetails changes (like if you navigate between two users' profile edit pages directly), scroll to top to make the reload more obvious
  useEffect(() => window.scrollTo(0, 0), [userDetails]);

  useEffect(() => {
    if (initialSetup) {
      setStatusMsg({
        message: "Please fill in the required* fields to continue.",
        variant: "primary",
      });
    }
  }, [initialSetup]);

  if (Object.keys(userDetails).length < 1) {
    return null;
  } else if (user.error) {
    return <NotFound />;
  } else if (!ownProfile && !currentUser.access.includes("admin")) {
    // currently does nothing, as rails will respond 401 in this situation, causing Requests' errorhandler to terminate the session.
    // leaving this here anyway, in case that behaviour ever changes
    return <NotAuthorized />;
  }

  const clearStatusMsg = () => {
    setStatusMsg({ message: "", variant: "" });
  };

  const onSubmit = updates => {
    clearStatusMsg();

    // update current user in appContext if editing self, otherwise update details generically
    const request = ownProfile ? currentUser.updateUserDetails(updates) : updateUser(updates);

    return request
      .then(() => {
        if (initialSetup && !currentUser.isStudent) {
          navigate("/queue");
        }
        setStatusMsg({
          message: "Profile updated successfully!",
          variant: "success",
        });
      })
      .catch(error => {
        setStatusMsg({
          message: `Could not update profile - ${error}`,
          variant: "danger",
        });
      });
  };

  return (
    <PaddedContent>
      <div className="d-flex flex-column align-items-center">
        <div className="col-md-8">
          <h1>
            Edit
            {ownProfile ? " Your " : ` ${user.firstName}'s `}
            Profile
          </h1>
          <Card body>
            <Alert variant={statusMsg.variant} hidden={!statusMsg.message}>
              {statusMsg.message}
            </Alert>
            <ProfileForm
              userDetails={userDetails}
              locations={locations}
              onSubmit={onSubmit}
              onValidate={clearStatusMsg}
              isLearner={currentUser.isStudent}
              requiredFields={requiredFields}
            />
          </Card>
        </div>
      </div>
    </PaddedContent>
  );
};

export default Edit;
