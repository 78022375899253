import { Table, InputGroup, Form } from "react-bootstrap";
import { useEffect, useRef } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { confirm } from "Components/Confirmation";
import queryString from "query-string";
import { toInteger } from "lodash";
import { DateTime } from "luxon";
import PaddedContent from "Layouts/PaddedContent";
import Pagination from "Components/Pagination";
import useInvites from "Hooks/useInvites";
import CopyToClipBoard from "Components/CopyToClipboard";
import Spinner from "Components/Spinner";
import "./Invites.scss";
import ModalButton from "Components/Buttons/ModalButtons";

const InviteRow = ({ invite, index, deleteInvite }) => {
  const handleDelete = async invite => {
    const confirmed = await confirm(
      "Are you sure you would like to delete this invite?",
      "Yes",
      "No"
    );
    if (confirmed) deleteInvite(invite);
  };

  return (
    <tr key={index}>
      <td>{invite.id}</td>
      <td>
        {invite.firstName} {invite.lastName}
      </td>
      <td>{invite.email}</td>
      <td className="link-icon-container">
        <CopyToClipBoard text={invite.url} icon="fa-link" iconCopied="fa-check-square" />
      </td>
      <td>{invite.userType}</td>
      <td>{DateTime.fromISO(invite.createdAt).toFormat("DD HH:mm")}</td>
      <td>{invite.usedAt ? DateTime.fromISO(invite.usedAt).toFormat("DD HH:mm") : ""}</td>
      <td>
        {invite.usedByUserId && (
          <Link to={`/users/${invite.usedByUserId}`}>{invite.usedByUserId}</Link>
        )}
      </td>
      <td className="link-icon-container">
        <ModalButton.Delete onClick={() => handleDelete(invite)} disabled={invite.usedAt} />
      </td>
    </tr>
  );
};

const InviteRows = ({ invites, deleteInvite }) => {
  const rows = invites.map((invite, index) => (
    <InviteRow invite={invite} key={index} index={index} deleteInvite={deleteInvite} />
  ));

  return <>{rows}</>;
};

const Browse = () => {
  const { search } = useLocation();
  const navigate = useNavigate();
  const inputRef = useRef(null);
  const { parse, stringify } = queryString;
  const { page, query, type } = queryString.parse(search);
  const searchQuery = query || "";
  const pageNumber = /^[0-9]+$/.test(page) ? toInteger(page) : 1;
  const userType = type || "";
  const { invites, lastPage, deleteInvite, isLoading, error, refetch } = useInvites(
    pageNumber,
    searchQuery,
    userType
  );

  useEffect(() => {
    refetch();
  }, [pageNumber, searchQuery, userType, refetch]);

  const updateQuery = updates => {
    const updatedQuery = stringify({ page: 1, ...parse(search), ...updates });

    navigate(`?${updatedQuery}`);
  };

  const handleSearch = query => updateQuery({ query, type: userType });
  const handleUserTypeFilter = selectedUserType =>
    updateQuery({ query: searchQuery, type: selectedUserType });

  return (
    <PaddedContent>
      {lastPage && (
        <Pagination
          routeKey={"/invites"}
          pageNumber={pageNumber}
          lastPage={lastPage}
          ariaLabel="Invites pagination"
        />
      )}
      <InputGroup className="mb-3">
        <Form.Control
          ref={inputRef}
          placeholder="Search by name or email..."
          aria-label="Search"
          aria-describedby="basic-addon2"
          onKeyPress={e => {
            if (e.key === "Enter") handleSearch(e.target.value.trim());
          }}
        />
        <ModalButton.Submit
          text="Search"
          onClick={() => handleSearch(inputRef.current.value.trim())}
        />
        <ModalButton.Back
          text="Clear"
          id="button-addon2"
          onClick={e => {
            e.preventDefault();
            inputRef.current.value = "";
            handleSearch(inputRef.current.value.trim());
          }}
        />
        <Form.Select onChange={e => handleUserTypeFilter(e.target.value)}>
          <option value="">All User Types</option>
          <option value="Learner">Learner</option>
          <option value="Teacher">Teacher</option>
          <option value="Admin">Admin</option>
          <option value="SuperAdmin">SuperAdmin</option>
          <option value="SystemAdmin">SystemAdmin</option>
        </Form.Select>
      </InputGroup>
      <div className="title-container">
        <h1>Invites</h1>
        <div className="links-container">
          <Link className="nav-link" to="/invites/new">
            <i className="fa fa-fw fa-envelope fa-lg" />
            <div className="nav-text">invite</div>
          </Link>
          <Link className="nav-link" to="/invites/bulk">
            <i className="fa-solid fa-fw fa-envelopes-bulk fa-lg" />
            <div className="nav-text">bulk invite</div>
          </Link>
        </div>
        <div className="loading-container">{isLoading && <Spinner />}</div>
      </div>
      {error ? (
        <p>Something went wrong. {error}</p>
      ) : (
        <Table striped responsive bordered size="sm">
          <thead>
            <tr>
              <th>ID</th>
              <th>Name</th>
              <th>Email</th>
              <th>Link</th>
              <th>User Type</th>
              <th>Created at</th>
              <th>Used at</th>
              <th>User ID</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            <InviteRows invites={invites} deleteInvite={deleteInvite} />
          </tbody>
        </Table>
      )}
    </PaddedContent>
  );
};

export default Browse;
