import PendingAssistanceRequest from "./PendingAssistanceRequest";
import ListGroup from "Components/ListGroup";

const OpenRequestsList = ({ tasks }) => {
  return (
    <ListGroup icon={tasks.length} title="Assigned Requests">
      {tasks.map(task => (
        <PendingAssistanceRequest
          key={`request-${task.id}`}
          assistors={task.assistors}
          task={task}
        />
      ))}
    </ListGroup>
  );
};

export default OpenRequestsList;
