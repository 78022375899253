import MainNavigation from "Components/MainNavigation";
import MainFooter from "Components/MainFooter";
import Announcements from "Components/Announcements";

const MainLayout = ({ children }) => {
  return (
    <>
      <MainNavigation />
      <div className="main-layout">
        <Announcements />
        {children}
      </div>
      <MainFooter />
    </>
  );
};

export default MainLayout;
