import Spinner from "./Spinner";
import "./Components.scss";

const Splash = () => {
  return (
    <label className="splash-label">
      <Spinner />
    </label>
  );
};

export default Splash;
