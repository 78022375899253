import StarRating from "Components/Rating/StarRating";

// custom input using our Rating component, for use with Formik
const StarRatingInput = ({ field, maxRating, ...props }) => {
  const { name, value, onChange } = field;

  // when Rating changes, call onChange with a constructed target for Formik to read
  const handleChange = value => onChange({ target: { name, value } });

  return (
    <StarRating
      initialRating={value}
      returnRating={handleChange}
      maxRating={maxRating}
      {...props}
    />
  );
};

export default StarRatingInput;
