import { Table } from "react-bootstrap";
import TagsListItem from "./TagsListItem";

const TagsList = ({ tags, edit, remove, currentUser }) => {
  return (
    <Table striped bordered hover responsive>
      <thead>
        <tr>
          <th>Name</th>
          <th>Tagged</th>
          <th>Queue Scoring</th>
          <th>Created</th>
          <th>Category</th>
          {currentUser.isSuperAdmin && <th>Actions</th>}
        </tr>
      </thead>
      <tbody>
        {tags.map(tag => (
          <TagsListItem
            key={`tag-list-item-${tag.id}`}
            tag={tag}
            edit={edit}
            remove={remove}
            admin={currentUser.isSuperAdmin}
          />
        ))}
      </tbody>
    </Table>
  );
};

export default TagsList;
