import { Alert } from "react-bootstrap";
import { LLM_recommendation_bot_name as llmBotName } from "../../constants/token_api";
import _ from "lodash";

/**
 * Generate a validation error list for Formik forms that does not preemptively show errors on untouched fields.
 * @param {object} errors Object containing field names as keys and error messages as values. From Formik.
 * @param {object} touched Object containing field names as keys for all touched fields. From Formik.
 */
const ErrorList = ({ errors, touched }) => {
  const touchedErrors = Object.keys(errors).filter(errorKey => touched[errorKey]);

  const replaceBotName = inputStr => {
    const name = _.startCase(inputStr);

    return name.replace("Llm Bot", llmBotName);
  };

  if (touchedErrors.length) {
    return (
      <Alert variant="danger">
        {touchedErrors.map((errorKey, index) => (
          <div key={index}>{`${replaceBotName(errorKey)}: ${errors[errorKey]}`}</div>
        ))}
      </Alert>
    );
  } else {
    return null;
  }
};

export default ErrorList;
