import ModalBackdrop from "Components/ModalBackdrop";
import SkipRequestForm from "Components/SkipRequestForm";
import Modal from "./Modal";

const SkipRequestModal = ({ requestType, skip, hide }) => {
  const skipRequest = formValues => {
    skip(...Object.values(formValues));
    hide();
  };
  return (
    <ModalBackdrop blur center>
      <Modal title="Skip Assistance Request" size="medium" closeModal={hide}>
        <SkipRequestForm requestType={requestType} onSubmit={skipRequest} />
      </Modal>
    </ModalBackdrop>
  );
};

export default SkipRequestModal;
