import PaddedContent from "Layouts/PaddedContent";
import HelpDashboard from "Screens/lti/HelpDashboard";

const StudentIndex = () => {
  return (
    <PaddedContent>
      <HelpDashboard />
    </PaddedContent>
  );
};

export default StudentIndex;
