import InputField from "../InputField";

const FeedbackField = props => <InputField prefix="feedback" {...props} />;

const PreRequestQuestions = () => {
  return (
    <>
      <FeedbackField
        name="preRequestImpression"
        label="Which statement best describes what you did before opening this request?"
        as="select"
      >
        <option value="" disabled>
          Please select an option...
        </option>
        <option value="help_was_needed">
          I immediately knew I would need help with this task and filed an assistance request
        </option>
        <option value="tried_and_failed">
          I tried completing the task and was unsuccessful, and then filed a request
        </option>
      </FeedbackField>
      <FeedbackField
        name="helpAlreadyRequested"
        label="Did you ask for help from a colleague or manager before opening a request?"
        as="select"
      >
        <option value="" disabled>
          Please select an option...
        </option>
        <option value="yes:not_helpful">Yes, but they didn't have the skills needed to help</option>
        <option value="yes:too_slow">Yes, but they couldn't help me in a timely fashion</option>
        <option value="no:feel_like_burden">
          No, because I feel like I'm asking them too many questions
        </option>
        <option value="no:embarrassed">
          No, because I didn't want to admit I didn't know the answer
        </option>
        <option value="no:cannot_help">
          No, because my colleagues don't have the experience to help me on this issue
        </option>
      </FeedbackField>
    </>
  );
};

export default PreRequestQuestions;
