import { isBrowserExtension } from "util/env";

/**
 *
 *
 * @param {{ [key: string]: string }}
 * @return {Promise}
 */
const storageSet = object => {
  if (isBrowserExtension()) {
    return chrome.storage.local.set(object);
  } else {
    for (const [key, value] of Object.entries(object)) {
      localStorage.setItem(key, value);
    }

    return Promise.resolve();
  }
};

/**
 *
 *
 * @param {string} selector
 * @return {Promise}
 */
const storageGet = selector => {
  // TODO: chrome.storage.local.get method returns an object
  // but localStorage.getItem returns a string
  // it would be nice to have a consistent return type
  // possible solution:
  // if (isBrowserExtension()) {
  //   return chrome.storage.local.get(selector).then((object) => {
  //     return object[selector];
  //   });
  // }
  // but this would require a lot of refactoring on other files

  if (isBrowserExtension()) {
    return chrome.storage.local.get(selector);
  } else {
    return Promise.resolve(localStorage.getItem(selector));
  }
};

/**
 * Resets authStatus and currentUser
 * @return {Promise}
 */
const resetStorage = () => {
  return storageSet({ authStatus: "dropped", currentUser: {} });
};

export { storageSet, storageGet, resetStorage };
