import Button from "Components/Buttons/AssistanceButtons";

const AssistanceActions = ({
  disabled,
  isMine,
  admin,
  assistanceRequest,
  assistance,
  type,
  finishResourceUrl,
  state,
  handleStartAssisting,
  handleEndAssisting,
  handleCancelAssisting,
  handleCloseAssistance,
}) => {
  const isUserRequest = type === "UserRequest";
  const isTaskRequest = type === "TaskRequest";
  const isEvaluation = type === "Evaluation" || type === "CodeReview";
  const isAIOnly = type === "AIOnly";
  const isClaimed = state === "claimed";
  const inReview = state === "needs_review";
  const inTaskReview = state === "needs_task_review";

  return (
    <div className="actions float-right">
      {isMine && (
        <>
          {finishResourceUrl && (
            <Button.External
              text={isEvaluation && "Start Marking"}
              url={finishResourceUrl}
              disabled={disabled}
            />
          )}
          {!finishResourceUrl && isClaimed && (
            <Button.Main onClick={handleStartAssisting} text="Start" disabled={disabled} />
          )}
          {(!finishResourceUrl || (finishResourceUrl && isTaskRequest)) &&
            !isClaimed &&
            !inReview &&
            !inTaskReview && (
              <Button.Danger onClick={handleEndAssisting} text="Finish" disabled={disabled} />
            )}
          {inTaskReview && (
            <Button.Success
              onClick={handleEndAssisting}
              text="Provide Review Details"
              disabled={disabled}
            />
          )}
          {!finishResourceUrl && inReview && (
            <Button.Success onClick={handleEndAssisting} text="Review" disabled={disabled} />
          )}
          {!finishResourceUrl &&
            !inReview &&
            !inTaskReview &&
            !isClaimed &&
            assistanceRequest?.chatConference?.link && (
              <Button.ConferenceLink
                url={assistanceRequest?.chatConference?.link}
                type={"Chat"}
                title={`Go to your assistance chat room: ${assistanceRequest?.chatConference?.name}`}
                disabled={disabled}
              />
            )}
          {!finishResourceUrl && !inReview && !isClaimed && assistanceRequest?.childConference && (
            <Button.ConferenceLink
              url={assistanceRequest?.childConference?.link}
              type={assistanceRequest?.childConference?.platform}
              disabled={disabled}
            />
          )}
          {!finishResourceUrl &&
            !inReview &&
            !inTaskReview &&
            !isClaimed &&
            assistance?.conferenceLink && (
              <Button.ConferenceLink
                url={assistance.conferenceLink}
                type={assistance.conferenceType}
                disabled={disabled}
              />
            )}
          {(!finishResourceUrl || (finishResourceUrl && (isTaskRequest || isEvaluation))) &&
            !inReview &&
            !inTaskReview && (
              <Button.Light onClick={handleCancelAssisting} text="Cancel" disabled={disabled} />
            )}
        </>
      )}
      {admin && (
        <>
          {!isMine &&
            !isAIOnly &&
            assistance.conferenceType === "DiscordForum" &&
            assistance.conferenceLink && (
              <Button.ConferenceLink
                url={assistance.conferenceLink}
                type={assistance.conferenceType}
              />
            )}
          {isAIOnly && (
            <Button.ConferenceLink
              url={assistanceRequest.conference.link}
              type={assistanceRequest.conference.platform}
            />
          )}
          {(!isUserRequest || isAIOnly) && (
            <Button.Warn text="Force Close" onClick={handleCloseAssistance} />
          )}
        </>
      )}
    </div>
  );
};

export default AssistanceActions;
