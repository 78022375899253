import { useParams } from "react-router-dom";
import { capitalize } from "util/string";
import ModalBackdrop from "./ModalBackdrop";
import { ModalTitle } from "./Typography/Heading";

const OAuthSuccessModal = () => {
  const { platform } = useParams();

  return (
    <ModalBackdrop id={`oauth-success-${platform.toLowerCase()}`} center blur>
      <div className="modal-dialog dialog-wide">
        <div className="modal-content">
          <div className="modal-header">
            <ModalTitle>You're All Set</ModalTitle>
          </div>
          <div className="modal-body">
            <p>
              <strong>{capitalize(platform)}</strong> was successfully authorized! Feel free to
              close this tab now.
            </p>
            {platform === "discord" && (
              <p>
                <a href="discord://discordapp.com/channels/">
                  <i className="fa fab fa-discord" /> You can also click here to open the Discord
                  app.
                </a>
              </p>
            )}
          </div>
        </div>
      </div>
    </ModalBackdrop>
  );
};

export default OAuthSuccessModal;
