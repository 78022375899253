import classNames from "classnames";
import { Tooltip, Button, OverlayTrigger } from "react-bootstrap";
import SettingsConditional from "Components/SettingsConditional";
import Spinner from "Components/Spinner";
import { determineConferenceTypeName, determineConferenceTypeIcon } from "util/styles";

const ConferenceButton = ({
  conferenceType,
  conferenceLink,
  assistorName,
  withText,
  forDashboard,
}) => {
  const conferenceTypeName = determineConferenceTypeName(conferenceType);
  const conferenceTypeIcon = determineConferenceTypeIcon(conferenceType);

  return conferenceLink ? (
    <OverlayTrigger
      placement="bottom"
      overlay={
        <Tooltip>
          {conferenceTypeName}
          {assistorName ? ` with ${assistorName}` : ""}
        </Tooltip>
      }
    >
      <Button
        variant="outline-primary"
        className={classNames("mr-2", "join-video", {
          "dashboard-button": forDashboard,
        })}
        as="a"
        href={conferenceLink}
        target="_blank"
        rel="noopener noreferrer"
        aria-label={conferenceTypeName}
      >
        <div className="btn-content">
          {conferenceTypeIcon}
          {withText && <span>&nbsp;Join {conferenceTypeName}</span>}
        </div>
      </Button>
    </OverlayTrigger>
  ) : (
    <SettingsConditional flag={SettingsConditional.AUTO_CONFERENCES}>
      <Button variant="outline-primary" className="mr-2" disabled>
        <Spinner />
        &nbsp;Loading...
      </Button>
    </SettingsConditional>
  );
};

export default ConferenceButton;
