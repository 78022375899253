import { useEffect, useState } from "react";
import requests from "Requests";

const useDeployments = () => {
  const [deployments, setDeployments] = useState([]);

  useEffect(() => {
    let isMounted = true;

    const fetchData = async () => {
      try {
        const data = await requests.getDeployments();
        if (isMounted) setDeployments(data);
      } catch (error) {
        console.error("Error fetching deployments:", error);
      }
    };

    fetchData();

    return () => (isMounted = false);
  }, []);

  const editFeatureSettings = (deploymentId, settings) =>
    requests.editDeploymentFeatureSettings(deploymentId, { settings });

  const editUiSettings = (deploymentId, settings) =>
    requests.editDeploymentUiSettings(deploymentId, { settings });

  const editQueueSettings = (deploymentId, settings) =>
    requests.editDeploymentRouterSettings(deploymentId, { settings });

  return {
    deployments,
    editFeatureSettings,
    editUiSettings,
    editQueueSettings,
  };
};

export default useDeployments;
