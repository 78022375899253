export default (axios, errorHandler) => {
  // without the withCredentials setting, all requests from this handler will fail on CORS errors
  const getRecommendations = (recommenderUrl, recommendationId) => {
    return axios
      .get(`/recommend/${recommendationId}`, {
        withCredentials: false,
        baseURL: recommenderUrl,
      })
      .then(response => {
        return response.data?.solution;
      })
      .catch(err => {
        errorHandler(err);
      });
  };

  const postRecommendationArticleFeedback = (
    recommenderUrl,
    recommendationId,
    articleId,
    feedback
  ) => {
    return axios
      .post(
        `${recommenderUrl}/recommend/${recommendationId}/feedback`,
        {
          content_id: articleId,
          feedback,
        },
        requestConfig
      )
      .catch(err => {
        errorHandler(err);
      });
  };

  // TODO: implementation is temporary.
  // for the trial run of hint feature we just make a GET request to a Rec's redirect_url,
  // but future versions of Recommender should have a separate POST route for this. - Q 2022-05-05
  const postRecommendationArticleInteraction = recommendation => {
    return axios.get(recommendation.redirect_url, requestConfig).catch(errorHandler);
  };

  return {
    getRecommendations,
    postRecommendationArticleFeedback,
    postRecommendationArticleInteraction,
  };
};
