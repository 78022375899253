import ModalBackdrop from "Components/ModalBackdrop";
import Pronoun from "Components/Pronoun";
import "./AdminQueue.scss";
import Modal from "Components/Modal";

const DelegateRequestModal = ({ mentors, onClickMentor, hide }) => {
  return (
    <ModalBackdrop blur center>
      <Modal title="Select Assignee" closeModal={hide} size="small">
        <div className="teacher-holder">
          <div className="teacher-image">
            {mentors.map(mentor => (
              <div className="mentor" key={mentor.id} onClick={() => onClickMentor(mentor.uid)}>
                <div style={{ width: "min-content" }}>
                  <img alt={mentor.fullName} src={mentor.avatarUrl} />
                </div>
                <div className="name">
                  <span className="mr-1">{mentor.fullName}</span>
                  <Pronoun pronoun={mentor.pronoun} />
                </div>
              </div>
            ))}
          </div>
        </div>
      </Modal>
    </ModalBackdrop>
  );
};

export default DelegateRequestModal;
