const priorityLevel = priority => {
  const numPriority = Number(priority);
  if (priority === null || priority === undefined) {
    return;
  } else if (numPriority < 4) {
    return "P0";
  } else if (numPriority > 4 && numPriority <= 8) {
    return "P1";
  } else if (numPriority > 8) {
    return "P2";
  }
};

const className = level => {
  switch (level) {
    case "P0":
      return "badge badge-danger";
    case "P1":
      return "badge badge-warning";
    case "P2":
      return "badge badge-info";
  }
};

const RequestPriority = ({ priority }) => {
  const level = priorityLevel(priority);
  const badge = className(level);
  return <span className={badge}>{level}</span>;
};

export default RequestPriority;
