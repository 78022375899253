export default (axios, errorHandler) => {
  const getVapidPublicKey = () => {
    return axios
      .get("/web_push/vapid_public_key")
      .then(response => response.data.public_key)
      .catch(errorHandler);
  };

  const registerWebPushSubscription = payload => {
    return axios
      .post("/web_push/register", payload)
      .then(response => response.data.subscription)
      .catch(errorHandler);
  };

  return {
    getVapidPublicKey,
    registerWebPushSubscription,
  };
};
