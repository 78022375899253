import { Button } from "react-bootstrap";
import SettingsConditional, { Flags } from "Components/SettingsConditional";

const Zoom = ({ token }) => {
  return (
    <>
      {token && <p>Bad token. Status: {token.status}</p>}
      <SettingsConditional flag={Flags.ZOOM_AUTHORIZATION_NOTE} />
      <Button as="a" href="/oauth/authorize?platform=zoom">
        Authorize Zoom
      </Button>
    </>
  );
};

export default Zoom;
