import { useState } from "react";
import useDebounceEffect from "Hooks/useDebounceEffect";

const UserSearch = ({ search }) => {
  const [query, setQuery] = useState("");

  const onChange = e => {
    setQuery(e.target.value);
  };

  useDebounceEffect(() => search(query), [query], 400);

  return (
    <div>
      <form>
        <input
          type="text"
          className="form-control"
          placeholder="Search Users..."
          value={query}
          onChange={onChange}
        />
      </form>
    </div>
  );
};

export default UserSearch;
