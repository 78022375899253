export default (axios, errorHandler) => {
  const createFeedback = payload => {
    return axios
      .post("/feedbacks", payload)
      .then(response => response.data)
      .catch(errorHandler);
  };

  const getPendingFeedbackCount = () => {
    return axios
      .get("/feedbacks/pending_count")
      .then(response => response.data.pendingFeedbackCount)
      .catch(errorHandler);
  };

  const getKeywordList = () => {
    return axios
      .get("/feedbacks/keyword_list")
      .then(response => response.data.keywordList)
      .catch(errorHandler);
  };

  const createSuggestedAssistanceFeedback = payload => {
    return axios
      .post("/suggested_assistance_feedbacks", payload)
      .then(response => response.data)
      .catch(errorHandler);
  };

  return {
    createFeedback,
    getPendingFeedbackCount,
    getKeywordList,
    createSuggestedAssistanceFeedback,
  };
};
