import { useEffect, useState } from "react";
import requests from "Requests";
import { pascalCase } from "util/string";

const useUser = (id, isLearner) => {
  const [user, setUser] = useState({});
  const [userDetails, setUserDetails] = useState({});

  useEffect(() => {
    id &&
      requests
        .getUser(id, isLearner)
        .then(response => {
          setUser(response.user);
          setUserDetails(response.userDetails);
        })
        .catch(err => {
          setUser({ error: err });
          setUserDetails({ error: err });
        });
  }, [id, isLearner]);

  const updateUser = update => {
    if (update.type) {
      update.type = pascalCase(update.type);
    }
    return requests.updateUser(user, { user: update }).then(response => {
      setUser(response.user);
      setUserDetails(response.userDetails);
      return response.user;
    });
  };

  const applyTag = (tag, isLearner) => {
    return requests.addAttribution(tag, user, isLearner ? "Learner" : "User").then(attribution => {
      setUser({ ...user, tags: [...user.tags, tag] });
      return attribution;
    });
  };

  const removeTag = (tag, isLearner) => {
    return requests.deleteUserAttribution(user, tag, isLearner).then(response => {
      setUser({ ...user, tags: user.tags.filter(t => t.id !== tag.id) });
      return response;
    });
  };

  return {
    user,
    userDetails,
    applyTag,
    removeTag,
    updateUser,
  };
};

export default useUser;
