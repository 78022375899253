import { Button } from "react-bootstrap";
import { DateTime } from "luxon";
import { Link } from "react-router-dom";
import useTag from "Hooks/useTag";

const TagsListItem = ({ tag, edit, remove, admin }) => {
  const { tag: tagData } = useTag(tag.id);
  const userAttributionsCount = tagData?.userAttributions?.length;
  return (
    <tr>
      <td>{tag.name}</td>
      <td>
        <Link to={`/tags/${tag.id}/edit/attributions`}>
          {userAttributionsCount} {userAttributionsCount === 1 ? "user" : "users"}
        </Link>
      </td>
      <td>
        Match: {tag.matchBonusMultiplier}
        <br />
        Mismatch: {tag.mismatchPenaltyMultiplier}
      </td>
      <td>{DateTime.fromISO(tag.createdAt).toFormat("ff")}</td>
      <td>{tag.settings["category"]}</td>
      {admin && (
        <td style={{ textAlign: "center" }}>
          <Button onClick={() => edit(tag)} className="mr-1" size="sm" variant="outline-primary">
            EDIT
          </Button>
          <Button
            onClick={() => remove(tag)}
            className="mr-1"
            size="sm"
            variant="outline-danger"
            disabled={tag.userAttributionsCount > 0}
          >
            DELETE
          </Button>
        </td>
      )}
    </tr>
  );
};

export default TagsListItem;
