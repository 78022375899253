import { ModalTitle } from "./Typography/Heading";
import "./Modal.scss";
import ModalButton from "./Buttons/ModalButtons";

const Modal = ({ closeModal, title, size, children }) => {
  return (
    <div className={`modal-dialog ${size ? size : ""}`}>
      <div className="modal-content">
        <div className="modal-header">
          <ModalTitle>{title}</ModalTitle>
          <ModalButton.Close closeModal={closeModal} />
        </div>
        <div className="modal-body">{children}</div>
      </div>
    </div>
  );
};

export default Modal;
