import { useEffect, useState } from "react";
import requests from "Requests";

const useCategories = (categoryId, answerId) => {
  const [categoryState, setCategoryState] = useState({
    categories: [],
    category: null,
    answer: answerId ? { id: answerId } : null,
  });

  useEffect(() => {
    requests
      .getCategories()
      .then(categories => {
        let category = categories.find(category => category.id === categoryId);
        setCategoryState(state => ({ ...state, categories, category }));
      })
      .catch(() => {
        // handle error
      });
  }, [categoryId]);

  const selectCategory = categoryId => {
    const category = categoryState.categories.find(category => category.id === categoryId);
    setCategoryState(state => ({ ...state, category }));
    return category;
  };

  const selectAnswer = answerId => {
    const answer =
      categoryState.category &&
      categoryState.category.answers.find(
        answer => parseInt(answer.id, 10) === parseInt(answerId, 10)
      );
    setCategoryState(state => ({ ...state, answer }));

    return answer;
  };

  return {
    categories: categoryState.categories,
    answers: categoryState.category && categoryState.category.answers,
    category: categoryState.category,
    answer: categoryState.answer,
    selectCategory: selectCategory,
    selectAnswer: selectAnswer,
  };
};

export default useCategories;
