import requests from "Requests";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { pascalCase } from "util/string";

const useUsers = (pageNumber, searchQuery) => {
  const {
    isLoading,
    error,
    data: users,
    refetch,
  } = useQuery({
    queryKey: ["users"],
    queryFn: () => requests.getUsers(_, pageNumber, searchQuery),
    ...{
      enabled: false,
    },
  });
  const editMutation = useMutation({
    mutationFn: ({ user, update }) => requests.updateUser(user, { user: update }),
  });
  const queryClient = useQueryClient();

  const updateUser = (user, update) => {
    if (update.type) update.type = pascalCase(update.type);

    editMutation.mutate(
      { user, update },
      {
        onSuccess: () => {
          queryClient.invalidateQueries({ queryKey: ["users"] });
        },
      }
    );
  };

  return {
    data: users || [],
    updateUser,
    isLoading,
    isUpdating: editMutation.isLoading,
    error,
    refetch,
  };
};

export default useUsers;
