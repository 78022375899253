import { Formik, Form } from "formik";
import InputField from "Components/Form/InputField";
import ErrorList from "Components/Form/ErrorList";
import StepIndicator from "Components/Form/StepIndicator";
import SettingsConditional from "Components/SettingsConditional";
import { confirm } from "Components/Confirmation";
import { LLM_recommendation_bot_name as llmBotName } from "../constants/token_api";
import {
  BotRecommendationInput,
  OptionButtonInput,
  UsedVideoConferenceInput,
  StarRatingInput,
} from "Components/Form/CustomInputs";
import { Alert } from "react-bootstrap";
import useCategories from "Hooks/useCategories";
import StepProvider, { useStepContext, Step } from "Context/StepProvider";
import * as Yup from "yup";
import ModalButton from "./Buttons/ModalButtons";

const ManualAssistanceField = props => <InputField prefix="manual-assistance" {...props} />;

const ManualAssistanceForm = ({ student, onSubmit }) => {
  const { count: stepCount, step, next, back } = useStepContext();
  const { categories } = useCategories();

  const initialValues = {
    categoryId: "",
    requestCause: "",
    requestSolution: "",
    rating: "",
    llmBotRecommendation: "",
    usedVideoConference: null,
    llmBotRating: 0,
    llmBotNotes: "",
    notes: "",
    notify: false,
  };

  const assistanceSchema = Yup.object().shape({
    categoryId: Yup.number().required("Please select a category"),
    requestCause: Yup.string().required("Please provide some details"),
    requestSolution: Yup.string().required("Please provide a brief explanation"),
    rating: Yup.number()
      .required("Please select a rating")
      .min(1, "Please select a rating")
      .max(4, "Invalid rating"),
    llmBotRecommendation: Yup.string().required("Please select one of these options"),
    usedVideoConference: Yup.number().required("Please select one of these options"),
    llmBotRating: Yup.number().min(1, "Please select a rating").max(5, "Invalid rating"),
    llmBotNotes: Yup.string(),
    notes: Yup.string(),
    notify: Yup.boolean(),
  });

  const handleSubmit = async (values, { setSubmitting }) => {
    if (!values.notify) {
      onSubmit(values);
    } else if (values.notify) {
      const confirmed = await confirm(
        "Are you sure you would like to notify the education team about this assistance?",
        "Yes",
        "No"
      );

      if (confirmed) onSubmit(values);
    } else {
      setSubmitting(false);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={assistanceSchema}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting, errors, touched, validateForm, setFieldTouched }) => {
        const stepValidation = fieldNames => () =>
          validateForm().then(errors => {
            fieldNames.forEach(name => setFieldTouched(name));
            return fieldNames.some(name => errors[name]);
          });
        return (
          <Form>
            <div className="step-indicator-container">
              <StepIndicator step={step} stepCount={stepCount} />
            </div>
            <ErrorList touched={touched} errors={errors} />
            <Step beforeNext={stepValidation(["requestCause", "requestSolution"])}>
              <ManualAssistanceField
                name="categoryId"
                label="What was the assistance about?"
                component={OptionButtonInput}
                options={categories}
              />
              <ManualAssistanceField
                name="requestCause"
                label="How would you describe the problem you assisted with?"
                as="textarea"
              />
              <ManualAssistanceField
                name="requestSolution"
                label="Briefly explain the solution provided"
                as="textarea"
              />
            </Step>
            <Step
              beforeNext={stepValidation(["rating", "llmBotRecommendation", "usedVideoConference"])}
            >
              <ManualAssistanceField
                name="rating"
                label="How was the student's grasp on the topics covered in this interaction?"
                as="select"
              >
                <option value="" disabled>
                  Please select an option...
                </option>
                <option value="4">It was great!</option>
                <option value="3">It was good.</option>
                <option value="2">It was okay, I guess, maybe it was bad.</option>
                <option value="1">It was lacking fundamentals.</option>
              </ManualAssistanceField>
              <ManualAssistanceField
                name="llmBotRecommendation"
                label="You advised the student to:"
                component={BotRecommendationInput}
              />
              <ManualAssistanceField
                name="usedVideoConference"
                label="Was this assistance:"
                component={UsedVideoConferenceInput}
              />
            </Step>
            <Step beforeNext={stepValidation(["llmBotRating", "llmBotNotes", "notes"])}>
              <ManualAssistanceField
                name="llmBotRating"
                label={`How do you rate ${llmBotName}'s recommendation?`}
                info="A 5-star rating fits to the context, is factually accurate, and represents best practices."
                component={StarRatingInput}
              />
              <ManualAssistanceField
                name="llmBotNotes"
                label="Provide feedback on the relevance and helpfulness of the AI prompt"
                as="textarea"
              />
              <ManualAssistanceField
                name="notes"
                label="Provide feedback to the Education team (how was the issue solved, student attitude and grasp of the topic, etc.)"
                as="textarea"
              />
              <SettingsConditional
                flag={SettingsConditional.NOTIFY_ED_TEAM_FLAG}
                deploymentId={student.deploymentId}
              >
                <Alert variant="warning">
                  <ManualAssistanceField
                    name="notify"
                    label="Notify the education team about this interaction?"
                    type="checkbox"
                    className="form-check-input"
                  />
                </Alert>
              </SettingsConditional>
            </Step>
            <div className="step-button-container">
              {back ? (
                <ModalButton.Back
                  text={back ? "Back" : "Previous Student"}
                  onClick={back ? back : prevForm}
                />
              ) : (
                <div />
              )}
              {step < stepCount - 1 ? (
                <ModalButton.Submit
                  onClick={event => {
                    event.preventDefault();
                    next();
                  }}
                  text="Next"
                />
              ) : (
                <ModalButton.Submit text="Submit" type="submit" disabled={isSubmitting} />
              )}
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

const Wrapper = ({ ...props }) => (
  <StepProvider>
    <ManualAssistanceForm {...props} />
  </StepProvider>
);

export default Wrapper;
