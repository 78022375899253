import { useEffect, useState } from "react";
import requests from "Requests";

const useExternalUser = (currentUser, platform) => {
  const [externalUser, setExternalUser] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const { uid, deploymentId } = currentUser;
  const userType = currentUser.access[0] === "student" ? "Learner" : "Other";

  useEffect(() => {
    if (uid) {
      setIsLoading(true);
      setError(null);

      requests
        .getExternalUser(uid, userType, platform, deploymentId)
        .then(response => setExternalUser(response))
        .catch(err => setError(err.message))
        .finally(() => setIsLoading(false));
    }
  }, [uid, userType, platform, deploymentId]);

  const deactivateExternalUser = (uid, platform, userType, deploymentId) => {
    return requests
      .deactivateExternalUser(uid, platform, userType, deploymentId)
      .then(response => {
        setExternalUser(response);
        return response;
      })
      .catch(err => setError(err.message));
  };

  return {
    externalUser,
    isLoading,
    error,
    deactivateExternalUser,
  };
};

export default useExternalUser;
