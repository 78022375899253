import { useState } from "react";
import classNames from "classnames";
import "./StarRating.scss";

const StarRating = ({
  returnRating,
  initialRating,
  staticRating,
  maxRating = 5,
  showSummary = false,
  disabled,
}) => {
  const [rating, setRating] = useState({
    max: maxRating,
    hover: 0,
    selected: staticRating || initialRating || 0,
  });

  const summaryText = {
    1: "Nonsensical or Otherwise useless",
    2: "Barely got any of it",
    3: "Got some of it",
    4: "Got most of it",
    5: "Totally got it!",
  };

  const setSelected = selected => {
    setRating(state => ({ ...state, selected }));
    returnRating(selected);
  };

  const setHover = hover => setRating(state => ({ ...state, hover }));

  const starSelect = () => {
    const selects = [];
    const starNumbers = ["first", "second", "third", "fourth", "fifth"];
    for (let x = 1; x <= rating.max; x++) {
      selects.push(
        <button
          type="button"
          key={x}
          className={classNames("star", {
            selected: x <= rating.selected || x <= rating.hover,
            disabled: disabled,
          })}
          onMouseEnter={() => disabled || setHover(x)}
          onMouseLeave={() => disabled || setHover()}
          onClick={() => disabled || setSelected(x)}
          aria-hidden={staticRating ? "true" : "false"}
        >
          <span role="img" aria-label={starNumbers[x - 1] + " star of " + maxRating + " stars"}>
            ⭐
          </span>
        </button>
      );
    }
    return selects;
  };

  const starShow = () => {
    const stars = [];
    for (let x = 1; x <= rating.max; x++) {
      stars.push(
        <p
          key={x}
          className={classNames("star", {
            selected: x <= rating.selected,
          })}
          aria-hidden="true"
        >
          <span className="show-only">⭐</span>
        </p>
      );
    }
    return stars;
  };

  return (
    <div className="star-rating">
      <div
        className="stars"
        aria-label={staticRating ? staticRating + " out of " + maxRating + " review" : ""}
      >
        {staticRating ? starShow() : starSelect()}
      </div>
      {staticRating || !showSummary ? null : <small>{summaryText[rating.selected]}</small>}
    </div>
  );
};

export default StarRating;
