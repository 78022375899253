import { useState } from "react";
import { useAppContext } from "Context/AppProvider";
import PaddedContent from "Layouts/PaddedContent";
import { Alert, Card } from "react-bootstrap";
import useTags from "Hooks/useTags";
import useDeployments from "Hooks/useDeployments";
import { isEmpty } from "lodash";
import { pascalCase } from "util/string";
import requests from "Requests";
import InviteForm from "Screens/invites/InviteForm";
import CopyToClipBoard from "Components/CopyToClipboard";
import { ACCESS_LEVELS } from "constants/app";
import "../../Components/Typography/Typography.scss";

const sortByAccess = (a, b) => {
  if (ACCESS_LEVELS[a] && ACCESS_LEVELS[b]) {
    return ACCESS_LEVELS[a] - ACCESS_LEVELS[b];
  } else {
    return 0;
  }
};

const buildInviteResultsMessage = ({ invites, errors }) => {
  // all failed
  if (!isEmpty(errors) && isEmpty(invites))
    return <p className="text-1">All invites have failed, please double-check the addresses</p>;

  // some failed or none failed
  if (invites.length) {
    return (
      <>
        <p className="text-1">
          {isEmpty(errors) ? "All" : "Some"} invites were created successfully!
        </p>
        <p className="text-1">{isEmpty(errors) ? "Invite links" : "Successful invites"}:</p>
        <ul>
          {invites.map((invite, index) => {
            return (
              <li key={index}>
                <div className="flex-container">
                  {invite.email}:&nbsp;
                  <CopyToClipBoard text={invite.url} icon="fa-link" iconCopied="fa-check-square" />
                </div>
              </li>
            );
          })}
        </ul>

        {!isEmpty(errors) && (
          <>
            <p className="text-1">Failed invites:</p>
            <ul>
              {Object.entries(errors).map(([email, errorMessages], index) => {
                return (
                  <li key={index}>
                    <div className="flex-container">
                      {email}:&nbsp;
                      {errorMessages.map((msg, index) => (
                        <p key={index}>{msg.toLowerCase()}</p>
                      ))}
                    </div>
                  </li>
                );
              })}
            </ul>
          </>
        )}
      </>
    );
  }
};

const defineVariant = ({ invites, errors }) => {
  if (isEmpty(errors)) {
    return "success";
  } else if (!isEmpty(errors) && !!invites.length) {
    return "warning";
  } else {
    return "danger";
  }
};

const New = ({ isBulk }) => {
  const { currentUser } = useAppContext();
  const { tags } = useTags();
  const { deployments } = useDeployments();

  // default to null since it can either be an obj or array
  const [inviteResults, setInviteResults] = useState({
    result: null,
    variant: "primary",
  });

  const resetAlertState = () => {
    setInviteResults({
      result: null,
      variant: "primary",
    });
  };

  // prevents admins from creating invites for types above their access level
  const userTypes = currentUser.access
    .map(acc => pascalCase(acc))
    .concat(["Learner"])
    .sort(sortByAccess);

  const singleInvite = inviteData => {
    return requests
      .createInvite(inviteData)
      .then(invite => setInviteResults({ result: invite, variant: "success" }))
      .catch(err => {
        const res = err.response;
        const errorStatus = `${res.status} - ${res.statusText}`;
        const errorMessage = res.data.error ? `: ${res.data.error}` : "";
        resetAlertState({
          messages: [errorStatus + errorMessage],
          variant: "danger",
        });
      });
  };

  const bulkInvite = inviteData => {
    return requests
      .createInvites(inviteData)
      .then(invite => setInviteResults({ result: invite, variant: defineVariant(invite) }))
      .catch(err => {
        const res = err.response;
        const errorStatus = `${res.status} - ${res.statusText}`;
        const errorMessage = res.data.error ? `: ${res.data.error}` : "";
        resetAlertState({
          messages: [errorStatus + errorMessage],
          variant: "danger",
        });
      });
  };

  const submit = inviteData => {
    isBulk ? bulkInvite(inviteData) : singleInvite(inviteData);
  };

  return (
    <PaddedContent>
      <h2>{isBulk ? "Invite users" : "Invite new user"}</h2>
      <Card body>
        {inviteResults.result && (
          <Alert variant={inviteResults.variant}>
            {!isBulk && (
              <>
                <p className="text-1">
                  Invite created successfully! We've sent an email to:&nbsp;
                  <strong>{inviteResults.result.email}</strong> with the invite link below.
                </p>

                <div className="flex-container">
                  Invite link:&nbsp;
                  <CopyToClipBoard
                    text={inviteResults.result.url}
                    icon="fa-link"
                    iconCopied="fa-check-square"
                  />
                </div>
                <p className="text-1"> Fill out the form again to invite another user.</p>
              </>
            )}
            {isBulk && buildInviteResultsMessage(inviteResults.result)}
          </Alert>
        )}
        <InviteForm
          deployments={deployments}
          tags={tags}
          userTypes={userTypes}
          onSubmit={submit}
          onValidate={resetAlertState}
          isBulk={isBulk}
        />
      </Card>
    </PaddedContent>
  );
};

export default New;
