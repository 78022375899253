import RequestQuote from "Components/RequestQuote";
import RequestCategory from "Components/RequestCategory";
import "./Components.scss";

const RequestDetails = ({
  categoryName,
  day,
  activityUrl,
  activityTitle,
  title,
  reason,
  requestedBy,
  resubmission = false,
  priority,
}) => {
  return (
    <>
      {categoryName && <RequestCategory name={categoryName} />}
      {requestedBy && (
        <div>
          <small>Requested by: {requestedBy}</small>
        </div>
      )}
      {priority === -1 && <i className="priority fa-solid fa-circle-exclamation" />}
      <a className="resource-name" href={activityUrl} target="_blank" rel="noopener noreferrer">
        {activityTitle}
        &nbsp;
      </a>
      {day && <span className="badge badge-secondary">{day}</span>}
      {resubmission && <span className="badge badge-primary">Resubmission</span>}

      {reason && <RequestQuote quote={reason} title={title} />}
    </>
  );
};

export default RequestDetails;
