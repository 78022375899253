import { useState } from "react";
import { Button } from "react-bootstrap";

const ACTIVE = "ACTIVE";
const INACTIVE = "INACTIVE";
const PENDING = "PENDING";
const ERROR = "ERROR";

const buttonStyles = {
  [ACTIVE]: "primary",
  [INACTIVE]: "outline-secondary",
  [PENDING]: "warning",
  [ERROR]: "danger",
};

const ToggleButton = ({ name, isActive, activate, deactivate }) => {
  const [toggleState, setToggleState] = useState(isActive ? ACTIVE : INACTIVE);

  const handleActivate = () => {
    setToggleState(PENDING);
    activate()
      .then(() => setToggleState(ACTIVE))
      .catch(() => setToggleState(ERROR));
  };

  const handleDeactivate = () => {
    setToggleState(PENDING);
    deactivate()
      .then(() => setToggleState(INACTIVE))
      .catch(() => setToggleState(ERROR));
  };

  const handleClick = () => {
    if (toggleState === ACTIVE) {
      return handleDeactivate();
    }

    if (toggleState === INACTIVE) {
      return handleActivate();
    }
  };

  return (
    <Button
      size="sm"
      className="mr-1 mb-1"
      variant={buttonStyles[toggleState]}
      onClick={handleClick}
      disabled={toggleState === PENDING || toggleState === ERROR}
    >
      {name}
    </Button>
  );
};

export default ToggleButton;
