import { useAppContext } from "Context/AppProvider";
import { Navigate } from "react-router-dom";
import PaddedContent from "Layouts/PaddedContent";
import DeploymentSettings from "../DeploymentSettings";

const DeploymentSettingsQueue = () => {
  const {
    currentUser,
    tasksHandler: { error },
  } = useAppContext();

  // Do not attempt to render this component if the teacher does not have access.
  // Even though the server will not serve the teacher admin data, the admin page still renders awkwardly withtout this.
  if (!currentUser.isAdmin) return <Navigate to="/" replace />;

  return (
    <PaddedContent>
      {error && (
        <div className="alert alert-danger">
          <strong>{error}</strong>
        </div>
      )}
      <div className="queue-by-location queue-show">
        {currentUser.isAdmin && <DeploymentSettings user={currentUser} />}
      </div>
    </PaddedContent>
  );
};

export default DeploymentSettingsQueue;
