const ButtonContainer = ({ children, forDashboard }) => {
  return forDashboard ? (
    <div className="assistance-buttons">{children}</div>
  ) : (
    <li id="assistance-request-module">
      <span id="assistance-request-actions">{children}</span>
    </li>
  );
};

export default ButtonContainer;
