import { Alert } from "react-bootstrap";

const WorkContextErrors = ({ errors = {} }) => {
  if (Object.keys(errors).length < 1) {
    return null;
  }

  // actual error message/exception gets logged to sentry and console, so these messages are just explanation for learners
  const getActivityErrorMessage = () => {
    // activity value definitions build upon each other, so we only need a message for the earliest step that failed
    // TODO: improve the method by which we suppress Work Context errors in the case of no activity source
    if (errors.selectableActivities?.includes("No valid activity source")) {
      return null;
    }
    if (errors.selectableActivities !== undefined) {
      return {
        variant: "danger",
        text: "Could not load available activites, so activity selection has been skipped.",
      };
    }
    if (errors.recentActivities !== undefined) {
      return {
        variant: "warning",
        text: "Could not load recently viewed activities.",
      };
    }
    if (errors.currentActivity !== undefined) {
      return {
        variant: "warning",
        text: "Could not determine current activity.",
      };
    }
  };

  const activityMessage = getActivityErrorMessage();

  return (
    <>
      {activityMessage && <Alert variant={activityMessage.variant}>{activityMessage.text}</Alert>}
    </>
  );
};

export default WorkContextErrors;
