import ModalBackdrop from "Components/ModalBackdrop";
import ReactMarkdown from "react-markdown";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { vscDarkPlus } from "react-syntax-highlighter/dist/esm/styles/prism";
import Modal from "./Modal";

const determineModalTitle = type => {
  switch (type) {
    case "code":
      return "Request Code Snippet";
    case "error":
      return "Request Error Message";
    default:
      return "Request Details";
  }
};

const determineDetailContent = type => {
  switch (type) {
    case "code":
      return "code snippet";
    case "error":
      return "error message";
    default:
      return "details";
  }
};

const ShowRequestDetailsModal = ({ type, requestDetails, setShowModal }) => {
  return (
    <ModalBackdrop>
      <Modal title={determineModalTitle(type)} closeModal={() => setShowModal(false)}>
        <p>
          The student included the following additional {determineDetailContent(type)} to provide
          context for the request: <br />
        </p>
        <ReactMarkdown
          components={{
            code({ inline, className, children, ...props }) {
              const match = /language-(\w+)/.exec(className || "");
              if (inline)
                return (
                  <code {...props} className={className}>
                    {children}
                  </code>
                );
              return match ? (
                <SyntaxHighlighter {...props} style={vscDarkPlus} language={match[1]} PreTag="div">
                  {String(children).replace(/\n$/, "")}
                </SyntaxHighlighter>
              ) : (
                <div className="chat-code-block">
                  <code {...props} className={className}>
                    {children}
                  </code>
                </div>
              );
            },
          }}
        >
          {`\`\`\`\n${requestDetails}`}
        </ReactMarkdown>
      </Modal>
    </ModalBackdrop>
  );
};

export default ShowRequestDetailsModal;
