export default (axios, errorHandler) => {
  const getWaitTime = (arId, apiUrl) => {
    return axios
      .get(`/predict_queue/${arId}`, {
        withCredentials: false,
        baseURL: apiUrl,
      })
      .then(response => {
        return response.data;
      })
      .catch(err => {
        errorHandler(err);
      });
  };

  return {
    getWaitTime,
  };
};
