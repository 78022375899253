import { Alert } from "react-bootstrap";

const QueueWaitAlerts = ({ errorMessage, alerts }) => {
  if (errorMessage !== "") {
    return (
      <Alert variant="warning">
        <strong>Wait Time Estimate Unavailable: </strong>
        {errorMessage}
      </Alert>
    );
  }
  if (alerts.length > 0) {
    return (
      <>
        {alerts.map(a => (
          <Alert variant={a.variant} key={a.message}>
            <span className="message">{a.message}</span>
            {a.details !== undefined && a.details}
          </Alert>
        ))}
      </>
    );
  }
  return null;
};

export default QueueWaitAlerts;
