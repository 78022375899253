import { Route } from "react-router-dom";
import { ProtectedRoute } from "Components/Routers";
import Feedbacks from "./Feedbacks";
import FeedbackExternal from "./FeedbackExternal/index";

export default [
  <Route key="feedbacks" path="/feedbacks" element={<ProtectedRoute component={Feedbacks} />} />,
  <Route
    key="feedback"
    path="/feedback/:id" // this is the assistance id
    element={<ProtectedRoute component={FeedbackExternal} />}
  />,
];
