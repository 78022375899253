import classNames from "classnames";
import "./AccuracySliderInput.scss";

const AccuracySliderInput = ({ field, disabled }) => {
  const { value } = field;
  const ratingMap = {
    3: "Yes",
    2: "Somewhat",
    1: "Not really",
  };
  return (
    <div className="accuracy-slider">
      <input
        type="range"
        className="custom-range slider-range"
        min="1"
        max="3"
        {...field}
        disabled={disabled}
        aria-valuetext={ratingMap[value]}
      />
      <div className="slider-labels">
        <span
          className={classNames("label-left", {
            selected: value === 3,
          })}
        >
          {ratingMap[3]}
        </span>
        <span
          className={classNames("label-center", {
            selected: value === 2,
          })}
        >
          {ratingMap[2]}
        </span>
        <span
          className={classNames("label-right", {
            selected: value === 1,
          })}
        >
          {ratingMap[1]}
        </span>
      </div>
    </div>
  );
};

export default AccuracySliderInput;
