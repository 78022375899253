import { LLM_recommendation_bot_name } from "../../constants/token_api";

const getBackgroundColour = state => {
  switch (state) {
    case "pending":
    case "in_progress":
      return "warning";
    default:
      return "success";
  }
};

const getDisplayText = (state, canceledAt, cancellationReason) => {
  if (state === "closed" && !canceledAt) return "Solved with Mentor";
  if (state === "pending" || state === "in_progress") return "Ongoing";
  if (cancellationReason === "solved_by_ai") return `Solved with ${LLM_recommendation_bot_name}`;
};

const SuggestedAssistanceStatusBadge = ({ state, canceledAt, cancellationReason }) => {
  const backgroundColour = getBackgroundColour(state);
  const displayText = getDisplayText(state, canceledAt, cancellationReason);
  if (!displayText) return;
  return <div className={`badge badge-${backgroundColour}`}>{displayText}</div>;
};

export default SuggestedAssistanceStatusBadge;
