import { useState, useEffect } from "react";
import Spinner from "Components/Spinner";
import Requests from "Requests";
import classnames from "classnames";
import { uniqueValuesOnly } from "../../../../src/util/array";
import { DateTime } from "luxon";
import { humanize } from "util/datetime";
import "./Assistances.scss";

const AssistanceDetail = ({ label, value, link = false }) => {
  return (
    <>
      <strong>{label}</strong>
      <span>{link ? <a href={link}>{value}</a> : value}</span>
    </>
  );
};

const AssistanceRequest = ({ assistanceRequest, assistance }) => {
  const { request, startAt } = assistanceRequest;
  const { endAt, rating, requestAccuracy, requestSolution, notes } = assistance;
  const started = DateTime.fromISO(startAt).toFormat("DD, t");
  const ended = DateTime.fromISO(endAt).toFormat("DD, t");
  const duration = humanize(DateTime.fromISO(endAt).diff(DateTime.fromISO(startAt)).toMillis());

  const details = [
    { label: "Started", value: started },
    { label: "Finished", value: ended },
    { label: "Duration", value: duration },
    { label: "Category", value: request?.categoryName },
    { label: "Reason", value: request?.reason },
    {
      label: request?.resourceType || "Activity",
      value: request?.resourceName,
      link: request?.resourceLink,
    },
    { label: "Rating", value: rating },
    { label: "Request Accuracy", value: requestAccuracy },
    { label: "Solution", value: requestSolution },
    { label: "Notes", value: notes },
  ];
  return (
    <div className="my-assistances-assistance-request">
      <span className="my-assistances-section-header">Assistance Details</span>
      <div className="my-assistances-assistance-request-details">
        {details.map(
          detail =>
            detail.value && (
              <AssistanceDetail
                key={detail.label}
                label={detail.label}
                value={detail.value}
                link={detail.link}
              />
            )
        )}
      </div>
    </div>
  );
};

const CompleteAssistance = ({ assistance }) => {
  const requestingStudent = assistance.assistanceRequest?.requestor;
  const studentAvatar = requestingStudent?.avatarUrl;
  const requestorName = requestingStudent?.fullName;

  return (
    <div className="my-assistances-top">
      <div className="my-assistances-header">
        {studentAvatar && (
          <div className="student-pic">
            <img src={studentAvatar} alt="student-pic" />
            <p className="student-name">{requestorName}</p>
          </div>
        )}
      </div>
      <AssistanceRequest assistanceRequest={assistance.assistanceRequest} assistance={assistance} />
    </div>
  );
};

const Assistance = ({ expanded, onClick, assistance }) => {
  return (
    <div className={classnames("my-assistances-assistance", { expanded })} onClick={onClick}>
      {expanded ? (
        <CompleteAssistance assistance={assistance} />
      ) : (
        <div className="truncated-assistance">
          Assisted <strong>{assistance.assistanceRequest?.requestor?.fullName}</strong> on{" "}
          <strong>{DateTime.fromISO(assistance.endAt).toFormat("DD")}</strong>
        </div>
      )}
    </div>
  );
};

const Assistances = ({ uid }) => {
  const [assistances, setAssistances] = useState([]);
  const [loading, setLoading] = useState(false);
  const [expandedIds, setExpandedIds] = useState([]);

  useEffect(() => {
    const controller = new AbortController();
    setLoading(true);

    Requests.getUserAssistances(uid, null, controller.signal)
      .then(response => {
        if (!controller.signal.aborted) {
          setAssistances(response);
          setLoading(false);
        }
      })
      .catch(error => {
        if (error.name !== "AbortError") {
          console.error("Fetch error:", error);
          setAssistances([]);
          setLoading(false);
        }
      });
    return () => controller.abort();
  }, [uid]);

  const onAssistanceClick = id => setExpandedIds([...expandedIds, id].reduce(uniqueValuesOnly, []));

  return (
    <div className="d-flex justify-content-center align-items-center m-2">
      {loading ? (
        <p>
          <Spinner /> &nbsp;Loading your assistances...
        </p>
      ) : (
        <div className="my-assistances">
          {assistances?.map(assistance => (
            <Assistance
              key={assistance.id}
              expanded={expandedIds.includes(assistance.id)}
              onClick={() => onAssistanceClick(assistance.id)}
              assistance={assistance}
            />
          ))}
          {assistances?.length === 0 && (
            <p className="my-assistances-message">
              Looks like you haven't completed any assistances.
            </p>
          )}
        </div>
      )}
    </div>
  );
};

export default Assistances;
