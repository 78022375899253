export default function SentryTest(props) {
  return (
    <div id="SentryTest">
      <h1>Sentry Error Test</h1>

      <button onClick={() => runtimeError(props)}>Undefined Function</button>
      <br />
      <button onClick={throwError}>throw</button>
    </div>
  );
}

function runtimeError(props) {
  props.doesNotExist();
}

function throwError() {
  throw new Error("This is a test for sentry");
}
