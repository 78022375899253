import { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { useAppContext } from "Context/AppProvider";
import NotAuthorized from "Components/NotAuthorized";
import Splash from "Components/Splash";
import { isBrowserExtension } from "util/env";
import { useLoadingContext } from "Context/LoadingProvider";
import { storageGet } from "BrowserExtension/utils/storage";

const isBrowserExtensionAndNotSignedIn = currentUser =>
  isBrowserExtension() && !currentUser.isPresent;

export const LoginRoute = ({ component: Component, location, ...rest }) => {
  const {
    queueChannel: { rejected },
    currentUser,
  } = useAppContext();
  const [authStatus, setAuthStatus] = useState("");
  const { isLoading } = useLoadingContext();

  useEffect(() => {
    storageGet("authStatus").then(data => {
      // TODO: storageGet needs to be refactored
      // Right now, it returns an object for the browser extension
      // and a string for the rudder web app
      // Should be refactored to return the same type
      const status = isBrowserExtension() ? data.authStatus : data;
      if (status) setAuthStatus(status);
    });
  }, []);

  const shouldRenderSignInComponent = () =>
    isBrowserExtensionAndNotSignedIn(currentUser) || rejected || !isLoading;

  // TODO: This needs to be simplified
  return (
    <>
      {shouldRenderSignInComponent() ? <Component {...rest} /> : <Splash />}
      {currentUser.isPresent && authStatus === "logged-in" && (
        <Navigate
          to={{
            pathname: "/",
            state: {
              from: location,
            },
          }}
        />
      )}
    </>
  );
};

export const ProtectedRoute = ({
  component: Component,
  admin,
  teacher,
  superAdmin,
  systemAdmin,
  ...rest
}) => {
  const app = useAppContext();
  const { rejected } = app.queueChannel;
  const currentUser = app.currentUser;

  const authorized = () => {
    if (currentUser.isPresent) {
      let authorized = admin ? currentUser.isAdmin : true;
      authorized = authorized && (teacher ? currentUser.isTeacher : true);
      authorized = authorized && (systemAdmin ? currentUser.isSystemAdmin : true);
      return authorized && (superAdmin ? currentUser.isSuperAdmin : true);
    } else {
      return "pending";
    }
  };

  // TODO: This needs to be simplified
  return (
    <>
      {(isBrowserExtensionAndNotSignedIn(currentUser) || rejected) && (
        <Navigate
          to={{
            pathname: "/sign-in",
            search: location.search,
            state: {
              from: location,
            },
          }}
        />
      )}
      {authorized() === false && <NotAuthorized />}
      {authorized() === true && <Component {...rest} />}
      {authorized() === "pending" && <Splash />}
    </>
  );
};
