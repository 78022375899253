import { useEffect, useState } from "react";
import classNames from "classnames";
import "./KeywordInput.scss";

const KeywordInput = ({
  field,
  keywords = [],
  helpfulness = 3,
  keywordBtnStyle = "",
  keywordsPerPage = 10,
}) => {
  const { name, value, onChange } = field;
  const [page, setPage] = useState(0);
  const [selectedKeywords, setSelectedKeywords] = useState(value);

  useEffect(() => {
    setPage(0);
    // Helpfulness: 3 -> good sentiment, 2 or 1 -> bad sentiment.
    // The keyword selection is only reset when switching between good and bad sentiment.
    if (!selectedKeywords.some(item => keywords[item])) setSelectedKeywords([]);
  }, [helpfulness]);

  useEffect(() => {
    onChange({ target: { name, value: selectedKeywords } });
  }, [onChange, name, selectedKeywords]);

  const keywordLength = Object.keys(keywords).length;
  if (keywordLength === 0) return <></>;
  const startIndex = page * keywordsPerPage;
  const endIndex = Math.min(startIndex + keywordsPerPage, keywordLength);
  const maxPage =
    Math.floor(keywordLength / keywordsPerPage) - (keywordLength % keywordsPerPage === 0 ? 1 : 0);
  const prevPage = Math.max(page - 1, 0);
  const nextPage = Math.min(page + 1, maxPage);

  const handleOnClick = i =>
    setSelectedKeywords(state =>
      state.includes(i) ? state.filter(item => item !== i) : [...state, i]
    );

  const keywordsList = () =>
    Object.keys(keywords)
      .slice(startIndex, endIndex)
      .map(keyword => {
        const { label } = keywords[keyword];
        const isSelected = selectedKeywords.includes(keyword);

        return (
          <button
            key={keyword}
            type="button"
            className={classNames("btn", keywordBtnStyle, { selected: isSelected })}
            onClick={() => handleOnClick(keyword)}
            aria-label={`${label} ${isSelected ? "selected" : "not selected"}`}
          >
            {label}
          </button>
        );
      });

  const PageButton = ({ buttonShape, next, edgeCase, label, keywordBtnStyle }) => {
    return (
      <button
        className={classNames("page-button", "btn", keywordBtnStyle, {
          disabled: edgeCase,
        })}
        type="button"
        onClick={() => setPage(next)}
        disabled={edgeCase}
        aria-label={label}
        aria-hidden={edgeCase}
      >
        {buttonShape}
      </button>
    );
  };

  return (
    <div className="keywords">
      <div hidden={maxPage === 0}>
        <PageButton
          buttonShape="◁"
          next={prevPage}
          edgeCase={page === 0}
          keywordBtnStyle={keywordBtnStyle}
          label="previous keywords"
        />
      </div>
      <div className="list">{keywordsList()}</div>
      <div hidden={maxPage === 0}>
        <PageButton
          buttonShape="▷"
          next={nextPage}
          edgeCase={page === maxPage}
          keywordBtnStyle={keywordBtnStyle}
          label="next keywords"
        />
      </div>
    </div>
  );
};
export default KeywordInput;
