import { ACCESS } from "../constants/user";

/**
 * Uses the access: [] to check for user roles
 * and determine wether the user is a student or not
 * @param {Array<string>} access array of roles
 * @return {boolean}
 */
export const isStudent = (access = []) => {
  if (!access) return;
  if (Array.isArray(access) === false) {
    throw new Error("Must pass an array of access types");
  }

  return access.some(role => role === ACCESS.STUDENT);
};
