import UserSearch from "./UserSearch";
import User from "./User";
import useUserSearch from "Hooks/useUserSearch";

const AllUsers = ({ onAdd, alreadyTaggedUsers }) => {
  const { searchUsers, users } = useUserSearch();

  const isAlreadyTagged = user => alreadyTaggedUsers.map(u => u.id).includes(user.id);

  return (
    <div>
      <div className="card-body">
        <UserSearch search={searchUsers} />
      </div>
      <ul className="list-group list-group-flush">
        {users.map(user => (
          <User
            onAdd={onAdd}
            user={user}
            key={`user-${user.id}`}
            isAlreadyTagged={isAlreadyTagged(user)}
          />
        ))}
      </ul>
    </div>
  );
};

export default AllUsers;
