export default (axios, errorHandler) => {
  const getDeployments = () => {
    return axios
      .get("/deployments")
      .then(response => response.data.deployments)
      .catch(errorHandler);
  };

  const editDeploymentRouterSettings = (deploymentId, settings) => {
    return axios
      .put(`/deployments/${deploymentId}/router_settings`, settings)
      .then(response => response.data)
      .catch(errorHandler);
  };

  const editDeploymentFeatureSettings = (deploymentId, settings) => {
    return axios
      .put(`/deployments/${deploymentId}/feature_settings`, settings)
      .then(response => response.data)
      .catch(errorHandler);
  };

  const editDeploymentUiSettings = (deploymentId, settings) => {
    return axios
      .put(`/deployments/${deploymentId}/ui_settings`, settings)
      .then(response => response.data)
      .catch(errorHandler);
  };

  return {
    getDeployments,
    editDeploymentRouterSettings,
    editDeploymentFeatureSettings,
    editDeploymentUiSettings,
  };
};
