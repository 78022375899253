import { z } from "zod";

const UserTagSchema = z.object({
  id: z.number(),
  name: z.string(),
  queueNote: z.string().nullish(),
  settings: z
    .object({
      badge: z.object({
        background: z.string(),
      }),
    })
    .nullish(),
});

const OAuthTokenSchema = z.object({
  platform: z.string().nullish(),
  status: z.string().nullish(),
});

const ChatConferenceSchema = z.object({
  link: z.string().nullable(),
  name: z.string().nullable(),
});

const ConferenceSchema = z.object({
  link: z.string().nullish(),
  plaform: z.string().nullish(),
});

const RequestorSchema = z.object({
  uid: z.string(),
  avatarUrl: z.string().nullish(),
  fullName: z.string().nullish(),
  info: z
    .object({
      week: z.string().optional(),
      location: z.string().optional(),
    })
    .nullish(),
  profileLink: z.string().nullish(),
  pronoun: z.string().nullable(),
  socials: z.record(z.string()).nullish(),
  tags: z.array(UserTagSchema).optional(),
  access: z.array(z.string()).optional().nullable(),
});

const UserSchema = z.object({
  id: z.number(),
  uid: z.string(),
  fullName: z.string(),
  pronoun: z.string().nullable(),
  avatarUrl: z.string(),
  socials: z.record(z.string()),
  info: z.record(z.unknown()),
  infoUrl: z.string().nullable(),
  onDuty: z.boolean(),
  firstName: z.string().optional(),
  lastName: z.string(),
  access: z.array(z.string()),
  busy: z.boolean().nullable(),
  tags: z.array(UserTagSchema),
  type: z.string(),
  deploymentId: z.number().nullish(),
  oauthTokens: z.array(OAuthTokenSchema).nullish(),
});

const PodSchema = z.object({
  id: z.number().optional(),
  name: z.string().optional(),
  uuid: z.string().optional(),
  program: z.string().optional(),
});

const RequestSchema = z.object({
  info: z
    .object({
      day: z.string().nullable().optional(),
      tags: z.array(z.unknown()).optional(),
    })
    .nullish(),
  pods: z.array(PodSchema).nullish(),
  route: z.boolean().optional(),
  reason: z.string(),
  categoryId: z.number().optional(),
  form_results: z.unknown().nullable(),
  resourceLink: z.string().optional(),
  resourceName: z.string().optional(),
  resourceType: z.string().optional(),
  resourceUuid: z.string().optional(),
  intake_source: z.string().optional(),
  external_metadata: z.unknown().nullable(),
  requestCode: z.string().optional().nullable(),
  requestErrorMessage: z.string().optional().nullable(),
});

export const AssistanceRequestSchema = z.object({
  id: z.number(),
  requestor: RequestorSchema.nullable(),
  request: RequestSchema,
  startAt: z.string(),
  canceledAt: z.string().nullable(),
  type: z.string(),
  state: z.string(),
  assistor: UserSchema.nullish(),
  conference: ConferenceSchema.nullish(),
  childConference: ConferenceSchema.nullish(),
  conferenceLink: z.string().nullish(),
  conferenceType: z.string().nullish(),
  assistanceStart: z.string().nullish(),
  assistanceId: z.number().nullish(),
  chatConference: ChatConferenceSchema.nullish(),
  recommenderUrl: z.string().nullish(),
  recommendationId: z.string().nullable(),
  queueWaitTimeApiUrl: z.string().nullable(),
  queueMaxWaitTime: z.number().nullable(),
  lead: RequestorSchema.nullish(),
  peers: z.array(RequestorSchema).nullish(),
  cancellationReason: z.string().nullish(),
  transcriptId: z.number().nullish(),
  priority: z.number().nullish(),
});

export const AssistanceRequestsSchema = z.array(AssistanceRequestSchema);

const AssistanceResponseSchema = z.object({
  data: z.object({
    assistanceRequest: AssistanceRequestSchema,
  }),
});

export const AssistanceRequestsResponseSchema = z.object({
  data: z.object({
    assistance_requests: z.array(AssistanceRequestSchema),
  }),
});

export const ChatAssistanceMessageSchema = z.object({
  id: z.number(),
  transcriptId: z.number(),
  author: z.string().nullish(),
  role: z.string(),
  content: z.string().nullish(),
  timestamp: z.string(),
  imageUrls: z.array(z.string()).nullish(),
});

export const ChatAssistanceTranscriptSchema = z.object({
  id: z.number(),
  conferenceId: z.number(),
  conferenceLink: z.string(),
  platform: z.string(),
  threadId: z.string(),
  threadName: z.string(),
  chatAssistanceMessages: z.array(ChatAssistanceMessageSchema),
});

// Infer the types
export type UserTag = z.infer<typeof UserTagSchema>;
export type OAuthToken = z.infer<typeof OAuthTokenSchema>;
export type ChatConference = z.infer<typeof ChatConferenceSchema>;
export type Requestor = z.infer<typeof RequestorSchema>;
export type User = z.infer<typeof UserSchema>;
export type Pod = z.infer<typeof PodSchema>;
export type Request = z.infer<typeof RequestSchema>;
export type AssistanceRequest = z.infer<typeof AssistanceRequestSchema>;
export type AssistanceRequests = z.infer<typeof AssistanceRequestsSchema>;
export type AssistanceResponse = z.infer<typeof AssistanceResponseSchema>;
export type AssistanceRequestsResponse = z.infer<typeof AssistanceRequestsResponseSchema>;
export type ChatAssistanceMessage = z.infer<typeof ChatAssistanceMessageSchema>;
export type ChatAssistanceTranscript = z.infer<typeof ChatAssistanceTranscriptSchema>;
