import useTags from "Hooks/useTags";
import ToggleButton from "Components/ToggleButton";

const Tags = ({ user, applyTag, removeTag }) => {
  const { tags } = useTags();
  const topicTags = tags.filter(tag => tag.settings["category"] === "topic");
  const userTags = user?.tags || [];
  return (
    <div style={{ margin: "2rem" }}>
      {topicTags.length > 1 ? (
        topicTags.map(tag => (
          <ToggleButton
            key={tag.id}
            name={tag.name}
            isActive={userTags.map(t => t.id).includes(tag.id)}
            activate={() => applyTag(tag)}
            deactivate={() => removeTag(tag)}
          />
        ))
      ) : (
        <></>
      )}
    </div>
  );
};

export default Tags;
