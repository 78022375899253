import InputField from "../InputField";
import { LLM_recommendation_bot_name as llmBotName } from "../../../constants/token_api";

const FeedbackField = props => <InputField prefix="feedback" {...props} />;

const LlmBotQuestions = () => {
  return (
    <>
      <FeedbackField
        name="llmBotRecommendationRating"
        label={`How much did ${llmBotName}'s recommendation help you solve your issue?`}
        as="select"
      >
        <option value="" disabled>
          Please select an option...
        </option>
        <option value="not_at_all">Not at all</option>
        <option value="somewhat">Somewhat</option>
        <option value="solved_it">Solved it</option>
        <option value="didnt_use_recommendation">I didn't use the recommendation</option>
      </FeedbackField>
      <label
        htmlFor="llmbot-inappropriate-notify"
        className="alert-warning p-2 d-flex align-items-center"
      >
        <FeedbackField
          name="llmBotInappropriateNotify"
          id="llmbot-inappropriate-notify"
          type="checkbox"
          className="form-check-input me-2"
        />
        Flag the AI recommendation as harmful or inappropriate
      </label>
    </>
  );
};

export default LlmBotQuestions;
