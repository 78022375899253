import { useState } from "react";
import RequestQuote from "Components/RequestQuote";
import StarRating from "Components/Rating/StarRating";
import StudentInfo from "Components/StudentInfo";
import QueueItem from "Components/QueueItem";
import RequestDetails from "Components/RequestDetails";
import FeedbackModal from "./FeedbackModal";
import TimeAgoWithTooltip from "Components/TimeAgoWithTooltip";
import "./Feedbacks.scss";

const Feedback = ({ task }) => {
  const [showModal, setShowModal] = useState(false);
  const request = task.assistanceRequest;
  const student = task.assistanceRequest.requestor;
  const assistor = task.assistance.assistor;
  const assistanceEndTime = task.assistance.endAt;
  const rating = () => {
    if (task.assistance?.feedback) {
      const feedback = task.assistance.feedback;
      return (
        <>
          <RequestQuote quote={feedback.notes} title="Your Feedback" />
          <StarRating staticRating={feedback.rating} />
        </>
      );
    }
  };

  return (
    <QueueItem type="Feedback" disabled={false}>
      <StudentInfo
        student={assistor}
        showDetails={true}
        hideTags={true}
        when={request.startAt}
        details={student.info}
        nonLearner
      />

      <div className="request-details-container blurb">
        <RequestDetails
          categoryName={request.request.categoryName}
          day={request.request.info && request.request.info.day}
          activityUrl={request.request.resourceLink}
          activityTitle={request.request.resourceName}
          reason={request.request.reason}
        />
        {assistanceEndTime && <TimeAgoWithTooltip when={assistanceEndTime} />}
        {rating()}
      </div>
      <div className="actions float-right">
        {task.assistance && !task.assistance.feedback && (
          <button
            key="finish"
            className="btn btn-sm btn-primary btn-main"
            onClick={() => setShowModal(true)}
            disabled={false}
          >
            Provide Feedback
          </button>
        )}
      </div>
      {showModal && (
        <FeedbackModal
          task={task}
          hide={() => {
            setShowModal(false);
          }}
        />
      )}
    </QueueItem>
  );
};

export default Feedback;
