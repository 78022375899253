import { useEffect, useState } from "react";
import { Formik, Form } from "formik";
import InputField from "Components/Form/InputField";
import StepIndicator from "Components/Form/StepIndicator";
import * as Yup from "yup";
import { Button } from "react-bootstrap";
import { LLM_recommendation_bot_name as llmBotName } from "../../../constants/token_api";
import {
  AccuracySliderInput,
  EmoteRatingInput,
  StarRatingInput,
  KeywordInput,
} from "Components/Form/CustomInputs";
import StepProvider, { useStepContext, Step } from "Context/StepProvider";
import "Components/Form/Form.scss";
import requests from "Requests";
import PreRequestQuestions from "Components/Form/CustomInputs/PreRequestQuestions";
import LlmBotQuestions from "Components/Form/CustomInputs/LlmBotQuestions";

const FeedbackField = props => <InputField prefix="feedback" {...props} />;

const getInteractionType = type => {
  switch (type) {
    case "Evaluation":
      return "evaluation";
    case "CodeReview":
      return "code review";
    case "TechInterview":
      return "interview";
    case "noBotActivity":
      return "assistance";
    default:
      return "interaction";
  }
};

const FeedbackForm = ({ handleSubmit, source, feedbackRequestType, receivedRecommendation }) => {
  const [keywordList, setKeywordList] = useState();
  const { count: stepCount, step, next, back } = useStepContext();
  const ratingErrorMsg = "Please select a valid rating.";
  const mentorOnlyRequestTypes = ["Evaluation", "TechInterview", "CodeReview"];
  const interaction = getInteractionType(feedbackRequestType);

  const keywordListByHelpfulness = helpfulness => {
    switch (helpfulness) {
      case 3:
        return keywordList.attitude.good;
      case 2:
        return { ...keywordList.attitude.good, ...keywordList.attitude.bad };
      case 1:
        return keywordList.attitude.bad;
    }
  };

  const noBot = feedbackRequestType === "noBotActivity";
  const mentorOnlyRequest = mentorOnlyRequestTypes.includes(feedbackRequestType);
  const mentorOnly = noBot || mentorOnlyRequest;
  const displayPreRequestQuestions = feedbackRequestType === "pps";
  const displayLlmBotQuestions = feedbackRequestType === "llmBot";

  const initialValues = {
    assistedBy: mentorOnly ? "mentor" : "",
    rating: 0,
    helpfulness: 3,
    waitTimeRating: 0,
    notes: "",
    attitudeKeywords: [],
    technicalKeywords: [],
    feedbackSource: source,
    preRequestImpression: displayPreRequestQuestions ? "" : undefined,
    helpAlreadyRequested: displayPreRequestQuestions ? "" : undefined,
    llmBotRecommendationRating: displayLlmBotQuestions ? "" : undefined,
    llmBotInappropriateNotify: displayLlmBotQuestions ? false : undefined,
    llmBotInappropriateNotes: "",
    recommendationRating: "",
    flagEvaluation: false,
    flagNotes: "",
  };

  useEffect(() => requests.getKeywordList().then(setKeywordList), []);

  let feedbackSchema = Yup.object().shape({
    assistedBy: Yup.string().required("Please indicate what kind of assistance you received."),
    rating: Yup.number().when("assistedBy", {
      is: "none",
      then: feedbackSchema => feedbackSchema.min(0),
      otherwise: feedbackSchema =>
        feedbackSchema.required(ratingErrorMsg).min(1, ratingErrorMsg).max(5, ratingErrorMsg),
    }),
    helpfulness: Yup.number()
      .required("Please select a rating")
      .min(1, "Invalid rating")
      .max(3, "Invalid rating"),
    waitTimeRating: Yup.number().when("assistedBy", {
      is: "llm-bot",
      then: feedbackSchema => feedbackSchema.min(0),
      otherwise: feedbackSchema =>
        mentorOnlyRequest
          ? feedbackSchema.min(0)
          : feedbackSchema.required(ratingErrorMsg).min(1, ratingErrorMsg).max(5, ratingErrorMsg),
    }),
    notes: Yup.string(),
    attitudeKeywords: Yup.array().when("assistedBy", {
      is: val => val === "none" || val === "llm-bot",
      then: feedbackSchema => feedbackSchema.min(0),
      otherwise: feedbackSchema =>
        mentorOnlyRequest
          ? feedbackSchema.min(0)
          : feedbackSchema
              .required()
              .min(
                keywordList?.attitude?.good && keywordList?.attitude?.bad ? 1 : 0,
                "Please select a keyword about the mentor during the interaction."
              ),
    }),
    technicalKeywords: Yup.array(),
    preRequestImpression: displayPreRequestQuestions
      ? Yup.string().required("Please indicate what you did before requesting assistance")
      : undefined,
    helpAlreadyRequested: displayPreRequestQuestions
      ? Yup.string().required("Please indicate if you already had requested assistance")
      : undefined,
    llmBotRecommendationRating: displayLlmBotQuestions
      ? Yup.string().when("assistedBy", {
          is: val => val === "none" || val === "mentor",
          then: feedbackSchema => feedbackSchema.min(0),
          otherwise: feedbackSchema => feedbackSchema.required(ratingErrorMsg),
        })
      : undefined,
    llmBotInappropriateNotify: displayLlmBotQuestions ? Yup.boolean() : undefined,
    llmBotInappropriateNotes: Yup.string(),
    recommendationRating: Yup.string(),
    flagEvaluation: Yup.boolean(),
    flagNotes: Yup.string(),
  });

  const onSubmit = values => {
    // strip out mentor critique if assistance was helpful
    if (values.helpfulness > 2) values.mentorCritique = null;
    handleSubmit(values);
  };

  return (
    <Formik initialValues={initialValues} validationSchema={feedbackSchema} onSubmit={onSubmit}>
      {({ values, isSubmitting, setFieldTouched, validateForm }) => {
        const stepValidation = fieldNames => () =>
          validateForm().then(errors => {
            fieldNames.forEach(name => setFieldTouched(name));
            return fieldNames.some(name => errors[name]);
          });

        const keywordListLoaded =
          (keywordList?.attitude?.good && values.helpfulness === 3) ||
          (keywordList?.attitude?.bad && values.helpfulness !== 3);
        const noOneHelped = values.assistedBy === "none";
        const botOnly = values.assistedBy === "llm-bot";
        const mentorHelped = values.assistedBy === "mentor";
        const bothHelped = values.assistedBy === "both";

        return (
          <Form>
            <div className="step-indicator-container">
              <StepIndicator step={step} stepCount={stepCount} className="step-indicator" />
            </div>
            {!mentorOnly && (
              <Step beforeNext={stepValidation(["assistedBy"])}>
                <FeedbackField
                  name="assistedBy"
                  label="Who assisted you with this request?"
                  as="select"
                >
                  <option value="" disabled>
                    Please select an option...
                  </option>
                  <option value="none">No one</option>
                  <option value="llm-bot">{llmBotName}</option>
                  <option value="mentor">Mentor(s)</option>
                  <option value="both">Both {llmBotName} and Mentor(s)</option>
                </FeedbackField>
              </Step>
            )}
            <Step beforeNext={stepValidation(["rating", "waitTimeRating"])}>
              {noOneHelped && (
                <FeedbackField
                  name="waitTimeRating"
                  label="How did you feel about the length of time you had to wait to receive assistance?"
                  component={EmoteRatingInput}
                />
              )}
              {(mentorHelped || bothHelped) && (
                <>
                  <FeedbackField
                    name="rating"
                    label={`How would you rate this ${interaction} overall?`}
                    component={StarRatingInput}
                  />
                  {!mentorOnlyRequest && (
                    <FeedbackField
                      name="waitTimeRating"
                      label="How did you feel about the length of time you had to wait to receive assistance from a mentor?"
                      component={EmoteRatingInput}
                    />
                  )}
                </>
              )}
              {botOnly && !bothHelped && (
                <FeedbackField
                  name="rating"
                  label="How would you rate this interaction overall?"
                  component={StarRatingInput}
                />
              )}
              {receivedRecommendation && (
                <FeedbackField
                  name="recommendationRating"
                  label={`How would you rate the similar previous request that ${llmBotName} recommended to you?`}
                  as="select"
                >
                  <option value="" disabled>
                    Please select an option...
                  </option>
                  <option value="helpful">It solved my issue</option>
                  <option value="somewhat-helpful">It was somewhat helpful</option>
                  <option value="not-helpful">It was not helpful at all</option>
                  <option value="did-not-use">I did not visit the recommended conversation</option>
                </FeedbackField>
              )}
              {(botOnly || bothHelped) && <LlmBotQuestions />}

              {values.llmBotInappropriateNotify && (
                <FeedbackField
                  name="llmBotInappropriateNotes"
                  as="textarea"
                  label="Please provide more detail about why you are flagging this AI recommendation as harmful or inappropriate"
                />
              )}
            </Step>
            <Step
              beforeNext={stepValidation([
                "helpfulness",
                ...(keywordList ? ["attitudeKeywords"] : []),
                "notes",
              ])}
            >
              {(mentorHelped || bothHelped) && (
                <FeedbackField
                  name="helpfulness"
                  label={
                    mentorOnlyRequest
                      ? "Was this evaluation helpful to you?"
                      : "Was the mentor able to help you with your request?"
                  }
                  component={AccuracySliderInput}
                />
              )}
              {keywordListLoaded && (mentorHelped || bothHelped) && !mentorOnlyRequest && (
                <FeedbackField
                  name="attitudeKeywords"
                  label="How was the mentor during the interaction?"
                  component={KeywordInput}
                  keywords={keywordListByHelpfulness(values.helpfulness)}
                  helpfulness={values.helpfulness}
                  keywordBtnStyle="btn-secondary"
                />
              )}
              {keywordList?.technical?.bad && !mentorOnlyRequest && (
                <FeedbackField
                  name="technicalKeywords"
                  label="Any technical issue? (Optional)"
                  component={KeywordInput}
                  keywords={keywordList.technical.bad}
                  keywordBtnStyle="btn-info"
                />
              )}
              {feedbackRequestType === "Evaluation" && (
                <label
                  htmlFor="flag-evaluation"
                  className="alert-warning p-2 d-flex align-items-center"
                >
                  <FeedbackField
                    name="flagEvaluation"
                    id="flag-evaluation"
                    type="checkbox"
                    className="form-check-input me-2"
                  />
                  Flag the evaluation feedback as inaccurate, unclear, or inappropriate
                </label>
              )}
              {values.flagEvaluation && (
                <FeedbackField
                  name="flagNotes"
                  as="textarea"
                  label="Please provide more detail about why you are flagging this evaluation"
                />
              )}
              <FeedbackField name="notes" as="textarea" label="Additional feedback (Optional)" />
            </Step>
            {displayPreRequestQuestions && (
              <Step beforeNext={stepValidation(["preRequestImpression", "helpAlreadyRequested"])}>
                <PreRequestQuestions />
              </Step>
            )}
            <div className="step-button-container">
              {back ? (
                <Button onClick={back} variant="secondary">
                  Back
                </Button>
              ) : (
                <div />
              )}
              {step < stepCount - 1 ? (
                <Button
                  onClick={e => {
                    e.preventDefault();
                    next();
                  }}
                >
                  Next
                </Button>
              ) : (
                <Button type="submit" disabled={isSubmitting}>
                  Submit
                </Button>
              )}
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

const Wrapper = ({ ...props }) => (
  <StepProvider>
    <FeedbackForm {...props} />
  </StepProvider>
);

export default Wrapper;
