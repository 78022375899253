const AccuracyRadioInput = ({ field, disabled }) => {
  const radioLabels = {
    1: "Not even close",
    2: "Somewhat",
    3: "Yes",
  };
  const { value } = field;
  return (
    <div className="d-flex justify-content-between">
      {Object.keys(radioLabels)
        .sort((a, b) => b - a)
        .map(key => {
          return (
            <div key={key} className="form-check form-check-inline">
              <input
                className="form-check-input"
                name="requestAccuracy"
                type="radio"
                id={`option-${key}`}
                {...field}
                disabled={disabled}
                value={key}
                checked={value === key}
              />
              <label className="form-check-label" htmlFor={`option-${key}`}>
                {radioLabels[key]}
              </label>
            </div>
          );
        })}
    </div>
  );
};

export default AccuracyRadioInput;
