import { useCallback } from "react";
import notify from "util/notify";

const requestNotificationOptions = (request, type) => {
  switch (type) {
    case "newPersonInQueue": {
      // Notify Mentor that their queue has a new student
      const reason =
        request.request?.reason || request.request?.info?.day || request.formResults?.form_name;
      return {
        body: `${reason}\r\n(Notified b/c you're marked as on duty)`,
        icon: request.requestor.avatarUrl,
      };
    }
    case "assistanceStarted": {
      // Notifiy Student that assistance has started
      const startTime = new Date(request.assistanceStart);
      return {
        body: `Your assistance was started by ${
          request.assistor.fullName
        } at ${startTime.getHours()}:${startTime.getMinutes()}`,
        icon: request.assistor.avatarUrl,
      };
    }
    case "studentWaitingTime": {
      // Notify Mentor when their queue has a student who has been waiting > 20 minutes
      return {
        body: "Your queue has students that have been waiting more than 20 minutes.",
        icon: "/images/logo.png",
      };
    }
    case "longRunningAssistance": {
      // Notify Mentor when their current assistance has been going for more than 30 minutes and their queue has at least one student waiting
      return {
        body: `Your current assistance has been running for more than 30 minutes, there are ${request} students waiting in your queue.`,
        icon: "/images/logo.png",
      };
    }
  }
};

const shouldUpdate = (user, update) => {
  return (
    user &&
    user.onDuty &&
    !user.busy &&
    update &&
    update.state === "pending" &&
    user.uid === (update.assistor?.uid || update.assistance?.assistor?.uid) &&
    update.assistanceRequest.type !== "TaskRequest"
  );
};

const shouldNotifyStudent = (user, update) => {
  return update.state === "in_progress" && user.uid === update.requestor.uid;
};

const handleQueueUpdate = (user, update) => {
  if (shouldUpdate(user, update)) {
    const assistanceRequest = update.assistanceRequest;
    const title = `Assistance Requested by ${assistanceRequest.requestor.fullName}`;
    const options = requestNotificationOptions(assistanceRequest, "newPersonInQueue");
    notify(title, options);
  }
};

const handleRequestUpdate = (user, update) => {
  if (update && shouldNotifyStudent(user, update)) {
    const title = `Assistance started`;
    const options = requestNotificationOptions(update, "assistanceStarted");
    notify(title, options);
  }
};

const handleQueueTimeNotice = () => {
  const title = "Students waiting";
  const options = requestNotificationOptions(null, "studentWaitingTime");
  notify(title, options);
};

const handleLongAssistance = update => {
  const title = "Students waiting";
  const options = requestNotificationOptions(update, "longRunningAssistance");
  notify(title, options);
};

const useNotifications = () => {
  const notificationHandler = useCallback(data => {
    const currentUserString = localStorage.getItem("currentUser");
    let user = null;

    try {
      user = JSON.parse(currentUserString);
    } catch (error) {
      console.error("Invalid JSON in localStorage:", currentUserString);
      return;
    }

    const update = Array.isArray(data.object) ? data.object[data.object.length - 1] : data.object;

    switch (data.type) {
      case "queueUpdate":
        handleQueueUpdate(user, update);
        break;
      case "requestUpdate":
        handleRequestUpdate(user, update);
        break;
      case "queueTimeNotice":
        handleQueueTimeNotice();
        break;
      case "longAssistance":
        handleLongAssistance(update);
        break;
      default:
        break;
    }
  }, []);

  return {
    notificationHandler,
  };
};

export default useNotifications;
