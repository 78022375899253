const useRequestState = () => {
  const RequestStatuses = {
    IN_PROGRESS: "in-progress",
    WAITING: "waiting",
    NONE: "none",
  };

  const requestState = update => {
    if (
      !update ||
      !update.object ||
      update.object.state === "closed" ||
      update.state === "closed"
    ) {
      return { status: RequestStatuses.NONE };
    }

    const {
      id,
      state,
      assistor,
      conferenceLink,
      conferenceType,
      childConference,
      assistanceId,
      request,
      requestor,
      recommenderUrl,
      recommendationId,
      queueWaitTimeApiUrl,
      queueMaxWaitTime,
    } = update.object;

    const response = {
      conferenceLink,
      conferenceType,
      id,
      request,
      requestor,
      childConference,
      recommenderUrl,
      recommendationId,
      queueWaitTimeApiUrl,
      queueMaxWaitTime,
      state,
    };

    if (state === "in_progress") {
      response.status = RequestStatuses.IN_PROGRESS;
      response.assistorName = assistor.fullName;
      response.assistanceId = assistanceId;
    } else {
      response.status = RequestStatuses.WAITING;
    }

    return response;
  };

  const getButtonInfo = (props = {}) => {
    const {
      forDashboard,
      currentRequestState,
      queueClosed,
      allowAIOnly,
      inPrep,
      requestBeforeQueueEnabled,
      profileIncomplete,
      allowAsync,
      teachers,
      chatAssistance,
      externalChannel,
    } = props;

    const buttonConfigKey = () => {
      if (currentRequestState.status === RequestStatuses.IN_PROGRESS) return "isInProgress";
      if (currentRequestState.status === RequestStatuses.WAITING) return "isWaiting";
      if (profileIncomplete) return "isProfileIncomplete";
      if (queueClosed && !allowAIOnly && !requestBeforeQueueEnabled) return "isQueueClosed";
      if ((queueClosed || inPrep) && allowAIOnly) return "isAIOnly";
      if (!allowAsync && !teachers.length) return "noMentors";
      if (chatAssistance && externalChannel === "missing") return "missingExternalChannel";

      return "default";
    };

    return {
      isInProgress: {
        style: "btn-success",
        text: `${currentRequestState?.assistorName} assisting`,
        title: "Click to view the details of your assistance request.",
      },
      isWaiting: {
        style: forDashboard ? "cancel" : "btn-outline-warning",
        text: "View Request",
        title: "Click to view the details of your assistance request.",
      },
      isProfileIncomplete: {
        style: "btn-outline-info",
        text: "Finish Setup",
        title: "Complete your profile before you can make requests",
      },
      isQueueClosed: {
        style: "btn-outline-secondary",
        text: "Queue Closed",
        title: "The assistance queue is currently closed.",
      },
      isAIOnly: {
        style: "btn-info",
        text: "Larry AI Bot Assistance",
        title: "The assistance queue is only available for Larry AI assistance.",
      },
      noMentors: {
        style: "btn-outline-warning",
        text: "No Mentors Available",
        title: "No Mentors Available",
      },
      missingExternalChannel: {
        style: "btn-outline-warning",
        text: "Missing Discord Connection",
        title: "Could not connect to Discord. Please contact your SSC.",
      },
      default: {
        style: "btn-primary",
        text: "Request Assistance",
        title: "Request Assistance",
      },
    }[buttonConfigKey(props)];
  };

  return { RequestStatuses, requestState, getButtonInfo };
};

export default useRequestState;
