import { Link } from "react-router-dom";
import "./Components.scss";

const avatarClassName = (busy, onDuty) => {
  let name = "queue-avatar-img ";

  if (busy) return (name += "busy");
  if (onDuty) return (name += "on-duty");
  return name;
};

const Avatar = ({ to, src, busy, onDuty, name }) => {
  return to ? (
    <Link to={to}>
      <img className={avatarClassName(busy, onDuty)} title={name || "User Avatar"} src={src} />
    </Link>
  ) : (
    <img className={avatarClassName(busy, onDuty)} title={name || "User Avatar"} src={src} />
  );
};

export default Avatar;
