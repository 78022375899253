import { isBrowserExtension } from "util/env";
import logo from "Assets/images/lhl-logo.svg";
import "../../Components/Components.scss";

const PoweredBy = () => {
  return (
    <div
      className="powered-by-anchor"
      href="http://www.lighthouselabs.com"
      size={isBrowserExtension() ? "medium" : "large"}
    >
      <small>POWERED BY</small>
      <img src={logo} alt="lighthouse labs logo"></img>
    </div>
  );
};
export default PoweredBy;
