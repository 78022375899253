import ToggleButton from "Components/ToggleButton";
import useTags from "Hooks/useTags";
import "./Tags.scss";
import { startCase } from "lodash";

const Tags = ({ user, applyTag, removeTag }) => {
  const { tags } = useTags();

  const categorizedTags = {};

  tags.forEach(tag => {
    const category = tag.settings?.category || "Uncategorized";

    if (!categorizedTags[category]) categorizedTags[category] = [];
    categorizedTags[category].push(tag);
  });

  const tagsByCategory = Object.keys(categorizedTags).map(category => (
    <div key={category}>
      <h5>{startCase(category)}</h5>
      {categorizedTags[category].map(tag => (
        <ToggleButton
          key={tag.id}
          name={tag.name}
          isActive={user.tags.map(t => t.id).includes(tag.id)}
          activate={() => applyTag(tag, user.uid, true)}
          deactivate={() => removeTag(tag, user.uid, true)}
        />
      ))}
    </div>
  ));

  return <div className="tags">{tagsByCategory}</div>;
};

export default Tags;
