import { useState, useEffect, useCallback } from "react";
import { useParams, useLocation } from "react-router-dom";
import { useAppContext } from "Context/AppProvider";
import FeedbackModal from "../Feedbacks/FeedbackModal";
import ModalBackdrop from "Components/ModalBackdrop";
import NoPendingFeedbacksModal from "./NoPendingFeedbacksModal";
import CompletionModal from "./CompletionModal";

const Status = {
  Loading: "Loading",
  NoPermission: "NoPermission",
  HasPermission: "HasPermission",
  HasFeedback: "HasFeedback",
  Completion: "Completion",
};

const FeedbackExternal = () => {
  const { id } = useParams();
  const {
    currentUser,
    tasksHandler: { feedbacks, pendingFeedbacks },
  } = useAppContext();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const feedbackSource = queryParams.get("source") || "bot:discord";
  const [status, setStatus] = useState(Status.Loading);
  const [completion, setCompletion] = useState(false);

  const task = pendingFeedbacks().find(
    t => t.assistance?.id === Number(id) && t.assistanceRequest.requestor.uid === currentUser?.uid
  );

  const existingFeedback = feedbacks().find(
    t => t.assistance?.id === Number(id) && t.assistanceRequest.requestor.uid === currentUser?.uid
  );

  const updateStatus = useCallback(async () => {
    if (completion) return setStatus(Status.Completion);
    if (existingFeedback) return setStatus(Status.HasPermission);
    if (task) return setStatus(Status.HasFeedback);

    try {
      const assistanceRequest = await Requests.getAssistanceRequestByAssistance(id);
      if (assistanceRequest && currentUser) {
        if (assistanceRequest.requestor?.uid !== currentUser?.uid) {
          setStatus(Status.NoPermission);
        }
      }
    } catch {
      setStatus(Status.NoPermission);
    }
  }, [id, currentUser, task, existingFeedback, completion]);

  useEffect(() => {
    updateStatus();
  }, [updateStatus]);

  const modalComponents = {
    [Status.Loading]: <NoPendingFeedbacksModal loading />,
    [Status.HasFeedback]: (
      <FeedbackModal task={task} externalSource={feedbackSource} setCompletion={setCompletion} />
    ),
    [Status.Completion]: <CompletionModal />,
    [Status.HasPermission]: <NoPendingFeedbacksModal hasPermission />,
    [Status.NoPermission]: <NoPendingFeedbacksModal noPermission />,
  };

  return (
    <ModalBackdrop center blur>
      {modalComponents[status] || null}
    </ModalBackdrop>
  );
};

export default FeedbackExternal;
