export default (axios, errorHandler) => {
  const getFlags = () => {
    return axios
      .get(`/flags`)
      .then(response => {
        return response.data.flags;
      })
      .catch(err => {
        errorHandler(err);
      });
  };

  return {
    getFlags,
  };
};
