import requests from "Requests";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";

const useInvites = (pageNumber, searchQuery, userType) => {
  const queryClient = useQueryClient();
  const onSuccess = () => queryClient.invalidateQueries({ queryKey: ["invites"] });
  const { isLoading, error, data, refetch } = useQuery({
    queryKey: ["invites"],
    queryFn: () => requests.getInvites(pageNumber, searchQuery, userType),
  });
  const addMutation = useMutation({ mutationFn: requests.createInvite, ...{ onSuccess } });
  const deleteMutation = useMutation({ mutationFn: requests.deleteInvite, ...{ onSuccess } });

  return {
    invites: data?.invites || [],
    lastPage: data?.last_page,
    createInvite: addMutation.mutate,
    deleteInvite: deleteMutation.mutate,
    refetch,
    isLoading,
    isUpdating: addMutation.isLoading || deleteMutation.isLoading,
    error,
  };
};

export default useInvites;
