import { useParams } from "react-router-dom";
import { useAppContext } from "Context/AppProvider";
import { ModalTitle } from "./Typography/Heading";
import { Button, Alert } from "react-bootstrap";
import { capitalize } from "util/string";
import ModalBackdrop from "./ModalBackdrop";
import useExternalUser from "Hooks/useExternalUser";

const determineIcon = platform => {
  if (platform === "discord") return <i className="fa fab fa-discord" />;
};

const ReauthorizationModal = () => {
  const { platform } = useParams();
  const capitalizedPlatform = capitalize(platform);
  const { currentUser } = useAppContext();
  const { externalUser, error, deactivateExternalUser } = useExternalUser(
    currentUser,
    capitalizedPlatform
  );

  const deactivate = () => {
    deactivateExternalUser(currentUser, capitalizedPlatform).then(() =>
      window.location.replace(
        `${process.env.REACT_APP_SERVER}/oauth/authorize?platform=${platform}`
      )
    );
  };

  return (
    <ModalBackdrop id="reauthorization-modal" center blur>
      <div className="modal-dialog dialog-wide">
        <div className="modal-content">
          <div className="modal-header">
            <ModalTitle>Reauthorize Your Account</ModalTitle>
          </div>
          {platform && !error && (
            <div className="modal-body">
              <p>
                Deactivate your previous authorization with {capitalizedPlatform}; you will be
                prompted to reauthorize after your old authorization is deleted. You will be able to
                select your {capitalizedPlatform} account in the next window.{" "}
                <strong>Make sure you select the account you use with Lighthouse Labs.</strong>
              </p>
              {externalUser.id ? (
                <Button onClick={deactivate}>
                  {determineIcon(platform)} Reauthorize with {capitalizedPlatform}
                </Button>
              ) : (
                <>
                  <p>Your Rudder account is not currently authorized with {capitalizedPlatform}.</p>{" "}
                  <Button onClick={deactivate}>
                    {determineIcon(platform)} Authorize with {capitalizedPlatform}
                  </Button>
                </>
              )}
            </div>
          )}
          {!platform && (
            <div className="modal-body">
              <Alert>Cannot deactivate your account without specifying the platform.</Alert>
            </div>
          )}
          {error && (
            <div className="modal-body">
              <Alert variant="danger">Error: {error}</Alert>
              <p>
                We were unable to deactivate your account with {capitalize(platform)}. This may be
                because we do not currently have an authorization on file. Please contact your SSC
                for help or click below to authorize with {capitalizedPlatform} now.
              </p>
              <a href={`${process.env.REACT_APP_SERVER}/oauth/authorize?platform=${platform}`}>
                <Button>{determineIcon(platform)} Authorize your account with Discord</Button>
              </a>
            </div>
          )}
        </div>
      </div>
    </ModalBackdrop>
  );
};

export default ReauthorizationModal;
