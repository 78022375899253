export default (axios, errorHandler) => {
  const getTag = id => {
    return axios
      .get(`/tags/${id}`)
      .then(response => response.data)
      .catch(errorHandler);
  };

  const getTags = (pageNumber, searchQuery, category) => {
    const params = { tag: {}, page: pageNumber, search_query: searchQuery, category: category };
    return axios
      .get(`/tags`, { params })
      .then(response => response.data)
      .catch(errorHandler);
  };

  const addTag = tag => {
    const { background, text, category, ...tagWithoutSettings } = tag;

    const params = {
      tag: tagWithoutSettings,
      background: background,
      text: text,
      category: category,
    };

    return axios
      .post("/tags", params)
      .then(response => response.data)
      .catch(errorHandler);
  };

  const editTag = tag => {
    const { background, text, category, ...tagWithoutSettings } = tag;

    const params = {
      tag: tagWithoutSettings,
      background: background,
      text: text,
      category: category,
    };

    return axios
      .put(`/tags/${tag.id}`, params)
      .then(response => response.data)
      .catch(errorHandler);
  };

  const deleteTag = tag => {
    return axios
      .delete(`/tags/${tag.id}`)
      .then(response => response)
      .catch(errorHandler);
  };

  const addAttribution = (tag, user, taggableType = "User") => {
    return axios
      .post(`/tags/${tag.id}/attributions`, {
        tag_attribution: { taggable_type: taggableType, taggable_id: user.id },
      })
      .then(response => response.data)
      .catch(errorHandler);
  };

  const deleteAttribution = (tag, attribution) => {
    return axios
      .delete(`/tags/${tag.id}/attributions/${attribution.id}`)
      .then(response => response)
      .catch(errorHandler);
  };

  return {
    getTag,
    getTags,
    addTag,
    editTag,
    deleteTag,
    addAttribution,
    deleteAttribution,
  };
};
