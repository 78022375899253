export default (axios, errorHandler) => {
  const getStudents = () => {
    return axios
      .get(`/students`)
      .then(response => response.data.students || [])
      .catch(errorHandler);
  };

  return {
    getStudents,
  };
};
