import { useEffect, useState } from "react";
import requests from "Requests";

const useTag = id => {
  const [tag, setTag] = useState({});

  useEffect(() => {
    id &&
      requests
        .getTag(id)
        .then(setTag)
        .catch(err => setTag({ error: err }));
  }, [id]);

  const createTag = newTag => {
    return requests.addTag(newTag).then(tag => {
      setTag(tag);
      return tag;
    });
  };

  const editTag = state => {
    return requests.editTag(state).then(updatedTag => {
      setTag(updatedTag);
      return updatedTag;
    });
  };

  const deleteTag = () => {
    return requests.deleteTag(tag).then(() => {
      setTag({});
      return {};
    });
  };

  const addAttribution = user => {
    return requests
      .addAttribution(tag, user)
      .then(attribution => {
        setTag({
          ...tag,
          userAttributions: [...tag.userAttributions, attribution],
        });
        return attribution;
      })
      .catch(err => {
        return Promise.reject(err);
      });
  };

  const deleteAttribution = attribution => {
    return requests
      .deleteAttribution(tag, attribution)
      .then(() => {
        setTag({
          ...tag,
          userAttributions: tag.userAttributions.filter(att => att.id !== attribution.id),
        });
        return attribution;
      })
      .catch(err => {
        return Promise.reject(err);
      });
  };

  return {
    tag,
    createTag,
    editTag,
    deleteTag,
    addAttribution,
    deleteAttribution,
  };
};

export default useTag;
