import { Navigate } from "react-router-dom";
import { useAppContext } from "Context/AppProvider";
import PaddedContent from "Layouts/PaddedContent";
import AdminQueue from "./AdminQueue";

/**
 * The queue admins use to view all teachers' queues
 */
const Queue = () => {
  const {
    currentUser,
    tasksHandler: { error },
  } = useAppContext();

  // Do not attempt to render this component if the teacher does not have access.
  // Even though the server will not serve the teacher admin data,
  // the admin page still renders awkwardly withtout this.
  if (!currentUser.isAdmin) return <Navigate to="/" replace />;

  return (
    <PaddedContent>
      {error && (
        <div className="alert alert-danger">
          <strong>{error}</strong>
        </div>
      )}
      <div className="queue-by-location queue-show">
        <AdminQueue />
      </div>
    </PaddedContent>
  );
};

export default Queue;
