// Homebrew wrapper module that provides an axios-like method interface for fetch requests.
// Does not replicate all of axios's functionality, but at least provides everything we currently use.
// Aside from handling basic requests, this currently implements:
//  - Automatically translates axios's "withCredentials" config property into fetch "credentials" option
//  - Accepts query parameters as "params" object in config and appends them to endpoint URL
//  - Accepts custom baseURL value via config object, added 2022-06-22
// If anything more is required in the future, feel free to implement it here! - Q 2022-05-25

const baseURL = process.env.REACT_APP_SERVER + "/api";

const request = (endpoint, method, { body, ...customConfig } = {}) => {
  // default headers object mirrors config we used for axios in Requests/index
  // headers defined here can be overridden by providing alternative headers in request config
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    "Cache-Control": "no-cache",
    Pragma: "no-cache",
    Expires: "0",
  };

  const config = {
    method,
    ...customConfig,
    // we include credentials by default in our axios setup
    credentials: customConfig.withCredentials === false ? "omit" : "include",
    headers: new Headers({
      ...headers,
      ...customConfig.headers,
    }),
  };
  if (body) {
    config.body = JSON.stringify(body);
  }

  const params = customConfig.params ? queryParams(customConfig.params) : "";

  // custom baseURL is used for external APIs such as the queue predictor API
  // const url = new URL((customConfig.baseURL || baseURL) + endpoint + params);
  const urlString =
    endpoint.startsWith("http://") || endpoint.startsWith("https://")
      ? endpoint + params
      : (config.baseURL || baseURL) + endpoint + params;
  const url = new URL(urlString);

  const request = new Request(url, config);

  return fetch(request).then(fetchResponse => {
    // read json from fetch response and build an axios-like response object
    return fetchResponse.json().then(data => {
      const response = {
        data,
        status: fetchResponse.status,
        statusText: fetchResponse.statusText,
        headers: fetchResponse.headers,
        config,
        request, // in axios this is usually an XMLHttpRequest instance, but in fetchios it's a Fetch API Request
      };

      // simulate axios request failure handling
      if (fetchResponse.ok) {
        return response;
      } else {
        return Promise.reject({ response });
      }
    });
  });
};

// very simple query param support, doesn't handle axios toJSON or other serialization options
const queryParams = paramsObject => {
  // axios does not include params if they a value of "undefined".
  // use JSON methods to clean undefined values from params (JSON has no undefined type)
  const cleanedParams = JSON.parse(JSON.stringify(paramsObject));

  const params = new URLSearchParams(cleanedParams);
  return "?" + params.toString();
};

const fetchios = {};

["get", "delete"].forEach(method => {
  fetchios[method] = (endpoint, config) => request(endpoint, method.toUpperCase(), config);
});

["post", "put", "patch"].forEach(method => {
  fetchios[method] = (endpoint, body, config) =>
    request(endpoint, method.toUpperCase(), { body, ...config });
});

export default fetchios;
