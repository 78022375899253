import { marked } from "marked";
import { useState } from "react";
import { Button, Alert } from "react-bootstrap";
import "./NotificationsBanner.scss";
import { inIframe } from "util/env";

const HIDE_NOTIFICATIONS_BANNER = "hideNotificationsBanner";

const notificationsLink =
  "https://support.humblebundle.com/hc/en-us/articles/360008513933-Enabling-and-Disabling-Browser-Notifications-in-Various-Browsers";

const NotificationsBanner = ({ message }) => {
  const [hide, setHide] = useState(
    JSON.parse(localStorage.getItem(HIDE_NOTIFICATIONS_BANNER) || "false")
  );
  const [permission, setPermission] = useState(
    "Notification" in window ? Notification.permission : "none"
  );
  const onEnable = () => {
    Notification.requestPermission(setPermission);
  };
  const onHide = () => {
    localStorage.setItem(HIDE_NOTIFICATIONS_BANNER, "true");
    setHide(true);
  };
  const __html = marked(
    message +
      (permission === "denied"
        ? `
    \n\nTo re-enable notifications, click the lock icon in the url above and set the notifications setting to "Allow".
    Click the 'Help' button for more information on setting notification permissions in different browsers.
  `
        : "")
  );

  return inIframe() || hide || permission === "none" || permission === "granted" ? null : (
    <Alert variant="secondary" className="notifications-banner">
      <h5>Browser Notifications</h5>
      <small dangerouslySetInnerHTML={{ __html }} />
      <hr />
      <div className="btns d-flex justify-content-end">
        {permission !== "denied" ? (
          <Button size="sm" onClick={onEnable}>
            Set Notification Permission
          </Button>
        ) : (
          <Button variant="danger" size="sm" onClick={onHide}>
            Keep Notifications Disabled
          </Button>
        )}
        <Button
          variant={permission === "denied" ? "primary" : "secondary"}
          size="sm"
          className="ml-1"
          as="a"
          href={notificationsLink}
          target="_blank"
        >
          Help
        </Button>
      </div>
    </Alert>
  );
};

export default NotificationsBanner;
