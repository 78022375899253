import { useState } from "react";
import { Formik, Form } from "formik";
import { Alert } from "react-bootstrap";
import * as Yup from "yup";
import InputField from "Components/Form/InputField";
import StepIndicator from "Components/Form/StepIndicator";
import SettingsConditional from "Components/SettingsConditional";
import { confirm } from "Components/Confirmation";
import {
  BotRecommendationInput,
  AccuracyRadioInput,
  UsedVideoConferenceInput,
  StarRatingInput,
} from "Components/Form/CustomInputs";
import ErrorList from "Components/Form/ErrorList";
import { LLM_recommendation_bot_name as llmBotName } from "../../constants/token_api";
import "Components/Form/Form.scss";
import "./AssistanceReviewForm.scss";
import StepProvider, { useStepContext, Step } from "Context/StepProvider";
import PreRequestReviewQuestions from "./PreRequestReviewQuestions";
import ModalButton from "Components/Buttons/ModalButtons";

const AssistanceField = props => <InputField prefix="assistance" {...props} />;
const validRequestTypes = ["UserRequest", "ManualRequest"];

const AssistanceReviewForm = ({
  onSubmit,
  assistanceRequest,
  isLead = true,
  prevForm,
  answers,
  isLast,
}) => {
  const { preRequestQuestions, companyQuestions, llmBot, chatAssistance } =
    assistanceRequest?.featureSettings || {};
  const isValidRequestType = validRequestTypes.includes(assistanceRequest?.type);
  const hasNoBotTag = assistanceRequest?.tags.some(tag => tag.name === "no-bot");

  const chatQuestions = chatAssistance && isValidRequestType;
  const llmBotQuestions = llmBot && isValidRequestType && !hasNoBotTag;

  const { count: stepCount, step, next, back } = useStepContext();
  const previousAnswers = answers[assistanceRequest?.id];
  const parentAnswers = answers[assistanceRequest?.parentId];
  const [showDetails, setShowDetails] = useState(false);
  const [optOut, setOptOut] = useState(parentAnswers?.optOut || false);

  const initialValues = {
    requestId: assistanceRequest?.id,
    rating: previousAnswers?.rating || 0,
    requestAccuracy: previousAnswers?.requestAccuracy || parentAnswers?.requestAccuracy || 3,
    llmBotRecommendation:
      previousAnswers?.llmBotRecommendation || parentAnswers?.llmBotRecommendation || "",
    usedVideoConference: chatQuestions
      ? parentAnswers?.usedVideoConference || previousAnswers?.usedVideoConference
      : 1,
    llmBotRating: llmBotQuestions
      ? previousAnswers?.llmBotRating || parentAnswers?.llmBotRating || 0
      : 0,
    requestCause: previousAnswers?.requestCause || parentAnswers?.requestCause || "",
    requestSolution: previousAnswers?.requestSolution || parentAnswers?.requestSolution || "",
    notes: previousAnswers?.notes || parentAnswers?.notes || "",
    llmBotNotes: previousAnswers?.llmBotNotes || parentAnswers?.llmBotNotes || "",
    notify: previousAnswers?.notify || false,
    notifyDetails: previousAnswers?.notifyDetails || "",
    flags: previousAnswers?.flags || {},
    categoryName: assistanceRequest?.requestCategory.name,
    studentPreRequestEffort: preRequestQuestions ? "" : undefined,
    requestSpecificity: companyQuestions ? previousAnswers?.requestSpecificity || "" : undefined,
  };

  let assistanceSchema = optOut
    ? Yup.object()
    : Yup.object().shape({
        rating: Yup.number()
          .required("Please select a rating")
          .min(1, "Please select a rating")
          .max(4, "Invalid rating"),
        requestAccuracy: Yup.number()
          .required("Please select a rating")
          .min(1, "Please select a rating")
          .max(3, "Invalid rating"),
        llmBotRecommendation: llmBotQuestions
          ? Yup.string().required("Please select one of these options")
          : undefined,
        usedVideoConference: Yup.number().required("Please select one of these options"),
        llmBotRating: Yup.number().when("llmBotRecommendation", {
          is: "notApplicable",
          then: assistanceSchema => assistanceSchema.min(0),
          otherwise: assistanceSchema =>
            llmBotQuestions
              ? assistanceSchema
                  .required(`Please rate ${llmBotName}'s recommendation`)
                  .min(1, "Please select a rating")
                  .max(5, "Invalid rating")
              : assistanceSchema.min(0),
        }),
        requestCause: Yup.string(),
        requestSolution: chatQuestions
          ? Yup.string().required("Please describe the solution provided")
          : Yup.string(),
        notes: Yup.string().required("Please provide feedback"),
        llmBotNotes: llmBotQuestions ? Yup.string() : undefined,
        notify: Yup.boolean(),
        studentPreRequestEffort: preRequestQuestions
          ? Yup.string().required("Please indicate the student's level of effort")
          : undefined,
        requestSpecificity: companyQuestions
          ? Yup.number()
              .required("Please select a rating")
              .min(1, "Please select a rating")
              .max(4, "Invalid rating")
          : undefined,
      });

  const toggleDetails = event => {
    event.preventDefault();
    setShowDetails(!showDetails);
  };

  const RequestDetails = () => {
    if (showDetails)
      return (
        <>
          <a href={"#"} onClick={toggleDetails}>
            Hide Details&nbsp;
          </a>
          <span>- {assistanceRequest?.request?.reason}</span>
        </>
      );

    return (
      <a href={"#"} onClick={toggleDetails}>
        Show Details
      </a>
    );
  };

  const StudentFacingWarning = () => {
    if (!chatQuestions) return null;

    return (
      <p className="student-facing-warning">
        Your answer will be seen by the students. Do NOT share any information about the student
        here!
      </p>
    );
  };

  const handleChangeOptOut = (event, resetForm = () => false) => {
    setOptOut(event.target.checked);
    resetForm();
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    if (optOut) {
      onSubmit({ requestId: assistanceRequest.id, optOut });
    } else if (!values?.notify) {
      onSubmit(values);
    } else if (values?.notify) {
      const confirmed = await confirm(
        "Are you sure you want to notify the education team about this assistance?",
        "Yes",
        "No"
      );
      if (confirmed) onSubmit(values);
    } else {
      setSubmitting(false);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={assistanceSchema}
      onSubmit={handleSubmit}
    >
      {({ values, isSubmitting, errors, touched, handleReset, validateForm, setFieldTouched }) => {
        const stepValidation = fieldNames => () =>
          validateForm().then(errors => {
            fieldNames.forEach(name => setFieldTouched(name));
            return fieldNames.some(name => errors[name]);
          });
        return (
          <Form>
            <div className="opt-out-container">
              <div className="form-check">
                <input
                  type="checkbox"
                  className="form-check-input"
                  checked={optOut}
                  onChange={e => handleChangeOptOut(e, handleReset)}
                  id="assistance-opt-out-input"
                />
                <label htmlFor="assistance-opt-out-input" className="form-check-label">
                  Not enough information on this student to review?
                </label>
              </div>
            </div>
            <div className="step-indicator-container">
              <StepIndicator step={step} stepCount={stepCount} className="step-indicator" />
            </div>
            <ErrorList touched={touched} errors={errors} />
            <Step
              beforeNext={stepValidation([
                "rating",
                "requestAccuracy",
                "usedVideoConference",
                "llmBotRecommendation",
              ])}
            >
              <AssistanceField
                name="rating"
                label="How was the student's grasp on the topics covered in this interaction?"
                as="select"
                disabled={optOut}
              >
                <option value="0" disabled>
                  Please select an option...
                </option>
                <option value="4">It was great!</option>
                <option value="3">It was good.</option>
                <option value="2">It was okay, I guess, maybe it was bad.</option>
                <option value="1">It was lacking fundamentals.</option>
              </AssistanceField>
              <AssistanceField
                name="requestAccuracy"
                label="Did the request details match the actual problem?"
                info={RequestDetails()}
                component={AccuracyRadioInput}
                disabled={optOut}
              />
              {values.requestAccuracy > 0 && values.requestAccuracy < 3 && (
                <AssistanceField
                  name="requestCause"
                  label="How would you describe the actual problem?"
                  as="textarea"
                  disabled={optOut}
                />
              )}
              {llmBotQuestions && (
                <AssistanceField
                  name="llmBotRecommendation"
                  label="You advised the student to:"
                  component={BotRecommendationInput}
                  disabled={optOut}
                />
              )}
              {chatQuestions && (
                <AssistanceField
                  name="usedVideoConference"
                  label="Was this assistance:"
                  component={UsedVideoConferenceInput}
                  disabled={optOut}
                />
              )}
            </Step>
            <Step beforeNext={stepValidation(["requestSolution"])}>
              <StudentFacingWarning />
              <AssistanceField
                name="requestSolution"
                label="Briefly explain the solution provided"
                as="textarea"
                disabled={optOut}
              />
            </Step>
            <Step beforeNext={stepValidation(["notes", "llmBotRating"])}>
              {llmBotQuestions && values.llmBotRecommendation !== "notApplicable" && (
                <>
                  <AssistanceField
                    name="llmBotRating"
                    label={`How do you rate ${llmBotName}'s recommendation?`}
                    info="A 5-star rating fits to the context, is factually accurate, and represents best practices."
                    component={StarRatingInput}
                    disabled={optOut}
                  />
                  <AssistanceField
                    name="llmBotNotes"
                    label="Provide feedback on the relevance and helpfulness of the AI prompt"
                    as="textarea"
                    disabled={optOut}
                  />
                </>
              )}
              <AssistanceField
                name="notes"
                label="Provide feedback to the Education team (how was the issue solved, student attitude and grasp of the topic, etc.)"
                as="textarea"
                disabled={optOut}
              />
              <SettingsConditional
                flag={SettingsConditional.NOTIFY_ED_TEAM_FLAG}
                deploymentId={assistanceRequest.requestor.deploymentId}
              >
                <Alert variant="warning" hidden={optOut}>
                  <AssistanceField
                    name="notify"
                    id="assistance-input-notify"
                    label="Notify the education team about this interaction?"
                    type="checkbox"
                    className="form-check-input"
                  />
                </Alert>
                {values.notify && (
                  <AssistanceField
                    name="notifyDetails"
                    label="Include further details for the Education Team (not shared with student)"
                    as="textarea"
                    disabled={optOut}
                  />
                )}
              </SettingsConditional>
            </Step>
            {(preRequestQuestions || companyQuestions) && (
              <Step
                beforeNext={stepValidation([
                  ...(preRequestQuestions ? ["studentPreRequestEffort"] : []),
                ])}
              >
                <PreRequestReviewQuestions
                  preRequestQuestions={preRequestQuestions}
                  companyQuestions={companyQuestions}
                  optOut={optOut}
                />
              </Step>
            )}
            <div className="step-button-container">
              {!isLead || back ? (
                <ModalButton.Back
                  onClick={back ? back : prevForm}
                  text={back ? "Back" : "Previous Student"}
                />
              ) : (
                <div />
              )}
              {!optOut && step < stepCount - 1 ? (
                <ModalButton.Submit
                  text="Next"
                  onClick={event => {
                    event.preventDefault();
                    next();
                  }}
                />
              ) : (
                <ModalButton.Submit
                  text={isLast ? "Submit" : "Next Student"}
                  type="submit"
                  disabled={isSubmitting}
                />
              )}
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

const Wrapper = ({ ...props }) => (
  <StepProvider>
    <AssistanceReviewForm {...props} />
  </StepProvider>
);

export default Wrapper;
