import { Route } from "react-router-dom";
import { LoginRoute } from "Components/Routers";
import SignIn from "./SignIn";
import Register from "./Register";
import Success from "./Success";

export default [
  <Route key="sign-in" path="/sign-in" element={<LoginRoute component={SignIn} />} />,
  <Route
    key="admin-sign-in"
    path="/admin-sign-in"
    element={<LoginRoute admin component={SignIn} />}
  />,
  <Route key="register" path="/register" element={<LoginRoute component={Register} />} />,
  <Route key="success" path="/success" element={<LoginRoute component={Success} />} />,
];
