import { useState } from "react";
import StudentInfo from "Components/StudentInfo";
import { filteredStudents } from "Selectors/queue_selectors";
import "./AddStudentsSelect.scss";
import ModalButton from "./Buttons/ModalButtons";

const AddStudentsSelector = ({
  assistedStudentUIDs,
  selectedStudentUIDs,
  selectStudent,
  students,
}) => {
  const [query, setQuery] = useState("");

  const selectableStudents = query
    ? filteredStudents(students, query).filter(
        student =>
          !assistedStudentUIDs.includes(student.uid) && !selectedStudentUIDs.includes(student.uid)
      )
    : [];

  const studentEntries =
    selectableStudents.length > 0 ? (
      selectableStudents.map((student, i) => {
        return (
          <div className="student-item" key={i}>
            <StudentInfo student={student} showDetails />
            <ModalButton.Submit text="Add" onClick={() => selectStudent(student)} />
          </div>
        );
      })
    ) : (
      <div className="no-students">No results from search</div>
    );

  return (
    <>
      <input
        className="form-control"
        id="add-students-search-input"
        placeholder="Search by first or last name..."
        value={query}
        onChange={e => setQuery(e.target.value)}
      />
      <div className="student-list">{studentEntries}</div>
    </>
  );
};

export default AddStudentsSelector;
