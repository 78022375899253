import { Formik, Form } from "formik";
import InputField from "Components/Form/InputField";
import * as Yup from "yup";
import { Button } from "react-bootstrap";
import { LLM_recommendation_bot_name as llmBotName } from "../../../constants/token_api";
import { StarRatingInput } from "Components/Form/CustomInputs";
import "Components/Form/Form.scss";

const FeedbackField = props => <InputField prefix="feedback" {...props} />;

const AIFeedbackForm = ({ handleSubmit }) => {
  const initialValues = {
    rating: 0,
    llmBotRecommendationRating: "",
    llmBotInappropriateNotify: false,
    llmBotInappropriateNotes: "",
  };

  const ratingErrorMsg = "Please select a valid rating.";
  const feedbackSchema = Yup.object().shape({
    rating: Yup.number().required(ratingErrorMsg).min(1, ratingErrorMsg).max(5, ratingErrorMsg),
    llmBotRecommendationRating: Yup.string().required("Please select a rating"),
    llmBotInappropriateNotify: Yup.boolean(),
    llmBotInappropriateNotes: Yup.string(),
  });

  const onSubmit = values => handleSubmit(values);

  return (
    <Formik initialValues={initialValues} validationSchema={feedbackSchema} onSubmit={onSubmit}>
      {({ values, isSubmitting }) => {
        return (
          <Form>
            <FeedbackField
              name="rating"
              label="How would you rate this interaction overall?"
              component={StarRatingInput}
            />
            <FeedbackField
              name="llmBotRecommendationRating"
              label={`How much did ${llmBotName}'s recommendation help you solve your issue?`}
              as="select"
            >
              <option value="" disabled>
                Please select an option...
              </option>
              <option value="not_at_all">Not at all</option>
              <option value="somewhat">Somewhat</option>
              <option value="solved_it">Solved it</option>
              <option value="didnt_use_recommendation">I didn't use the recommendation</option>
            </FeedbackField>
            <label
              htmlFor="llmbot-inappropriate-notify"
              className="alert-warning p-2 d-flex align-items-center"
            >
              <FeedbackField
                name="llmBotInappropriateNotify"
                id="llmbot-inappropriate-notify"
                type="checkbox"
                className="form-check-input me-2"
              />
              Flag the AI recommendation as harmful or inappropriate
            </label>
            {values.llmBotInappropriateNotify && (
              <FeedbackField
                name="llmBotInappropriateNotes"
                as="textarea"
                label="Please provide more detail about why you are flagging this AI recommendation as harmful or inappropriate"
              />
            )}
            <div className="step-button-container">
              <div />
              <Button type="submit" disabled={isSubmitting}>
                Submit
              </Button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default AIFeedbackForm;
