import { Route, Routes } from "react-router-dom";
import { ProtectedRoute } from "Components/Routers";
import AdminScreens from "./admin";
import SignInScreens from "./sign-in";
import QueueScreens from "./queue";
import UserScreens from "./users";
import LtiScreens from "./lti";
import FeedbackScreens from "./feedback";
import TagsScreens from "./tags";
import InviteScreens from "./invites";
import NotFound from "Components/NotFound";
import NotAuthorized from "Components/NotAuthorized";
import Home from "./Home";
import AuthError from "./AuthError";
import OAuthSuccessModal from "Components/OAuthSuccessModal";
import OAuthUnauthorizedModal from "Components/OAuthUnauthorizedModal";
import ReauthorizationModal from "Components/ReauthorizationModal";

const Screens = () => {
  return (
    <Routes>
      {[
        ...AdminScreens,
        ...SignInScreens,
        ...QueueScreens,
        ...UserScreens,
        ...LtiScreens,
        ...FeedbackScreens,
        ...TagsScreens,
        ...InviteScreens,
      ]}
      <Route key="home" path={"/"} element={<ProtectedRoute component={Home} />} />
      <Route key="home" path={"/popup.html"} element={<ProtectedRoute component={Home} />} />
      <Route
        key="deactivate"
        path="/deactivate/:platform"
        element={<ProtectedRoute component={ReauthorizationModal} />}
      />
      <Route
        key="auth-success"
        path="/auth-success/:platform"
        element={<ProtectedRoute component={OAuthSuccessModal} />}
      />
      <Route
        key="auth-cancel"
        path="/auth-cancel/:platform/:error"
        element={<ProtectedRoute component={OAuthUnauthorizedModal} />}
      />
      <Route key="auth-error" path="/auth/failure" element={<AuthError />} />
      <Route key="not-allowed" path="/not-allowed" element={<NotAuthorized />} />
      <Route key="notauthed" path="/" element={<NotAuthorized />} />
      <Route key="missing" path="/404" element={<NotFound />} />
      <Route key="missing-catch-all" path="/" element={<NotFound />} />
    </Routes>
  );
};

export default Screens;
