const AuthError = () => {
  return (
    <div className="container" style={{ marginTop: 80 }}>
      <h1>Authentication Failed</h1>
      <p>Please clear your cookies and try to log in from compass.</p>
    </div>
  );
};

export default AuthError;
