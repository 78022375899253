import { useState } from "react";
import UserDetail from "./UserDetail";

const Attribution = ({ attribution, onDelete }) => {
  const [isDeleting, setIsDeleting] = useState(false);

  const markForDeletion = () => {
    setIsDeleting(true);
    onDelete(attribution, () => {
      setIsDeleting(false);
    });
  };

  const userTagType = taggable => {
    return taggable[taggable.type];
  };

  return (
    <li className="list-group-item removable-item d-flex justify-content-between">
      <UserDetail user={userTagType(attribution.taggable)}></UserDetail>
      <button
        className="btn btn-sm btn-danger remove-item"
        disabled={isDeleting ? "disabled" : null}
        onClick={markForDeletion}
      >
        X
      </button>
    </li>
  );
};

export default Attribution;
