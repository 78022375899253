import PropTypes from "prop-types";

const ListFilter = ({ query, onChange, clearLabel, placeholder }) => {
  return (
    <div className="input-group border-0 rounded-0">
      <input
        aria-label={placeholder}
        className="form-control border-0 rounded-0 shadow-none"
        onChange={e => {
          e.preventDefault();
          onChange(e.target.value);
        }}
        placeholder={placeholder}
        type="text"
        value={query}
      />
      <div className="input-group-append">
        <button
          onClick={e => {
            e.preventDefault();
            onChange("");
          }}
          className="btn btn-outline-light text-secondary border-0 rounded-0"
          type="button"
        >
          {clearLabel}
        </button>
      </div>
    </div>
  );
};

ListFilter.defaultProps = {
  clearLabel: "x",
  placeholder: "Search...",
};

ListFilter.propTypes = {
  clearLabel: PropTypes.string,
  placeholder: PropTypes.string,
  query: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default ListFilter;
