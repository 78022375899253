import { useState, useEffect } from "react";
import { isBrowserExtension } from "util/env";

/**
 * Hook to listen for Chrome Ports connections and returns the port.
 * @return {{ port: Port }} state
 */
const usePorts = () => {
  const [port, setPort] = useState();

  useEffect(() => {
    if (!isBrowserExtension() || port) return;

    function onPortDisconnect(port) {
      port.onDisconnect.removeListener(onPortDisconnect);
      setPort(undefined);
    }

    function onPortConnected(port) {
      port.onDisconnect.addListener(onPortDisconnect);
      setPort(port);
    }

    chrome.runtime.onConnect.addListener(onPortConnected);
    return () => {
      chrome.runtime.onConnect.removeListener(onPortConnected);
      port && port.disconnect();
    };
  }, [port]);

  return port;
};

export default usePorts;
