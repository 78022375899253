import PaddedContent from "Layouts/PaddedContent";

const LandingPage = () => {
  return (
    <PaddedContent>
      <h1>Landing Page</h1>
    </PaddedContent>
  );
};

export default LandingPage;
