import PendingAssistanceRequest from "./PendingAssistanceRequest";
import ListGroup from "Components/ListGroup";

const PendingEvaluationsList = ({ tasks }) => {
  return (
    <ListGroup icon={tasks.length} title="Open Requests">
      {tasks.map(task => {
        return <PendingAssistanceRequest key={task.id} task={task} />;
      })}
    </ListGroup>
  );
};

export default PendingEvaluationsList;
