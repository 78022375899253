import classNames from "classnames";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useAppContext } from "Context/AppProvider";
import "./MentorDutyButton.scss";

const MentorDutyButton = ({ user, admin, disabled }) => {
  const {
    queueChannel: { toggleDuty },
  } = useAppContext();

  const dutyText = () => {
    if (disabled) {
      return "";
    } else if (user) {
      return `Go ${user.onDuty ? "off" : "on"} duty${admin ? ` for ${user.firstName}` : ""}`;
    }
  };

  const dutyClass = classNames("btn-toggle-duty", "mr-1", "btn", {
    [`btn-outline`]: !user,
    [`btn-outline on-duty`]: user && user.onDuty,
    [`btn-outline off-duty`]: user && !user.onDuty,
  });

  const handleToggleDuty = () => toggleDuty(user);

  return (
    <OverlayTrigger placement="bottom" overlay={<Tooltip>{dutyText()}</Tooltip>}>
      <button className={dutyClass} onClick={handleToggleDuty} disabled={disabled}>
        <i
          className={`${
            user.onDuty ? "fa fa-solid fa-life-ring" : "fa fa-regular fa-circle-xmark"
          }`}
        />
      </button>
    </OverlayTrigger>
  );
};

export default MentorDutyButton;
