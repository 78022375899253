import { useEffect, useReducer } from "react";
import { studentsWithUpdates } from "Selectors/queue_selectors";
import { isStudent } from "util/roles";
import requests from "Requests";
import _ from "lodash";

const reducerActions = {
  SET_STUDENTS: "setStudents",
  UPDATE_STUDENT: "updateStudent",
  ADD_UPDATES: "addUpdates",
  ERROR: "error",
};

const studentReducer = (state, action) => {
  switch (action.type) {
    case reducerActions.SET_STUDENTS: {
      const students = action.data.reduce(function (result, item) {
        result[item.uid] = item;
        return result;
      }, {});
      return { ...state, students };
    }
    case reducerActions.UPDATE_STUDENT: {
      const students = { ...state.students };
      students[action.data.uid] = action.data;

      return { ...state, students };
    }
    case reducerActions.ADD_UPDATES:
      return {
        ...state,
        students: studentsWithUpdates(state.students, action.data),
      };
    case reducerActions.ERROR:
      return { ...state, error: action.data };
    default:
      throw new Error();
  }
};

/**
 * @param {Object[]} updates - queueChannel.studentUpdates - Array of User objects.
 *   Updated when a student's last_assisted_at value changes, etc.
 *   Updates any time the "studentUpdate" socket message is received.
 * @param {Object} user - The current user
 *   Note: Starts off as the initial state of useCurrentUser.jsx, then resolves to the actual user object.
 */
const useStudents = (updates, user) => {
  const [studentsState, dispatchStudentsState] = useReducer(studentReducer, {
    students: {},
    error: null,
  });

  useEffect(() => {
    if (user.uid) {
      requests
        .getStudents()
        .then(students => {
          if (students) {
            dispatchStudentsState({
              type: reducerActions.SET_STUDENTS,
              data: students,
            });
          }
        })
        .catch(() => {
          dispatchStudentsState({
            type: reducerActions.ERROR,
            data: "Could not fetch student data!",
          });
        });
    }
  }, [user.uid]);

  useEffect(() => {
    dispatchStudentsState({ type: reducerActions.ADD_UPDATES, data: updates });
  }, [updates]);

  const getStudent = uid => studentsState.students[uid] || {};

  const getAssistances = uid => {
    const user = getStudent(uid);
    if (user) {
      return requests.getUserAssistances(uid, isStudent(user.access)).then(assistances => {
        dispatchStudentsState({
          type: reducerActions.UPDATE_STUDENT,
          data: { ...user, assistances },
        });
        return assistances;
      });
    }
    return Promise.resolve([]);
  };

  const applyTag = (tag, uid, isLearner) => {
    const user = getStudent(uid);

    return requests.addAttribution(tag, user, isLearner ? "Learner" : "User").then(attribution => {
      dispatchStudentsState({
        type: reducerActions.UPDATE_STUDENT,
        data: { ...user, tags: [...user.tags, tag] },
      });
      return attribution;
    });
  };

  const removeTag = (tag, uid, isLearner) => {
    const user = getStudent(uid);

    return requests.deleteUserAttribution(user, tag, isLearner).then(response => {
      dispatchStudentsState({
        type: reducerActions.UPDATE_STUDENT,
        data: { ...user, tags: user.tags.filter(t => t.id !== tag.id) },
      });
      return response;
    });
  };

  return {
    students: Array.from(
      _.sortBy(Object.values(studentsState.students), student => student.lastAssistedAt)
    ),
    error: studentsState.error,
    getStudent,
    getAssistances,
    applyTag,
    removeTag,
  };
};

export default useStudents;
