import { useAppContext } from "Context/AppProvider";
import { Dropdown, NavLink, NavItem } from "react-bootstrap";
import ResponsiveLabel from "Components/ResponsiveLabel";
import { Link } from "react-router-dom";

const AdminOptions = () => {
  const { currentUser } = useAppContext();
  const icon = (
    <>
      <i className="fa fa-fw fa-lock" />
      <ResponsiveLabel sizes={["small", "xsmall"]}>Admin</ResponsiveLabel>
    </>
  );

  return (
    <Dropdown as={NavItem} align="end" id="basic-nav-dropdown">
      <Dropdown.Toggle as={NavLink}>{icon}</Dropdown.Toggle>
      <Dropdown.Menu className="dropdown-menu dropdown-menu-right" align="end">
        <Dropdown.Item as={Link} to="/tags">
          Manage Tags
        </Dropdown.Item>
        <Dropdown.Item as={Link} to="/users?page=1">
          Manage Users
        </Dropdown.Item>
        <Dropdown.Item as={Link} to="/invites">
          Manage Invites
        </Dropdown.Item>
        {currentUser.isSystemAdmin && (
          <>
            <Dropdown.Divider />
            <Dropdown.Item href="/activeadmin">ActiveAdmin</Dropdown.Item>
          </>
        )}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default AdminOptions;
