import { Formik, Form } from "formik";
import useCancellationReasons from "Hooks/useCancellationReasons";
import InputField from "Components/Form/InputField";
import ErrorList from "Components/Form/ErrorList";
import * as Yup from "yup";
import { Button } from "react-bootstrap";
import Spinner from "./Spinner";
import "Components/Form/Form.scss";

const CancelField = props => <InputField prefix="cancel" {...props} />;

const CancelRequestForm = ({ onSubmit, isAIOnly }) => {
  const { reasons, isLoading, error } = useCancellationReasons();

  const studentFacingReasons = reasons
    ?.filter(({ settings }) => !settings?.admin)
    ?.filter(({ settings }) => isAIOnly === Boolean(settings?.isAIOnly));

  const initialValues = { reason: "", notes: "" };

  const requestSchema = Yup.object().shape({
    reason: Yup.string().required(
      isAIOnly ? "Please select a reason for closing" : "Please select a reason for cancelling"
    ),
    notes: Yup.string(),
  });

  return (
    <Formik initialValues={initialValues} validationSchema={requestSchema} onSubmit={onSubmit}>
      {({ isSubmitting, touched, errors }) => (
        <>
          <ErrorList touched={touched} errors={errors} />
          <Form>
            {isLoading ? (
              <Spinner />
            ) : (
              <CancelField
                name="reason"
                label={
                  isAIOnly
                    ? "Why are you closing this assistance?"
                    : "Why are you cancelling this request?"
                }
                as="select"
              >
                <option key="" value="">
                  Select a reason
                </option>
                <>
                  {studentFacingReasons?.map(reason => (
                    <option key={reason.id} value={reason.name}>
                      {reason.displayText}
                    </option>
                  ))}
                  {error && (
                    <option key="other" value="other">
                      Other
                    </option>
                  )}
                </>
              </CancelField>
            )}
            <CancelField
              name="notes"
              label="Please provide any additional comments (optional)"
              as="textarea"
            />
            <div>
              <Button type="submit" disabled={isSubmitting}>
                Submit
              </Button>
            </div>
          </Form>
        </>
      )}
    </Formik>
  );
};

export default CancelRequestForm;
