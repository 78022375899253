export const capitalize = str =>
  typeof str === "string" && str.length > 0 ? str[0].toUpperCase() + str.slice(1) : str;

export const snakeCase = str => {
  if (typeof str !== "string" || str.length === 0) return str;
  return str.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`);
};

export const pascalCase = str => {
  if (typeof str !== "string" || str.length === 0) return str;
  return str.replace(/(^|_|-)\w/g, s => s.replace(/[_-]/, "").toUpperCase());
};

export const camelCase = str => {
  if (typeof str !== "string" || str.length === 0) return str;
  return str
    .split(/[-_\s]+|(?=[A-Z])/)
    .map((word, index) => {
      return index === 0
        ? word.toLowerCase()
        : word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    })
    .join("");
};

export const kebabCase = str => {
  if (typeof str !== "string" || str.length === 0) return str;

  return str
    .replace(/([a-z])([A-Z])/g, "$1-$2")
    .replace(/[\s_]+/g, "-")
    .toLowerCase();
};

export const snakeCaseKeys = obj => {
  if (typeof obj !== "object" || obj === null) return obj;
  if (Array.isArray(obj)) return obj.map(snakeCaseKeys);

  return Object.entries(obj).reduce((acc, [key, val]) => {
    acc[snakeCase(key)] = snakeCaseKeys(val);
    return acc;
  }, {});
};

export const camelCaseKeys = obj => {
  if (typeof obj !== "object" || obj === null) return obj;
  if (Array.isArray(obj)) return obj.map(camelCaseKeys);

  return Object.entries(obj).reduce((acc, [key, value]) => {
    acc[camelCase(key)] = camelCaseKeys(value);
    return acc;
  }, {});
};
