import { useEffect, useMemo } from "react";
import PoweredBy from "Components/PoweredBy/index";
import { useLocation } from "react-router-dom";
import { Alert } from "react-bootstrap";
import InviteError from "./InviteError";
import { useCookies } from "react-cookie";
import SettingsConditional from "Components/SettingsConditional";
import "./index.scss";
import "../../../Layouts/Layouts.scss";
import { getCurrentCookieDomain } from "util/env";

const SignIn = ({ admin = false }) => {
  let location = useLocation();
  const [, setCookie, removeCookie] = useCookies(["inviteToken"]);

  const params = useMemo(() => new URLSearchParams(location.search), [location.search]);

  // store invite token, if one is supplied
  useEffect(() => {
    if (params.get("invite_token")) {
      setCookie("inviteToken", params.get("invite_token"));
    } else {
      removeCookie("inviteToken");
    }
    if (params.get("extensionLogin")) {
      setCookie("extensionLogin", true, {
        path: "/",
        domain: getCurrentCookieDomain(),
      });
    } else {
      removeCookie("extensionLogin", {
        path: "/",
      });
    }
  }, [setCookie, removeCookie, params]);

  return (
    <div className="center-page-content sign-in-holder">
      <div className="message-container">
        {params.get("invite_token") && (
          <Alert variant="success">
            <p>Please sign in below to create a new account.</p>
            <span>Invite token: {params.get("invite_token")}</span>
          </Alert>
        )}
        {params.get("invite_error") && <InviteError errorType={params.get("invite_error")} />}
      </div>
      <h1>rudder</h1>
      <SettingsConditional
        flag={admin ? SettingsConditional.ADMIN_SIGN_IN : SettingsConditional.CUSTOM_SIGN_IN}
      />
      <PoweredBy />
    </div>
  );
};

export default SignIn;
