import { ChatAssistanceTranscript, ChatAssistanceTranscriptSchema } from "./types";
import { ErrorHandler } from "Requests";

export default (axios: any, errorHandler: ErrorHandler) => {
  const getChatAssistanceTranscript = (id: number): Promise<ChatAssistanceTranscript[]> => {
    return axios
      .get(`/chat_assistance_transcripts/${id}`)
      .then((response: any) => ChatAssistanceTranscriptSchema.parse(response.data))
      .catch(errorHandler);
  };

  return {
    getChatAssistanceTranscript,
  };
};
