export default (axios, errorHandler) => {
  const getCancellationReasons = () => {
    return axios
      .get("/cancellation_reasons")
      .then(response => response.data)
      .catch(errorHandler);
  };

  return {
    getCancellationReasons,
  };
};
