import { useEffect, useState } from "react";
import requests from "Requests";

const useExternalChannel = podUuid => {
  const [externalChannel, setExternalChannel] = useState(null);

  useEffect(() => {
    let isMounted = true;

    const fetchData = async () => {
      if (!podUuid) return;

      try {
        const data = await requests.getExternalChannel(podUuid);
        if (isMounted) setExternalChannel(data);
      } catch (error) {
        setExternalChannel("missing");
      }
    };

    fetchData();

    return () => (isMounted = false);
  }, [podUuid]);

  return {
    externalChannel,
  };
};

export default useExternalChannel;
