import ModalBackdrop from "Components/ModalBackdrop";
import { startCase } from "lodash";
import Bowser from "bowser";
import Modal from "./Modal";
import ModalButton from "./Buttons/ModalButtons";

const ClaimContextModal = ({ assistanceRequest, cancelClaim, setShowModal }) => {
  const { requestor, request, tags } = assistanceRequest;

  const student = {
    profileLink: requestor?.profileLink,
    pronoun: requestor?.pronoun || "they",
    name: requestor?.fullName,
    location: requestor?.info?.location,
    week: requestor?.info?.week,
    program: requestor?.info?.program_name,
    discord: requestor?.socials?.discord,
  };

  const activity = {
    name: request?.resourceName,
    type: request?.resourceType,
    link: request?.resourceLink,
    uuid: request?.resourceUuid,
    reason: request?.reason,
    platform: request?.info?.platform,
  };

  let osLine = <></>;

  if (activity.platform) {
    const browser = Bowser.getParser(activity.platform);
    const os = browser.getOS();

    if (os) {
      osLine = (
        <li>
          They are using{" "}
          <strong>
            {os.name} ({os.versionName || os.version})
          </strong>
        </li>
      );
    }
  }

  const accommodationTags = tags.filter(tag => tag.settings.category === "accommodation");

  const cancel = () => {
    setShowModal(false);
    cancelClaim();
  };

  return (
    <ModalBackdrop>
      <Modal title="Assistance Request Context" closeModal={() => setShowModal(false)}>
        <p>To prepare for this assistance request, please review the following material:</p>
        <h5>Request Information</h5>
        <ul>
          <li>
            <a href={student.profileLink} target="_blank" rel="noreferrer">
              {student.name}
            </a>{" "}
            ({student.location}, <i className="fa fab fa-discord" /> {student.discord}) is in{" "}
            {student.week} of the {student.program} bootcamp
          </li>
          <li>
            {startCase(student.pronoun)} are looking for help with the{" "}
            <a href={activity.link} target="_blank" rel="noreferrer">
              {activity.name}
            </a>{" "}
            {activity.type}
          </li>
          {osLine}
          <br />
          {accommodationTags.length > 0 &&
            accommodationTags.map(tag => (
              <li key={tag.name}>
                <strong>Accommodations Note: </strong> {tag.queueNote}
              </li>
            ))}
        </ul>
        <h5>Request Description</h5>

        <p>{activity.reason}</p>
        <i>
          The student will not be notified of your presence in this assistance until you exit this
          modal and click the "Start" button.
        </i>
        <div className="step-button-container">
          <ModalButton.Back onClick={cancel} text="Requeue" data-dismiss="modal" />
          <ModalButton.Submit
            onClick={() => setShowModal(false)}
            text="I have reviewed this material and I'm ready to assist!"
            data-dismiss="modal"
          />
        </div>
      </Modal>
    </ModalBackdrop>
  );
};

export default ClaimContextModal;
