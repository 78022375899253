export default (axios, errorHandler) => {
  const createTaskRequest = payload => {
    return axios
      .post("/task_requests", payload)
      .then(response => response.data.assistanceRequest)
      .catch(errorHandler);
  };

  return {
    createTaskRequest,
  };
};
