/**
 * Defines a series of steps (functions) to be taken before the React application is booted.
 */
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

function setupSentry() {
  if (process.env.REACT_APP_SENTRY_DSN) {
    const tracesSampleRate = process.env.REACT_APP_SENTRY_TRACE_SAMPLE_RATE
      ? parseFloat(process.env.REACT_APP_SENTRY_TRACE_SAMPLE_RATE) || 0.4
      : 0.4;

    Sentry.init({
      dsn: process.env.REACT_APP_SENTRY_DSN,
      integrations: [new BrowserTracing()],
      tracesSampleRate: tracesSampleRate,
    });
  } else {
    console.warn(
      "Sentry error reporting is not set up. Set the environment variable for REACT_APP_SENTRY_DSN and rebuild the client."
    );
  }
}

export default [setupSentry];
