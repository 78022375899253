export default (axios, errorHandler) => {
  const createAssistanceRequest = payload => {
    return axios
      .post("/user_requests", payload)
      .then(response => response.data.assistanceRequest)
      .catch(errorHandler);
  };

  const cancelAssistanceRequest = (id, payload) => {
    return axios
      .patch(`/user_requests/${id}/cancel`, payload)
      .then(response => response.data.assistanceRequest)
      .catch(errorHandler);
  };

  // when a student finishes the request before the mentor does
  const finishAssistanceRequest = id => {
    return axios
      .patch(`/user_requests/${id}/finish`)
      .then(response => response.data.assistanceRequest)
      .catch(errorHandler);
  };

  const getOngoingAssistanceRequest = () => {
    return axios
      .get("/user_requests/ongoing")
      .then(response => response.data.assistanceRequest)
      .catch(errorHandler);
  };

  const appendStudentsToAR = data => {
    return axios
      .post(`/assistance_requests/${data.parent_id}/append_to_completed`, data)
      .then(response => {
        return response.data;
      })
      .catch(errorHandler);
  };

  const getActivityDetails = data => {
    return axios
      .get(`/activities`, { params: data })
      .then(response => {
        return response.data;
      })
      .catch(errorHandler);
  };

  return {
    createAssistanceRequest,
    cancelAssistanceRequest,
    finishAssistanceRequest,
    getOngoingAssistanceRequest,
    appendStudentsToAR,
    getActivityDetails,
  };
};
