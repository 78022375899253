import { useQuery } from "@tanstack/react-query";
import requests from "Requests";

const fetchCancellationReasons = async () => {
  const response = await requests.getCancellationReasons();
  return response;
};

const useCancellationReasons = () => {
  const {
    isLoading,
    error,
    data: reasons,
  } = useQuery({ queryKey: ["cancellationReasons"], queryFn: fetchCancellationReasons });

  return { isLoading, error, reasons };
};

export default useCancellationReasons;
