import { isInteger } from "lodash";
import { Alert } from "react-bootstrap";
import { Link } from "react-router-dom";

const range = (start, end) => {
  return Array(end - start + 1)
    .fill()
    .map((_, idx) => start + idx);
};

const checkPageNumber = (pageNumber, lastPage) => {
  let errorMessage = "";
  let verifiedPageNumber = pageNumber;
  if (!pageNumber) {
    verifiedPageNumber = 1;
  } else if (!isInteger(pageNumber)) {
    errorMessage = "Please enter a valid page number.";
    verifiedPageNumber = 1;
  } else if (pageNumber < 1) {
    errorMessage = "Page number cannot be less than 0.";
    verifiedPageNumber = 1;
  } else if (pageNumber > lastPage) {
    errorMessage = `The last page number is ${lastPage}.`;
    verifiedPageNumber = lastPage;
  }
  return { verifiedPageNumber, errorMessage };
};

const Pagination = ({ routeKey, pageNumber, lastPage, paginationLength = 10, ariaLabel = "" }) => {
  const { verifiedPageNumber, errorMessage } = checkPageNumber(pageNumber, lastPage);
  const startPage =
    verifiedPageNumber -
    (verifiedPageNumber % paginationLength) +
    1 -
    (verifiedPageNumber % paginationLength === 0 ? paginationLength : 0);
  const endPage = startPage + paginationLength - 1;
  const pageRange = range(startPage, Math.min(lastPage, endPage));
  return (
    <>
      {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
      <nav aria-label={ariaLabel}>
        <ul className="pagination">
          <li className={"page-item" + (startPage === 1 ? " disabled" : "")}>
            <Link
              className="page-link"
              to={`${routeKey}?page=${startPage - 1}`}
              tabIndex={startPage === 1 ? " -1" : "0"}
            >
              Previous
            </Link>
          </li>
          {pageRange.map(index => {
            return (
              <li
                key={index}
                className={"page-item" + (index === verifiedPageNumber ? " active" : "")}
              >
                <Link className="page-link" to={`${routeKey}?page=${index}`}>
                  {index}
                  {index === verifiedPageNumber && <span className="sr-only">(current)</span>}
                </Link>
              </li>
            );
          })}
          <li className={"page-item" + (endPage >= lastPage ? " disabled" : "")}>
            <Link
              className="page-link"
              to={`${routeKey}?page=${endPage + 1}`}
              tabIndex={endPage >= lastPage ? " -1" : "0"}
            >
              Next
            </Link>
          </li>
        </ul>
      </nav>
    </>
  );
};

export default Pagination;
