import { useState } from "react";
import "./FormResults.scss";

const CollapsibleTable = ({ data, initialDataCount, show }) => {
  const rows = [];
  for (let i = 0; i < (show ? data.length : Math.min(initialDataCount, data.length)); i++) {
    rows.push(
      <tr key={`form-result-${i}`}>
        <th scope="row">{data[i].key}</th>
        <td>{data[i].result.toString()}</td>
      </tr>
    );
  }
  return (
    <table className="table table-striped mb-0">
      <tbody>{rows}</tbody>
    </table>
  );
};

const FormResults = ({ formName, results = {} }) => {
  const [show, setShow] = useState(false);
  const initialDataCount = 5;
  const name = formName || "";

  const data =
    results &&
    Object.keys(results).map((key, i) => ({
      id: i,
      key,
      result: results[key],
    }));

  return (
    <>
      {name && <h4>{name}</h4>}
      <CollapsibleTable
        className="show-button"
        data={data}
        initialDataCount={initialDataCount}
        show={show}
      />
      {data.length > initialDataCount && (
        <div
          className={`show-button ${show ? "less" : "more"}`}
          onClick={e => {
            e.preventDefault();
            setShow(!show);
          }}
        >
          {show ? "show less" : "show more"}
        </div>
      )}
    </>
  );
};
export default FormResults;
