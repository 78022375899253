import { useState, useEffect } from "react";
import { useAppContext } from "Context/AppProvider";
import SettingsConditional from "./SettingsConditional";
import OAuthModal from "./OAuthModal";
import NotificationsBanner from "./NotificationsBanner";

const Announcements = () => {
  const { currentUser } = useAppContext();

  const [showOAuthModal, setShowOAuthModal] = useState(false);
  const oauthTokens = {
    zoom: currentUser.getOAuthToken("Zoom"),
  };

  useEffect(() => {
    setShowOAuthModal(oauthTokens.zoom?.status !== "valid");
  }, [oauthTokens.zoom?.status]);

  return (
    <>
      <SettingsConditional flag={SettingsConditional.NOTIFICATIONS_BANNER_MESSAGE}>
        {settings => <NotificationsBanner message={settings.ui.notificationsBannerMessage} />}
      </SettingsConditional>
      {showOAuthModal && (
        <SettingsConditional flag={SettingsConditional.ZOOM_SUPPORT}>
          {settings => (
            <OAuthModal
              appName="Zoom"
              message={settings.ui.zoomAuthorizationNote}
              authLink="/oauth/authorize?platform=zoom"
              hide={() => setShowOAuthModal(false)}
            />
          )}
        </SettingsConditional>
      )}
    </>
  );
};

export default Announcements;
