import Assistance from "Components/Assistance";
import AIAssistance from "Components/AIAssistance";
import ListGroup from "Components/ListGroup";

const InProgressList = ({ tasks }) => {
  return (
    <ListGroup icon={tasks.length} title="In Progress" className="in-progress">
      {tasks.map(task =>
        task.assistanceRequest?.request?.isAIOnly ? (
          <AIAssistance admin key={`assistance-${task.id}`} task={task} />
        ) : (
          <Assistance admin key={`assistance-${task.id}`} task={task} />
        )
      )}
    </ListGroup>
  );
};

export default InProgressList;
