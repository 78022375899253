/**
 * To be passed as arg to Array.reduce(). Removes all elements from an array that appear more than one time.
 * Ex: [2, 3, 4, 2].reduce(uniqueValuesOnly, []) => [3, 4]
 */
export const uniqueValuesOnly = (arr, el) =>
  arr.includes(el) ? arr.filter(e => e !== el) : [...arr, el];

/**
 * urlBase64ToUint8Array
 *
 * @param {string} base64String a public vapid key
 */
export function urlBase64ToUint8Array(base64String) {
  const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding).replace(/\-/g, "+").replace(/_/g, "/");

  const rawData = self.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}
