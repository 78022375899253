import { useAppContext } from "Context/AppProvider";
import FeedbackForm from "./FeedbackForm";
import ModalBackdrop from "Components/ModalBackdrop";
import StudentInfo from "Components/StudentInfo";
import RequestDetails from "Components/RequestDetails";
import "Assets/styles/scrollable-modal.scss";
import { getCurrentSource, isBrowserExtension, inIframe } from "util/env";
import { ModalTitle } from "Components/Typography/Heading";

const determineModalTitle = type => {
  switch (type) {
    case "TechInterview":
      return "Technical Interview Feedback";
    case "Evaluation":
      return "Project Evaluation Feedback";
    case "CodeReview":
      return "Code Review Feedback";
    default:
      return "Assistance Request";
  }
};
const determineRequestSubtitle = type => {
  switch (type) {
    case "TechInterview":
      return "Your technical interview:";
    case "Evaluation":
      return "Your evaluation:";
    case "CodeReview":
      return "Your evaluation:";
    default:
      return "Your request:";
  }
};

const determineFeedbackSource = externalSource => {
  if (externalSource) return externalSource;
  if (inIframe()) return "web:compass";
  return getCurrentSource();
};

const mentorOnlyRequestType = ["Evaluation", "TechInterview", "CodeReview"];

const FeedbackModal = ({ task, hide, externalSource, setCompletion }) => {
  const {
    queueChannel,
    settingsHandler: { settings },
  } = useAppContext();
  if (!task) return null;

  const provideFeedback = feedback => {
    queueChannel.provideFeedback(task, feedback);
    !externalSource && hide();
    externalSource && setCompletion(true);
  };

  const {
    assistor,
    assistanceRequest: { day, request, type, tags },
  } = task;

  const receivedRecommendation = request.recommendedPreviousArId;
  const noBotRequest = tags.some(tag => tag.name === "no-bot");

  const determineRequestType = () => {
    if (noBotRequest) return "noBotActivity";
    if (mentorOnlyRequestType.includes(type)) return type;
    if (settings.features.preRequestQuestions) return "pps";
    if (!!settings.features.llmBot && type === "UserRequest") return "llmBot";

    return "generic";
  };

  const feedbackRequestType = determineRequestType();
  const modalTitle = determineModalTitle(type);
  const requestSubtitle = determineRequestSubtitle(type);

  return (
    <ModalBackdrop>
      <div className="modal-dialog" style={{ marginTop: isBrowserExtension() ? "2rem" : "5rem" }}>
        <div className="modal-content">
          <div className="modal-header">
            <ModalTitle>{modalTitle}</ModalTitle>
            {!externalSource && (
              <button type="button" className="close" data-cy="feedback-modal-close" onClick={hide}>
                <span aria-hidden="true">&times;</span>
                <span className="sr-only">Close</span>
              </button>
            )}
          </div>
          <div className="modal-body">
            <strong>Assistor:</strong>
            <div className="ml-3">
              <StudentInfo student={assistor} details={{ type: "teacher" }} nonLearner />
            </div>
            <strong>{requestSubtitle}</strong> <br />
            <RequestDetails
              categoryName={request.categoryName}
              day={day}
              activityUrl={request.resourceLink}
              activityTitle={request.resourceName}
              reason={request.reason}
              resubmission={request.resubmission}
            />
            <FeedbackForm
              source={determineFeedbackSource(externalSource)}
              handleSubmit={provideFeedback}
              feedbackRequestType={feedbackRequestType}
              receivedRecommendation={receivedRecommendation}
            />
          </div>
        </div>
      </div>
    </ModalBackdrop>
  );
};

export default FeedbackModal;
