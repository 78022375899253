import * as Sentry from "@sentry/react";

let transactions = {};

export const startTransaction = (name, id) => {
  const t = Sentry.startTransaction({ name });
  transactions[`${name}-${id}`] = t;
};

export const finishTransaction = (name, id) => {
  const key = `${name}-${id}`;
  const transaction = transactions[key];
  if (transaction) {
    transaction.finish();
    delete transactions[key];
  }
};
