const STATUS_COLORS = {
  CAN_CREATE: "#007BFF",
  PENDING: "#FFC107",
  IN_PROGRESS: "#28A745",
};

// prefixed with _STATE to avoid conflict with the color names
const STATES = {
  CLAIMED_STATE: "claimed",
  PENDING_STATE: "pending",
  IN_PROGRESS_STATE: "in_progress",
  CLOSED_STATE: "closed",
};

const UPDATES = {
  CreateAssistanceRequest: "create_assistance_request",
  StartAssistance: "start_assistance",
  CancelAssistance: "cancel_assistance",
  Cancel: "cancel",
  FinishAssistance: "finish_assistance",
  FinishRequest: "finish_request",
};

const MINIMUM_DESCRIPTION_LENGTH = process.env.NODE_ENV === "development" ? 1 : 20;

const REQUEST_TYPES = [
  { id: 1, name: "User Request", icon: "fa-hand" },
  { id: 2, name: "Code Review", icon: "fa-code" },
  { id: 3, name: "Tech Interview", icon: "fa-clipboard-question" },
  { id: 4, name: "Project Evaluation", icon: "fa-book-open" },
  { id: 5, name: "Larry Only Request", icon: "fa-robot" },
  { id: 6, name: "Mentor Task", icon: "fa-list-check" },
];

const PROGRAMS = [
  { id: 1, name: "Web", icon: "fa-file-code" },
  { id: 2, name: "Data", icon: "fa-database" },
  { id: 3, name: "Cyber", icon: "fa-shield-halved" },
];

export { STATUS_COLORS, STATES, UPDATES, MINIMUM_DESCRIPTION_LENGTH, REQUEST_TYPES, PROGRAMS };
