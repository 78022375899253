import { useState } from "react";
import StarRating from "Components/Rating/StarRating";
import StudentInfo from "Components/StudentInfo";
import QueueItem from "Components/QueueItem";
import RequestDetails from "Components/RequestDetails";
import AIFeedbackModal from "./AIFeedbackModal";
import TimeAgoWithTooltip from "Components/TimeAgoWithTooltip";

const AIFeedback = ({ task }) => {
  const [showModal, setShowModal] = useState(false);
  const request = task.assistanceRequest;
  const student = task.assistanceRequest.requestor;
  const assistanceEndTime = task.assistanceRequest.canceledAt;
  const rating = () => {
    if (task.assistanceRequest.feedback) {
      const feedback = task.assistanceRequest.feedback;
      return <StarRating staticRating={feedback.rating} />;
    }
  };

  const larry = {
    fullName: "Larry AI",
    avatarUrl: "https://assets.lighthouselabs.ca/logos/larry.png",
    profileLink: null,
  };

  return (
    <QueueItem label="Larry AI-only" type="Feedback" disabled={false}>
      <StudentInfo
        student={larry}
        showDetails={true}
        hideTags={true}
        when={request.startAt}
        details={student.info}
        nonLearner
      />

      <div className="request-details-container blurb">
        <RequestDetails
          categoryName={request.request.categoryName}
          day={request.request.info && request.request.info.day}
          activityUrl={request.request.resourceLink}
          activityTitle={request.request.resourceName}
          reason={request.request.reason}
        />
        {assistanceEndTime && <TimeAgoWithTooltip when={assistanceEndTime} />}
        {rating()}
      </div>
      <div className="actions float-right">
        {!task.assistanceRequest.feedback && (
          <button
            key="finish"
            className="btn btn-sm btn-primary btn-main"
            onClick={() => setShowModal(true)}
            disabled={false}
          >
            Provide Feedback
          </button>
        )}
      </div>
      {showModal && <AIFeedbackModal task={task} hide={() => setShowModal(false)} />}
    </QueueItem>
  );
};

export default AIFeedback;
