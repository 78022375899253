import { useEffect, createContext, useContext, useState, useRef, useCallback } from "react";

const StepContext = createContext();

export const useStepContext = () => useContext(StepContext);

export const Step = ({ beforeNext, children }) => {
  const [id, setId] = useState(-1);
  const { step, addStep, removeStep, registerBeforeNext } = useStepContext();

  // Add this step to context on first render
  useEffect(() => {
    setId(addStep());

    // Clean-up function required
    return () => removeStep();
  }, [addStep, removeStep]);

  // Register beforeNext
  useEffect(() => {
    registerBeforeNext(id, beforeNext);
  }, [registerBeforeNext, id, beforeNext]);

  return step === id ? children : null;
};

const StepProvider = ({ children }) => {
  const countRef = useRef(0);
  const beforeRef = useRef([]);
  const [step, setStep] = useState(0);
  const [count, setCount] = useState(0);

  // useCallback required for addStep because it affects state
  // and it is called within Step's useEffect
  const addStep = useCallback(() => {
    setCount(countRef.current + 1);
    return countRef.current++;
  }, []);

  const removeStep = useCallback(() => {
    setCount(countRef.current - 1);
    countRef.current--;
  }, []);

  const registerBeforeNext = (id, beforeNext) => (beforeRef.current[id] = beforeNext);

  const next = async () => {
    const beforeNext = beforeRef.current[step];
    if (!beforeNext || !(await beforeNext())) setStep(step + 1);
  };

  const back = () => setStep(step - 1);

  return (
    <StepContext.Provider
      value={{
        addStep,
        removeStep,
        registerBeforeNext,
        step,
        count,
        back: step > 0 ? back : undefined,
        next,
      }}
    >
      {children}
    </StepContext.Provider>
  );
};

export default StepProvider;
