import classnames from "classnames";
import "./Tabs.scss";
import { startCase } from "lodash";

const Tabs = ({ tab: currentTab, tabs, onChangeTab, children }) => {
  const changeTo = tab => (tab !== currentTab ? onChangeTab(tab) : null);
  const buttons = tabs.map(tab => (
    <button
      key={tab}
      className={classnames("tab", { selected: currentTab === tab })}
      onClick={() => changeTo(tab)}
    >
      {startCase(tab)}
    </button>
  ));

  return (
    <div className="tabs-container">
      <div className="tabs">{buttons}</div>
      {children}
    </div>
  );
};

export default Tabs;
