import "./Queue.scss";

const ReviewMessage = () => {
  return (
    <div className="review-message-box">
      You have to complete and review the in-progress assistance to be assigned a new assistance
      request.
    </div>
  );
};

export default ReviewMessage;
