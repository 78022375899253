import requests from "Requests";
import { useQuery } from "@tanstack/react-query";

const FIVE_MINUTES = 1000 * 60 * 5;

const useSettings = params => {
  const { deploymentId } = params || {};
  const {
    isLoading,
    error,
    data: settings,
  } = useQuery({
    queryKey: ["settings", deploymentId],
    queryFn: () => requests.getSettings({ deploymentId }),
    staleTime: FIVE_MINUTES,
  });

  return {
    settings: settings || {},
    isLoading,
    error,
  };
};

export default useSettings;
