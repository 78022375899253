import { isBrowserExtension } from "util/env";
import { NOOP } from "util/functions";

const GithubLoginButton = ({ onClickHandler, buttonText, type = "button" }) => {
  const onClick = onClickHandler ? onClickHandler : NOOP;
  return (
    <button className="login-button" onClick={onClick} type={type}>
      {buttonText || (
        <>
          <i className="fa fab fa-github" />
          &nbsp; Login with Github
        </>
      )}
    </button>
  );
};

const GithubButton = ({ message, buttonText }) => {
  const onInitLogin = () =>
    chrome.tabs.create({
      url: `${process.env.REACT_APP_SERVER}/sign-in?extensionLogin=true`,
    });

  return (
    <>
      {isBrowserExtension() ? (
        <GithubLoginButton onClickHandler={onInitLogin} />
      ) : (
        <form action="/auth/auth0" method="post">
          <GithubLoginButton type="submit" buttonText={buttonText} />
        </form>
      )}

      <small>{message}</small>
    </>
  );
};

export default GithubButton;
