import ModalBackdrop from "Components/ModalBackdrop";
import "./Components.scss";
import { ModalTitle } from "./Typography/Heading";

const NoMentorModal = ({ hide }) => {
  const closeModal = () => hide();

  return (
    <ModalBackdrop blur center>
      <div className="modal-dialog" style={{ maxWidth: 600 }}>
        <div className="modal-content">
          <div className="modal-header">
            <ModalTitle>No Mentors Available</ModalTitle>

            <button className="close" onClick={closeModal} type="button" data-dismiss="modal">
              <span aria-hidden="true">&times;</span>
              <span className="sr-only">Close</span>
            </button>
          </div>
          <div className="no-mentor-message">
            There are currently no mentors on duty to handle your Assistance Request. Please try
            again later.
          </div>
        </div>
      </div>
    </ModalBackdrop>
  );
};

export default NoMentorModal;
