import { useState } from "react";
import classNames from "classnames";
import UserDetail from "./UserDetail";

const User = ({ user, onAdd, isAlreadyTagged }) => {
  const [isAdding, setIsAdding] = useState(false);

  const addClicked = () => {
    setIsAdding(true);
    onAdd(user, () => {
      setIsAdding(false);
    });
  };

  const addDisabled = isAlreadyTagged || isAdding ? "disabled" : null;

  return (
    <li
      className={classNames("list-group-item", "d-flex", {
        disabled: addDisabled,
      })}
    >
      <button className="btn btn-sm btn-secondary mr-2" onClick={addClicked} disabled={addDisabled}>
        &lt;
      </button>
      <UserDetail user={user} />
    </li>
  );
};

export default User;
