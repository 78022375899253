import { Table } from "react-bootstrap";
import { DateTime } from "luxon";
import SocialIcons from "Components/SocialIcons";
import "./Info.scss";

const Info = ({ user }) => {
  const userInfoRow = (label, data) => (
    <tr>
      <td className="label">{label}</td>
      <td>{data}</td>
    </tr>
  );
  return user ? (
    <div className="info-table">
      <Table bordered>
        <tbody>{userInfoRow("Name", user.fullName)}</tbody>
        <tbody>{userInfoRow("Timezone", user.queueTimezone)}</tbody>
        <tbody>
          {userInfoRow("Last Requested", DateTime.fromISO(user.lastRequestedAt).toFormat("ff"))}
        </tbody>
        <tbody>
          {userInfoRow("Last Assisted", DateTime.fromISO(user.lastAssistedAt).toFormat("ff"))}
        </tbody>
        <tbody>{userInfoRow("Contact", <SocialIcons user={user} />)}</tbody>
      </Table>
    </div>
  ) : (
    <></>
  );
};

export default Info;
