import { useState } from "react";
import classNames from "classnames";
import "./EmoteRating.scss";

// library of emotes, containing FA icons, class names, and quality/difficulty descriptors
const emotes = [
  { name: "", quality: "", difficulty: "", icon: "" },
  {
    name: "angry",
    quality: "terrible",
    difficulty: "very difficult",
    icon: "fa-angry",
  },
  { name: "frown", quality: "bad", difficulty: "difficult", icon: "fa-frown" },
  { name: "meh", quality: "okay", difficulty: "so-so", icon: "fa-meh" },
  { name: "smile", quality: "good", difficulty: "easy", icon: "fa-smile" },
  {
    name: "beam",
    quality: "great",
    difficulty: "very easy",
    icon: "fa-grin-beam",
  },
];

const defaultSelection = (staticValue, initialValue, max = 5, inverted = false) => {
  const baseValue = staticValue || initialValue || 0;

  if (!baseValue || !inverted) {
    return baseValue;
  } else {
    return max + 1 - baseValue;
  }
};

const EmoteRating = ({
  initialRating,
  staticRating,
  returnRating,
  difficulty,
  invertValues,
  disabled,
  hideStatus,
}) => {
  const [rating, setRating] = useState({
    max: 5,
    hover: 0,
    selected: defaultSelection(staticRating, initialRating, 5, invertValues),
  });

  const setSelected = selected => {
    setRating(state => ({ ...state, selected }));
    returnRating(invertValues ? rating.max + 1 - selected : selected);
  };

  const setHover = hover => {
    setRating(state => ({ ...state, hover }));
  };

  const emoteSelect = () => {
    const selects = [];
    for (let i = 1; i <= rating.max; i++) {
      selects.push(
        <button
          type="button"
          key={i}
          className={classNames("emote", {
            [`${emotes[i].name}`]: i === rating.selected || i === rating.hover,
            disabled: disabled,
          })}
          onMouseEnter={() => disabled || staticRating || setHover(i)}
          onMouseLeave={() => disabled || staticRating || setHover()}
          onClick={() => disabled || staticRating || setSelected(i)}
        >
          <i aria-hidden="true" className={`fa-solid ${emotes[i].icon}`} />
          <span className="sr-only">{difficulty ? emotes[i].difficulty : emotes[i].quality}</span>
        </button>
      );
    }
    return selects;
  };

  const description = difficulty
    ? emotes[rating.hover || rating.selected]?.difficulty
    : emotes[rating.hover || rating.selected]?.quality;

  return (
    <>
      <div className="emote-rating">
        <div className="emotes">{emoteSelect()}</div>
      </div>
      {!disabled && !hideStatus && (
        <div className="emote-rating-status">
          {rating.hover || rating.selected
            ? "It was " + description + "."
            : "Please select an option..."}
        </div>
      )}
    </>
  );
};

export default EmoteRating;
