import EmoteRating from "Components/Rating/EmoteRating";

const EmoteRatingInput = ({ field, ...props }) => {
  const { name, value, onChange } = field;

  const handleChange = value => onChange({ target: { name, value } });

  return <EmoteRating initialRating={value} returnRating={handleChange} {...props} />;
};

export default EmoteRatingInput;
