export default (axios, errorHandler) => {
  const getSettings = params => {
    const { deploymentId } = params || {};

    return axios
      .get("/settings", { params: { deploymentId } })
      .then(response => response.data || {})
      .catch(errorHandler);
  };

  return {
    getSettings,
  };
};
