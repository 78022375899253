import { useParams } from "react-router-dom";
import ModalBackdrop from "./ModalBackdrop";
import { ModalTitle } from "./Typography/Heading";

const OAuthUnauthorizedModal = () => {
  const { platform, error } = useParams();
  const errorMessage =
    error === "access_denied"
      ? "This authorization could not be completed because the resource owner or authorization server denied the request."
      : "We were unable to process this authorization.";
  return (
    <ModalBackdrop id={`oauth-success-${error.toLowerCase()}`} center blur>
      <div className="modal-dialog dialog-wide">
        <div className="modal-content">
          <div className="modal-header">
            <ModalTitle>Authorization Cancelled</ModalTitle>
          </div>
          <div className="modal-body">
            <p> {errorMessage} </p>
            <p> You can now close this tab. </p>
            <p>
              {" "}
              <a
                href={`${process.env.REACT_APP_SERVER}/oauth/authorize?platform=${platform}`}
                target="_blank"
                rel="noreferrer"
              >
                Or, click here if you would like to retry authorization.
              </a>
            </p>
          </div>
        </div>
      </div>
    </ModalBackdrop>
  );
};

export default OAuthUnauthorizedModal;
