import { Route, Routes } from "react-router-dom";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { CLIENT_TYPES } from "constants/app";
import { ProtectedRoute } from "Components/Routers";
import SentryTest from "Components/SentryTest";
import ExtensionSignInSuccess from "Components/ExtensionSignInSuccess";
import Launch from "Screens/lti/Launch";
import RequestAssistance from "Screens/lti/RequestAssistance";
import ChatTranscript from "Screens/transcripts/ChatTranscript";
import HelpDashboard from "Screens/lti/HelpDashboard";
import Requirements from "Screens/requirements";
import AssistanceModalProvider from "Context/AssistanceModalProvider";
import PaddedContent from "Layouts/PaddedContent";
import "Assets/styles/index.css";
import "Assets/styles/queue.scss";
import "Assets/styles/card.scss";
import ErrorHandler from "Components/ErrorHandler";

// context provider
import AppProvider from "Context/AppProvider";

// layout
import MainLayout from "Layouts/MainLayout";

// Error handling
import * as Sentry from "@sentry/react";

// Alerting
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// routes
import Screens from "Screens";

function App() {
  return (
    <div data-testid="app" className="App">
      <ToastContainer />
      <AppProvider clientType={CLIENT_TYPES.web}>
        <ErrorHandler />
        <AssistanceModalProvider>
          <Sentry.ErrorBoundary showDialog fallback={() => <h1>Something went wrong.</h1>}>
            <Requirements>
              <Routes>
                <Route key="embeded" path="/learning_platforms/launch" element={<Launch />} />
                <Route
                  key="help-dash"
                  path="/help/dashboard"
                  render={
                    <MainLayout>
                      <PaddedContent>
                        <HelpDashboard />
                      </PaddedContent>
                    </MainLayout>
                  }
                />
                <Route
                  key="request-assistance"
                  path="/request-assistance"
                  element={<ProtectedRoute component={RequestAssistance} />}
                />
                <Route
                  key="show-chat-transcript"
                  path="/chat-transcript/:id"
                  element={<ProtectedRoute component={ChatTranscript} />}
                />
                <Route key="sentry-test" path="/sentry_test" element={<SentryTest />} />
                <Route
                  key="extension-sign-in-success"
                  path="/extension-sign-in-success"
                  element={
                    <MainLayout>
                      <PaddedContent>
                        <ExtensionSignInSuccess />
                      </PaddedContent>
                    </MainLayout>
                  }
                />
                <Route
                  key="main"
                  path="*"
                  element={
                    <MainLayout>
                      <Screens />
                    </MainLayout>
                  }
                />
              </Routes>
            </Requirements>
          </Sentry.ErrorBoundary>
        </AssistanceModalProvider>
      </AppProvider>
      {/* By default, React Query Devtools are only included in bundles when process.env.NODE_ENV === 'development' */}
      <ReactQueryDevtools initialIsOpen={false} />
    </div>
  );
}

export default App;
