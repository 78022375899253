export default (axios, errorHandler) => {
  const requestConfig = {
    baseURL: process.env.REACT_APP_SERVER + "/work_context",
  };

  // get work context for logged-in user
  const getLearnerWorkContext = () => {
    return axios
      .get("/learner_work_context", requestConfig)
      .then(response => response.data)
      .catch(errorHandler);
  };

  return {
    getLearnerWorkContext,
  };
};
