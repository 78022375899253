import RequestModal from "Components/RequestModal";
import { useAppContext } from "Context/AppProvider";
import { useNavigate } from "react-router-dom";

const RequestAssistance = () => {
  const { queueChannel } = useAppContext();
  const navigate = useNavigate();

  const hideAssistanceModal = () => {
    window.close();
    navigate("/");
  };
  return <RequestModal queueChannel={queueChannel} show={true} hide={hideAssistanceModal} />;
};

export default RequestAssistance;
