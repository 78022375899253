import { useEffect, useCallback } from "react";

const useDebounceEffect = (effect, dependencies, delay = 250) => {
  const callback = useCallback(effect, [...dependencies, effect]);

  useEffect(() => {
    const timeout = setTimeout(callback, delay);
    return () => clearTimeout(timeout);
  }, [...dependencies, delay]);
};

export default useDebounceEffect;
