import classNames from "classnames";
import { Dropdown } from "react-bootstrap";
import Pronoun from "Components/Pronoun";
import "./MentorStatusList.scss";

const MentorStatusList = ({ teachers }) => {
  return (
    <div className="teacher-holder">
      <div className="teacher-list">
        {teachers.map(teacher => {
          return (
            <Dropdown.Item
              as="li"
              className="teacher-mentor-item d-flex align-items-center"
              key={`tagbadge-${teacher.uid}`}
            >
              <img
                alt={teacher.fullName}
                src={teacher.avatarUrl}
                className={classNames("option", {
                  busy: teacher.busy,
                })}
              />
              <div className="name">
                <span className="mr-1">{teacher.fullName}</span>
                <Pronoun pronoun={teacher.pronoun} />
              </div>
            </Dropdown.Item>
          );
        })}
      </div>
    </div>
  );
};

export default MentorStatusList;
