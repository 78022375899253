export default (axios, errorHandler) => {
  const getTasks = () => {
    return axios
      .get(`/queue`)
      .then(response => {
        const tasks = response.data.tasks || [];
        const pendingArs = response.data.pendingArs || [];
        return { tasks, pendingArs };
      })
      .catch(errorHandler);
  };

  const getQueueStatistics = userUid => {
    return axios
      .get(`/queue/${userUid}`)
      .then(response => {
        return response.data.tasks || [];
      })
      .catch(errorHandler);
  };

  const getLastTaskWithPendingFeedback = () => {
    return axios
      .get(`/queue/task_with_pending_feedback`)
      .then(response => {
        const task = response.data.task || null;
        return { task };
      })
      .catch(errorHandler);
  };

  const skipRequest = (queueTaskId, reason, notes) => {
    return axios
      .patch(`/queue/${queueTaskId}/skip`, { reason, notes })
      .then(response => response.message)
      .catch(errorHandler);
  };

  return {
    getTasks,
    getQueueStatistics,
    getLastTaskWithPendingFeedback,
    skipRequest,
  };
};
