import ListGroup from "Components/ListGroup";
import PendingAssistanceRequest from "./PendingAssistanceRequest";

const OpenSecondaryTasksList = ({ tasks }) => {
  return (
    <ListGroup
      icon={tasks.length}
      title="Assigned Secondary Tasks"
      className="assigned-task-requests"
    >
      {tasks.map(task => (
        <PendingAssistanceRequest key={task.id} task={task} />
      ))}
    </ListGroup>
  );
};

export default OpenSecondaryTasksList;
