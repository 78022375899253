import { useState } from "react";
import { Formik, Form, ErrorMessage } from "formik";
import { Alert, Button } from "react-bootstrap";
import InputField from "Components/Form/InputField";
import { EMAIL_REGEX, EMAIL_SEPARATORS_REGEX, END_OF_STRING_DOT } from "constants/app";
import { startCase } from "lodash";

const InviteField = props => <InputField prefix="invite" {...props} />;

const initialFormValues = isBulk => {
  if (isBulk) {
    return {
      email: "",
      user_type: 0,
      tags: "",
      deployment_id: 0,
      timezone: "",
    };
  }

  return {
    email: "",
    first_name: "",
    last_name: "",
    user_type: 0,
    tags: "",
    deployment_id: 0,
    timezone: "",
  };
};

// simple validation, just checks for any@any.any
const validateEmail = address => EMAIL_REGEX.test(address);

const InviteForm = ({ deployments, tags, userTypes, onSubmit, onValidate, isBulk = false }) => {
  const [tagIds, setTagIds] = useState([]);

  const selectTag = id => setTagIds([...tagIds, id]);

  const removeTag = id => setTagIds(tagIds.filter(tagId => tagId !== id));

  const deploymentOptions = deployments.map((deploy, index) => (
    <option key={index} value={deploy.id}>
      {deploy.name}
    </option>
  ));

  const userTypeOptions = userTypes.map((userType, index) => (
    <option key={index} value={userType}>
      {userType}
    </option>
  ));

  const categorizedTags = {};

  tags.forEach(tag => {
    const category = tag.settings?.category || "Uncategorized";

    if (!categorizedTags[category]) categorizedTags[category] = [];
    categorizedTags[category].push(tag);
  });

  // not using ToggleButton here because button state is based on selected tag array rather than promise resolution
  const tagsByCategory = Object.keys(categorizedTags).map(category => (
    <div key={category}>
      <small>{startCase(category)}</small> <br />
      {categorizedTags[category].map(tag => {
        const active = tagIds.includes(tag.id);

        return (
          <Button
            key={tag.id}
            size="sm"
            className="mr-1 mb-1"
            variant={active ? "primary" : "outline-secondary"}
            onClick={active ? () => removeTag(tag.id) : () => selectTag(tag.id)}
          >
            {tag.name}
          </Button>
        );
      })}
    </div>
  ));

  // custom validation is necessary because tags do not use a traditional form input
  const validateForm = values => {
    const errors = {};
    console.log("###VALIDATE", values);

    if (values.first_name?.length < 1) errors.first_name = "Required";

    if (values.last_name?.length < 1) errors.last_name = "Required";

    if (values.email?.length < 1) {
      errors.email = "Required";
    } else if (!isBulk && !validateEmail(values.email)) {
      errors.email = "Invalid email address";
    }

    if (!values.user_type) {
      errors.user_type = "No user type selected";
    } else if (values.user_type === "Learner" && values.deployment_id < 1) {
      errors.deployment_id = "Required";
    }

    if (tagIds?.length < 1) errors.tags = "No tags selected";

    onValidate && onValidate();
    return errors;
  };

  const handleSubmit = (values, { setSubmitting, resetForm }) => {
    // assemble form data into shape invite controller expects
    const { email: emailField } = values;

    // the filter is to remove empty strings
    // e.g. "example@mail.com," would result in ['example@mail.com', '']
    let email = emailField
      .split(EMAIL_SEPARATORS_REGEX)
      .filter(e => e)
      .map(e => e.replace(END_OF_STRING_DOT, ""));

    // if not bulk convert ['email'] to string
    if (!isBulk) email = email.toString();

    const inviteData = {
      invite: { ...values, email },
      tag: {
        ids: tagIds,
      },
    };

    onSubmit(inviteData)
      .then(() => {
        // clear form on success
        resetForm();
        setTagIds([]);
      })
      .finally(setSubmitting(false));
  };

  const emailLabel = isBulk ? "Email Addresses" : "Email Address";
  const emailPlaceholder = isBulk ? "insert a list of emails" : "email@example.com";

  return (
    <Formik
      initialValues={initialFormValues(isBulk)}
      validate={validateForm}
      onSubmit={handleSubmit}
      validateOnChange={false}
      validateOnBlur={false}
    >
      {({ errors, values }) =>
        console.log("###ERRORS", errors) || (
          <Form>
            <Alert variant="danger" hidden={Object.keys(errors).length < 1}>
              {Object.keys(errors).map((errorKey, index) => (
                <li key={index}>{`${errorKey}: ${errors[errorKey]}`}</li>
              ))}
            </Alert>

            {!isBulk && (
              <>
                <InviteField name="first_name" label="First Name" />
                <InviteField name="last_name" label="Last Name" />
              </>
            )}

            {isBulk ? (
              <InviteField
                name="email"
                label={emailLabel}
                placeholder={"insert the list of emails"}
                as="textarea"
              ></InviteField>
            ) : (
              <InviteField name="email" label={emailLabel} placeholder={emailPlaceholder} />
            )}

            <InviteField name="user_type" label="User Type" as="select">
              <option disabled value={0}>
                Pick a user type
              </option>
              {userTypeOptions}
            </InviteField>
            {values.user_type === "Learner" && (
              <>
                <InviteField name="deployment_id" label="Deployment" as="select">
                  <option disabled value={0}>
                    Pick a deployment
                  </option>
                  {deploymentOptions}
                </InviteField>
                {/* TODO: replace this with a more robust time zone select when we want international support */}
                <InviteField name="timezone" label="Timezone" as="select">
                  <option disabled value={""}>
                    Pick a timezone
                  </option>
                  <option value="America/Halifax">America/Halifax</option>
                  <option value="America/Toronto">America/Toronto</option>
                  <option value="America/Edmonton">America/Edmonton</option>
                  <option value="America/Vancouver">America/Vancouver</option>
                </InviteField>
              </>
            )}
            <div className="form-group">
              <label htmlFor="invite-input-tags">Tags</label>
              <div id="invite-input-tags">{tagsByCategory}</div>
              <ErrorMessage name="tags" component="span" className="text-danger" />
            </div>
            <Button
              type="submit"
              disabled={false}
              variant="primary"
              onClick={() => window.scrollTo(0, 0)}
            >
              Submit
            </Button>
          </Form>
        )
      }
    </Formik>
  );
};

export default InviteForm;
