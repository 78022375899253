import teachers from "./teachers";
import queue from "./queue";
import users from "./users";
import students from "./students";
import deployments from "./deployments";
import auth from "./auth";
import categories from "./categories";
import cancellationReasons from "./cancellationReasons";
import flags from "./flags";
import tags from "./tags";
import invites from "./invites";
import locations from "./locations";
import settings from "./settings";
import queueWaitTime from "./queueWaitTime";
import channels from "./externalChannels";
import recommender from "./recommender";
import userARs from "./userRequests";
import feedbacks from "./feedbacks";
import webPush from "./webPush";
import workContext from "./workContext";
import queueSchedules from "./queueSchedules";
import assistances from "./assistances";
import transcripts from "./transcripts";
import tasks from "./taskRequests";
import fetchios from "util/fetchios";

import { isBrowserExtension } from "util/env";
import { storageGet, storageSet } from "BrowserExtension/utils/storage";

import { ZodError } from "zod";

export type ErrorHandler = (err: any) => void;

const errorHandler: ErrorHandler = (err: any): void => {
  if (err.response?.status === 401) {
    // destroy cookies and localStorage
    if (!isBrowserExtension()) {
      localStorage.clear();
      storageSet({ authStatus: "dropped" });
      authRequests.signOut().then(() => {
        // Reload - this helps solve strange login conditions where
        // cookies/localStorage/session data is mismatched with server
        window.location.reload();
      });
    }
  }
  if (err instanceof ZodError) {
    throw err.issues;
  }
  throw err;
};

const teacherRequests = teachers(fetchios, errorHandler);
const queueRequests = queue(fetchios, errorHandler);
const userRequests = users(fetchios, errorHandler);
const studentRequests = students(fetchios, errorHandler);
const deploymentRequests = deployments(fetchios, errorHandler);
const categoryRequests = categories(fetchios, errorHandler);
const cancellationReasonsRequests = cancellationReasons(fetchios, errorHandler);
const flagRequests = flags(fetchios, errorHandler);
const authRequests = auth(fetchios, errorHandler);
const tagRequests = tags(fetchios, errorHandler);
const inviteRequests = invites(fetchios, errorHandler);
const locationRequests = locations(fetchios, errorHandler);
const settingRequests = settings(fetchios, errorHandler);
const queueWaitTimeRequests = queueWaitTime(fetchios, errorHandler);
const recommenderRequests = recommender(fetchios, errorHandler);
const userARRequests = userARs(fetchios, errorHandler);
const feedbackRequests = feedbacks(fetchios, errorHandler);
const webPushRequests = webPush(fetchios, errorHandler);
const workContextRequests = workContext(fetchios, errorHandler);
const queueScheduleRequests = queueSchedules(fetchios, errorHandler);
const assistanceRequests = assistances(fetchios, errorHandler);
const transcriptRequests = transcripts(fetchios, errorHandler);
const taskRequests = tasks(fetchios, errorHandler);
const externalChannelRequests = channels(fetchios, errorHandler);

export default {
  ...teacherRequests,
  ...queueRequests,
  ...userRequests,
  ...studentRequests,
  ...deploymentRequests,
  ...categoryRequests,
  ...cancellationReasonsRequests,
  ...flagRequests,
  ...authRequests,
  ...tagRequests,
  ...inviteRequests,
  ...locationRequests,
  ...settingRequests,
  ...queueWaitTimeRequests,
  ...recommenderRequests,
  ...userARRequests,
  ...feedbackRequests,
  ...webPushRequests,
  ...workContextRequests,
  ...queueScheduleRequests,
  ...assistanceRequests,
  ...transcriptRequests,
  ...taskRequests,
  ...externalChannelRequests,
};
