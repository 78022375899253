import ReactMarkdown from "react-markdown";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { vscDarkPlus } from "react-syntax-highlighter/dist/esm/styles/prism";

const formatTimestamp = timestamp => {
  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  };
  return new Date(timestamp).toLocaleString(undefined, options);
};

const parseMessageContent = text =>
  text.replace(/\[Read the full conversation\]/g, "").replace(/(https?:\/\/[^\s]+)/g, (_, url) => {
    const displayText = url.includes("chat-transcript")
      ? "Read the full conversation"
      : url.replace(/^(https?:\/\/)(www\.)?/, "");
    return `[${displayText}](${url})`;
  });

const determineMessageClassName = role => {
  switch (role) {
    case "rudderbot":
      return "rudderbot-message";
    case "recommended-ar":
      return "reco-message";
    default:
      return "chat-message";
  }
};

const ChatTranscriptMessage = ({ message, sameSender }) => {
  if (message.content.includes("I'm all beak and ears!")) return null;

  return (
    <div>
      {!sameSender && (
        <div className={`chat-author-${message.role || "rudderbot"}`}>
          {message.author}{" "}
          <small className="chat-timestamp">{formatTimestamp(message.timestamp)}</small>
        </div>
      )}
      <div className={determineMessageClassName(message.role)}>
        <ReactMarkdown
          components={{
            code({ inline, className, children, ...props }) {
              const match = /language-(\w+)/.exec(className || "");
              if (inline)
                return (
                  <code {...props} className={className}>
                    {children}
                  </code>
                );
              return match ? (
                <SyntaxHighlighter {...props} style={vscDarkPlus} language={match[1]} PreTag="div">
                  {String(children).replace(/\n$/, "")}
                </SyntaxHighlighter>
              ) : (
                <div className="chat-code-block">
                  <code {...props} className={className}>
                    {children}
                  </code>
                </div>
              );
            },
          }}
        >
          {parseMessageContent(message.content)}
        </ReactMarkdown>
        {message.imageUrls.length > 0 &&
          message.imageUrls.map((url, i) => {
            const isVideo = /\.(mp4|mov|webm)$/i.test(url);
            let width = 0;

            if (!isVideo) {
              const img = new Image();
              img.src = url;
              width = img.width > 500 ? "500px" : img.width;
            }

            return isVideo ? (
              <video
                key={`message-video-${i}`}
                aria-label="Discord user added video"
                src={url}
                style={{ width: "50%" }}
                controls
              />
            ) : (
              <img key={`message-img-${i}`} src={url} style={{ width: width }} />
            );
          })}
      </div>
    </div>
  );
};

export default ChatTranscriptMessage;
