import { OverlayTrigger, Tooltip } from "react-bootstrap";
import "./OptionButtonInput.scss";

const OptionButtonInput = ({ field, options }) => {
  const { name, value, onChange } = field;

  const handleChange = (e, value) => {
    e.preventDefault();
    onChange({ target: { name, value } });
  };

  const optionButtons = options.map(option => {
    const button = (
      <button
        key={option.id}
        name={option.name}
        className={`option-button btn ${value === option.id ? "btn-info" : "btn-outline-info"}`}
        onClick={e => handleChange(e, option.id)}
      >
        {option.name}
      </button>
    );
    return option.detailText ? (
      <OverlayTrigger
        key={`overlay-${option.id}`}
        placement="bottom"
        overlay={<Tooltip>{option.detailText}</Tooltip>}
      >
        {button}
      </OverlayTrigger>
    ) : (
      button
    );
  });

  return <div className="option-buttons">{optionButtons}</div>;
};

export default OptionButtonInput;
