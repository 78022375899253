import { getCurrentCookieDomain } from "util/env";
import { resetStorage, storageSet } from "./storage";

// Read and store Rudder user/session cookie
// NOTE: delete is required because the set() doesn't accept those properties
const storeCookies = cookies => {
  cookies.forEach(({ ...cookie }) => {
    cookie.url = process.env.REACT_APP_SERVER;
    chrome.cookies.set(cookie, result => {
      console.log("###cookie-stored", result);
    });
  });
  return storageSet({ authStatus: "logged-in" });
};

const readCookies = () => {
  return chrome.cookies.getAll({ domain: getCurrentCookieDomain() });
};

// chrome.cookies returns a promise but this is a for loop
// so I cant return the promise there
const deleteCookies = cookies => {
  return Promise.all(
    cookies.map(({ name, storeId, url = process.env.REACT_APP_SERVER }) =>
      chrome.cookies.remove({ name, storeId, url })
    )
  );
};

const clearExtensionCookiesAndResetStorage = () => {
  return readCookies()
    .then(cookies => deleteCookies(cookies))
    .then(() => resetStorage());
};

export { readCookies, storeCookies, deleteCookies, clearExtensionCookiesAndResetStorage };
