import { useAppContext } from "Context/AppProvider";
import AIFeedbackForm from "./AIFeedbackForm";
import ModalBackdrop from "Components/ModalBackdrop";
import StudentInfo from "Components/StudentInfo";
import { Badge } from "react-bootstrap";
import RequestQuote from "Components/RequestQuote";
import "Assets/styles/scrollable-modal.scss";
import { ModalTitle } from "Components/Typography/Heading";

const AIFeedbackModal = ({ task, hide, externalSource, setCompletion }) => {
  const { queueChannel } = useAppContext();
  if (!task) return null;

  const provideFeedback = feedback => {
    queueChannel.provideFeedback(task, feedback);
    !externalSource && hide();
    externalSource && setCompletion(true);
  };

  const {
    assistanceRequest: {
      request: { reason: requestReason, categoryName: requestCategory },
    },
  } = task;

  const larry = {
    fullName: "Larry AI",
    avatarUrl: "https://assets.lighthouselabs.ca/logos/larry.png",
    profileLink: null,
  };

  return (
    <ModalBackdrop>
      <div className="modal-dialog" style={{ marginTop: "5rem" }}>
        <div className="modal-content">
          <div className="modal-header">
            <ModalTitle>Larry AI Assistance Feedback</ModalTitle>
            {!externalSource && (
              <button type="button" className="close" data-cy="feedback-modal-close" onClick={hide}>
                <span aria-hidden="true">&times;</span>
                <span className="sr-only">Close</span>
              </button>
            )}
          </div>
          <div className="modal-body">
            <strong>Assistor:</strong>
            <div className="ml-3">
              <StudentInfo student={larry} details={{ type: "teacher" }} nonLearner />
            </div>
            <strong>Your request:</strong>
            <p className="ml-3">
              <Badge bg="info" text="light">
                {requestCategory}
              </Badge>
              &nbsp;
              <RequestQuote quote={requestReason} length={120} inline />
            </p>
            <AIFeedbackForm handleSubmit={provideFeedback} />
          </div>
        </div>
      </div>
    </ModalBackdrop>
  );
};

export default AIFeedbackModal;
