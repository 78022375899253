import { useState, useEffect } from "react";
import requests from "Requests";

const AR_RESULT_LIMIT = 10;
const AR_TYPE = "UserRequest";
const PLATFORM = "DiscordForum";

const useSuggestedAssistanceRequests = (resourceUuid, pods) => {
  const [ongoingRequests, setOngoingRequests] = useState([]);
  const [completedRequests, setCompletedRequests] = useState([]);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchSuggestedAssistanceRequests = async () => {
      setIsLoading(true);

      try {
        const podUuid = pods[0]?.uuid;

        const params = {
          arType: AR_TYPE,
          resourceUuid,
          podUuid,
          limit: AR_RESULT_LIMIT,
          platform: PLATFORM,
        };

        const suggestedArs = await requests.getSuggestedAssistanceRequests(params);

        setOngoingRequests(
          suggestedArs.filter(ar => ar.state === "pending" || ar.state === "in_progress")
        );

        setCompletedRequests(
          suggestedArs.filter(
            ar =>
              (ar.state === "closed" && ar.canceledAt === null) ||
              ar.cancellationReason === "solved_by_ai"
          )
        );
      } catch (e) {
        setError(e.response?.data);
      } finally {
        setIsLoading(false);
      }
    };

    fetchSuggestedAssistanceRequests();
  }, [resourceUuid, pods]);

  return { ongoingRequests, completedRequests, error, isLoading };
};

export default useSuggestedAssistanceRequests;
