import { useAppContext } from "Context/AppProvider";
import { Formik, Form } from "formik";
import { Alert } from "react-bootstrap";
import * as Yup from "yup";
import InputField from "Components/Form/InputField";
import ModalButton from "Components/Buttons/ModalButtons";

const ProfileField = ({ name, ...props }) => {
  const {
    settingsHandler: {
      settings: { ui },
    },
  } = useAppContext();
  const hiddenFields = ui?.hiddenUserProfileFields || [];
  return hiddenFields.includes(name) ? null : (
    <InputField prefix="profile" name={name} {...props} />
  );
};

const getFormValues = userDetails => {
  return {
    full_name: userDetails.fullName || "",
    first_name: userDetails.firstName || "",
    last_name: userDetails.lastName || "",
    pronoun: userDetails.pronoun || "",
    email: userDetails.email || "",
    phone_number: userDetails.phoneNumber || "",
    twitter: userDetails.twitter || "",
    skype: userDetails.skype || "",
    slack: userDetails.slack || "",
    github_username: userDetails.githubUsername || "",
    quirky_fact: userDetails.quirkyFact || "",
    specialties: userDetails.specialties || "",
    location_id: userDetails.locationId || "",
    bio: userDetails.bio || "",
    clocktower_userid: userDetails.clocktowerUserid || "",
    company_name: userDetails.companyName || "",
    company_url: userDetails.companyUrl || "",
  };
};

const ProfileForm = ({
  userDetails,
  locations,
  onSubmit,
  onValidate,
  isLearner,
  requiredFields,
}) => {
  const commonSchema = {
    email: Yup.string().email("Invalid email address").required("Required"),
    github_username: requiredFields.githubUsername
      ? Yup.string().required("Required")
      : Yup.string(),
  };

  const accountSchema = isLearner
    ? {
        full_name: Yup.string().required("Required"),
      }
    : {
        first_name: Yup.string().required("Required"),
        last_name: Yup.string().required("Required"),
      };

  const profileSchema = Yup.object().shape({
    ...commonSchema,
    ...accountSchema,
  });

  return (
    <div>
      <Formik
        initialValues={getFormValues(userDetails)}
        validationSchema={profileSchema}
        validate={onValidate}
        onSubmit={(values, { setSubmitting }) => {
          onSubmit(values).finally(() => setSubmitting(false));
        }}
        enableReinitialize
      >
        {({ isSubmitting, errors }) => (
          <Form>
            <Alert variant="danger" hidden={Object.keys(errors).length < 1}>
              {Object.keys(errors).map((errorKey, index) => (
                <li key={index}>{`${errorKey}: ${errors[errorKey]}`}</li>
              ))}
            </Alert>
            {isLearner ? (
              <ProfileField name="full_name" label="Full Name" required />
            ) : (
              <>
                <ProfileField name="first_name" label="First Name" required />
                <ProfileField name="last_name" label="Last Name" required />
              </>
            )}
            <ProfileField name="pronoun" label="Pronoun" as="select">
              <option></option>
              <option value="They">They</option>
              <option value="He">He</option>
              <option value="She">She</option>
              <option value="Ask me">Ask me</option>
            </ProfileField>
            <ProfileField name="email" label="Email" required />
            {!isLearner && (
              <>
                <ProfileField name="phone_number" label="Phone Number" />
                <ProfileField name="twitter" label="Twitter" />
                <ProfileField name="skype" label="Skype" />
                <ProfileField name="slack" label="Slack" />
              </>
            )}
            <ProfileField name="github_username" label="Github" />
            {!isLearner && (
              <>
                <ProfileField name="quirky_fact" label="Quirky Fact" />
                <ProfileField name="specialties" label="Specialties" />
              </>
            )}
            <ProfileField name="location_id" label="Location" as="select">
              <option></option>
              {locations.map((location, index) => (
                <option value={location.id} key={index}>
                  {location.name}
                </option>
              ))}
            </ProfileField>
            {!isLearner && <ProfileField name="clocktower_userid" label="Clocktower User ID" />}
            {!isLearner && (
              <>
                <ProfileField name="bio" label="Bio" as="textarea" />
                <ProfileField name="company_name" label="Company Name" />
                <ProfileField name="company_url" label="Company URL" />
              </>
            )}
            <div className="actions float-right">
              <ModalButton.Submit
                type="submit"
                disabled={isSubmitting}
                onClick={() => window.scrollTo(0, 0)}
                text="Submit"
              />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default ProfileForm;
