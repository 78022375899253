import PendingEvaluation from "./PendingEvaluation";
import ListGroup from "Components/ListGroup";

const PendingEvaluationsList = ({ tasks }) => {
  return (
    <ListGroup icon={tasks.length} title="Open Requests" className="open-requests">
      {tasks.map(task => (
        <PendingEvaluation key={`request-${task.id}`} assistanceRequest={task.assistanceRequest} />
      ))}
    </ListGroup>
  );
};

export default PendingEvaluationsList;
