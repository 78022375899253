import { useAppContext } from "Context/AppProvider";

const QueueMenu = () => {
  const {
    queueChannel: { connected },
  } = useAppContext();

  return (
    <div className="queue-header">
      <div className="navigation">
        <h2 className="queue-title">the queue</h2>
        {!connected && <i className="fa-solid fa-spinner text-primary queue-loader" />}
      </div>
    </div>
  );
};

export default QueueMenu;
