import AssistanceReviewForm from "./AssistanceReviewForm";
import ModalBackdrop from "../ModalBackdrop";
import StudentInfo from "../StudentInfo";
import Modal from "Components/Modal";

const AssistanceReviewModal = ({
  assistanceRequest,
  hide,
  nextForm,
  numberOfStudents,
  multipleStudents,
  index,
  backFn,
  answers,
  isLast,
}) => {
  const titleProgress = !multipleStudents ? "" : `(${index + 1} of ${numberOfStudents})`;

  return (
    <ModalBackdrop>
      <Modal title={`Review Assistance ${titleProgress}`} closeModal={hide}>
        <div>
          <b>Feedback for student:</b>
          <StudentInfo
            student={assistanceRequest.requestor}
            showDetails={true}
            when={assistanceRequest.startAt}
            tags={assistanceRequest.tags}
            is_lead={!assistanceRequest.parentId}
          />
        </div>
        <AssistanceReviewForm
          onSubmit={nextForm}
          assistanceRequest={assistanceRequest}
          prevForm={backFn}
          isLead={index === 0}
          isLast={isLast}
          answers={answers}
        />
      </Modal>
    </ModalBackdrop>
  );
};

export default AssistanceReviewModal;
