// place to consolidate any specially formatted toast messages

export const FormattedToastMessage = ({ title, message }) => (
  <div>
    <span style={{ fontWeight: "bold", color: "#000" }}>{title}</span>
    <span>
      <br></br>
      {message}
    </span>
  </div>
);

export const ArCreateFail = () => (
  <FormattedToastMessage
    title="The assistance request could not be created"
    message="Please contact your SSC to report the issue"
  />
);
