const notify = (title, options) => {
  try {
    if (!("Notification" in window)) {
      console.log("notifications not supported");
    } else if (Notification.permission === "granted") {
      return new Notification(title, options);
    } else if (Notification.permission !== "denied") {
      Notification.requestPermission().then(function (permission) {
        if (permission === "granted") {
          return new Notification(title, options);
        }
      });
    }
  } catch (e) {
    if (e instanceof TypeError) {
      // this occurs when mobile browsers attempt `new Notification`
      // TODO: replace this notification system with Push notifications for wider availability
      console.warn("Device does not support desktop notifications");
      return null;
    } else {
      // let sentry report any other errors that might occur
      throw e;
    }
  }
};

export default notify;
