import { useState } from "react";
import { useAppContext } from "Context/AppProvider";
import QueueMenu from "./QueueMenu";
import QueueSelector from "./QueueSelector";
import RequestSelector from "./RequestSelector";
import InProgressList from "./InProgressList";
import OpenRequestsList from "./OpenRequestsList";
import OpenSecondaryTasksList from "./OpenSecondaryTasksList";
import SettingsConditional from "Components/SettingsConditional";
import {
  selectAssignedTaskByAssistorUid,
  selectByAssistorUid,
  selectByRequestType,
  selectByProgram,
} from "Selectors/queue_selectors";

const AdminQueue = () => {
  const [selectedTeacher, setSelectedTeacher] = useState(null);
  const [selectedRequestType, setSelectedRequestType] = useState(null);
  const [selectedProgram, setSelectedProgram] = useState(null);

  const {
    teacherHandler: { teachers },
    tasksHandler: {
      allOpenTasks,
      inProgress,
      inProgressAIOnly,
      pendingEvaluations,
      allOpenSecondaryTasks,
    },
    studentHandler: { students },
  } = useAppContext();

  const filterRequests = (queue, allTasks) => {
    let tasks = allTasks;

    if (queue === "assigned" || queue === "tasks") {
      tasks = selectAssignedTaskByAssistorUid(tasks, selectedTeacher?.uid);
    } else {
      tasks = selectByAssistorUid(tasks, selectedTeacher?.uid);
    }

    tasks = selectByRequestType(tasks, selectedRequestType?.name);
    tasks = selectByProgram(tasks, selectedProgram?.name);
    return tasks;
  };
  const filteredOpenRequests = filterRequests("assigned", allOpenTasks());
  const filteredOpenSecondaryTasks = filterRequests("tasks", allOpenSecondaryTasks());
  const filteredInProgressTasks = filterRequests("in progress", [
    ...inProgress(),
    ...inProgressAIOnly(),
  ]);
  const filteredEvaluations = filterRequests("evaluations", pendingEvaluations());

  return (
    <>
      <QueueMenu selectedTeacher={selectedTeacher} />
      <div className="queue-column queue-top mt-3">
        <QueueSelector
          teachers={teachers}
          selectedTeacher={selectedTeacher}
          selectTeacher={setSelectedTeacher}
        />
        <SettingsConditional flag={SettingsConditional.ADMIN_QUEUE_FILTER}>
          <RequestSelector
            selectedRequestType={selectedRequestType}
            selectRequestType={setSelectedRequestType}
            selectedProgram={selectedProgram}
            selectProgram={setSelectedProgram}
          />
        </SettingsConditional>
        <OpenRequestsList tasks={filteredOpenRequests} />
        <SettingsConditional flag={SettingsConditional.SECONDARY_TASK_QUEUE}>
          <OpenSecondaryTasksList tasks={filteredOpenSecondaryTasks} />
        </SettingsConditional>
      </div>
      <div className="queue-row queue-bottom ">
        <div className="queue-column left">
          <InProgressList tasks={filteredInProgressTasks} />
          <SettingsConditional
            flag={SettingsConditional.ADMIN_OPEN_REQUESTS}
            tasks={filteredEvaluations}
          />
        </div>
        <div className="queue-column right">
          <SettingsConditional flag={SettingsConditional.ADMIN_STUDENTS_LIST} students={students} />
        </div>
      </div>
    </>
  );
};

export default AdminQueue;
