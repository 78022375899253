import { kebabCase } from "util/string";
import classNames from "classnames";

const QueueItem = ({
  type,
  label,
  standOut,
  priority,
  children,
  disabled,
  small,
  thickDividers = false,
}) => {
  const queueItemClass = classNames("list-group-item", "clearfix", `${kebabCase(type)}`, {
    "queue-item": !label,
    [`queue-${kebabCase(label)}`]: label,
    disabled: disabled,
    standout: standOut,
    priority: priority,
    "thick-dividers": !!thickDividers,
  });

  const determineLabel = () => {
    if (small) return label;
    if (type === "Evaluation" || !label) return type;
    return `${label} ${type}`;
  };

  const labelText = determineLabel();
  return (
    <li className={queueItemClass}>
      <div className="type">
        <div className="text">{labelText}</div>
      </div>
      {children}
    </li>
  );
};

export default QueueItem;
