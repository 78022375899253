import { useEffect, useState } from "react";
import usePorts from "Hooks/usePorts";
import { isBrowserExtension } from "util/env";

const useWebPushUpdates = () => {
  const [webPushUpdates, setWebPushUpdates] = useState(null);
  const port = usePorts();

  // Only run this once a port connects
  useEffect(() => {
    if (!port) return;

    const onMessage = msg => {
      const { channel, payload } = msg;
      if (channel === "web-push-updates") {
        setWebPushUpdates(payload);
      }
    };

    port.onMessage.addListener(onMessage);

    return () => {
      port.onMessage.removeListener(onMessage);
    };
  }, [port]);

  // listen to one-time message and update state
  useEffect(() => {
    if (!isBrowserExtension()) return;

    const onOneTimeMessage = ({ name, message }, _, sendResponse) => {
      if (name === "webpush-update") {
        setWebPushUpdates(message.data);
        sendResponse({ message: "update received" });
      }
    };

    chrome.runtime.onMessage.addListener(onOneTimeMessage);

    return () => {
      chrome.runtime.onMessage.removeListener(onOneTimeMessage);
    };
  }, []);

  return webPushUpdates;
};

export default useWebPushUpdates;
