import { useEffect, useReducer } from "react";
import { teachersWithUpdates } from "Selectors/queue_selectors";
import requests from "Requests";
import { isBrowserExtension } from "util/env";
import { useLoadingContext } from "Context/LoadingProvider";

const teacherReducer = (state, action) => {
  switch (action.type) {
    case "setTeachers": {
      const teachers = action.data.reduce(function (result, item) {
        result[item.uid] = item;
        return result;
      }, {});
      return { ...state, teachers };
    }
    case "addUpdates":
      return {
        ...state,
        teachers: teachersWithUpdates(state.teachers, action.data),
      };
    case "error":
      return { ...state, error: action.data };
    default:
      throw new Error();
  }
};

/**
 * @param {Object[]} updates - queueSocket.teacherUpdates - Array of User objects.
 *   Updated when teacher goes on / off duty, starts an assistance, etc.
 *   Updates any time the "teacherUpdate" socket message is received.
 * @param {Object} user - The current user
 *   Note: Starts off as the initial state of useCurrentUser.jsx, then resolves to the actual user object.
 */
const useTeachers = (updates, user) => {
  const [teachersState, dispatchTeachersState] = useReducer(teacherReducer, {
    teachers: {},
    error: null,
  });
  const { setDepsLoaded } = useLoadingContext();

  useEffect(() => {
    if (!user.uid) return;

    const getTeachers = () => {
      requests
        .getTeachers()
        .then(teachers => {
          if (teachers) {
            dispatchTeachersState({ type: "setTeachers", data: teachers });
          }
        })
        .catch(() => {
          dispatchTeachersState({
            type: "error",
            data: "Could not fetch teacher data!",
          });
        })
        .finally(() => {
          setDepsLoaded(state => ({ ...state, teachersUpdated: true }));
        });
    };

    getTeachers();

    if (isBrowserExtension()) {
      const pollingTeachers = setInterval(() => {
        getTeachers();
      }, 5000);

      return () => {
        clearInterval(pollingTeachers);
      };
    }
  }, [user.uid, setDepsLoaded]);

  useEffect(() => {
    dispatchTeachersState({ type: "addUpdates", data: updates });
  }, [updates]);

  const isTeacherOnDuty = uid => {
    return teachersState.teachers[uid] && teachersState.teachers[uid].onDuty;
  };

  const isTeacherBusy = uid => {
    return teachersState.teachers[uid] && teachersState.teachers[uid].busy;
  };

  return {
    teachers: Object.values(teachersState.teachers),
    isTeacherOnDuty,
    isTeacherBusy,
    error: teachersState.error,
  };
};

export default useTeachers;
