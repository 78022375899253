import { useState, createContext, useContext } from "react";

const AssistanceModalContext = createContext(null);
export const useAssistanceModalContext = () => useContext(AssistanceModalContext);

// this provider was created so that components that aren't related to AssistanceButtons can trigger modal display
// it could eventually include logic for ensuring only one modal is displayed at a time, or which modals can stack
// TODO: we should eventually render the modals themselves elsewhere too
const AssistanceModalProvider = ({ children }) => {
  const [showCreateRequestModal, setShowCreateRequestModal] = useState(false);
  const [showNoMentorModal, setShowNoMentorModal] = useState(false);
  const [showViewRequestModal, setShowViewRequestModal] = useState(false);
  const [showCancelRequestModal, setShowCancelRequestModal] = useState(false);
  const [showFeedbackModal, setShowFeedbackModal] = useState(false);
  const [exitedFeedback, setExitedFeedback] = useState(false);
  const [showAIOnlyModal, setShowAIOnlyModal] = useState(false);
  const [showAIFeedbackModal, setShowAIFeedbackModal] = useState(false);

  const values = {
    showCreateRequestModal,
    setShowCreateRequestModal,
    showNoMentorModal,
    setShowNoMentorModal,
    showViewRequestModal,
    setShowViewRequestModal,
    showCancelRequestModal,
    setShowCancelRequestModal,
    showFeedbackModal,
    setShowFeedbackModal,
    exitedFeedback,
    setExitedFeedback,
    showAIOnlyModal,
    setShowAIOnlyModal,
    showAIFeedbackModal,
    setShowAIFeedbackModal,
  };

  return (
    <AssistanceModalContext.Provider value={values}>{children}</AssistanceModalContext.Provider>
  );
};

export default AssistanceModalProvider;
