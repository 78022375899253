export const ACCESS_LEVELS = {
  Learner: 1,
  Teacher: 2,
  Admin: 3,
  SuperAdmin: 4,
  SystemAdmin: 5,
};

export const CLIENT_TYPES = {
  web: "web",
  embedded: "embedded",
};

// the regex below is from http://emailregex.com/
// we use this regex because of all the checks it does, and also to allow only one email at a time
// when not using the bulk invite.
export const EMAIL_REGEX =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

// accepts space;,
export const EMAIL_SEPARATORS_REGEX = /[\s;,]+/;

// this is to remove emails that have a dot at the end
export const END_OF_STRING_DOT = /\.$/;
