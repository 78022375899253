const thresholds = [
  { unit: "year", seconds: 31557600, limit: null }, // No upper limit for years
  { unit: "month", seconds: 2629800, limit: 11 },
  { unit: "week", seconds: 604800, limit: 4 },
  { unit: "day", seconds: 86400, limit: 26 },
  { unit: "hour", seconds: 3600, limit: 22 },
  { unit: "minute", seconds: 60, limit: 45 },
  { unit: "second", seconds: 1, limit: 45 },
  { unit: "few seconds", seconds: 0, limit: 44 }, // Special case for a few seconds
];

export const humanize = (duration, withSuffix = false) => {
  const durationInSeconds = Math.abs(duration) / 1000;
  const isFuture = duration > 0;

  const { unit, seconds } = thresholds.reduce((selected, threshold) => {
    if (durationInSeconds < threshold.limit * threshold.seconds) return threshold;
    return selected;
  }, thresholds[0]);

  const value = seconds > 0 ? Math.round(durationInSeconds / seconds) : "";
  const plural = value !== 1 && unit !== "few seconds" ? "s" : "";
  const suffix = withSuffix ? (isFuture ? " from now" : " ago") : "";

  return value ? `${value} ${unit}${plural}${suffix}` : `a ${unit}${suffix}`;
};
