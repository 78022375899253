export default (axios, errorHandler) => {
  const getCategories = () => {
    return axios
      .get(`/categories`)
      .then(response => {
        return response.data.categories;
      })
      .catch(err => {
        errorHandler(err);
      });
  };

  return {
    getCategories,
  };
};
