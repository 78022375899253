const ActivityOptionGroup = ({ groupName, activities }) => (
  <optgroup label={groupName}>
    {activities.map(a => (
      <option key={a.uuid} value={a.uuid}>
        {a.name}
      </option>
    ))}
  </optgroup>
);

const ActivitySelectInput = ({
  selectableActivities,
  recentActivities,
  field,
  isAIOnly = false,
  ...props
}) => {
  // allow onChange to optionally be overridden by a prop, but use formik field's onChange by default
  const onChange = props.onChange ? props.onChange : field.onChange;

  const handleChange = e => {
    onChange({ target: { name: field.name, value: e.target.value } });
  };

  const groupedActivities = {
    "Recently Viewed": recentActivities,
    ...selectableActivities,
  };

  return (
    <select className="form-control" {...props} {...field} onChange={handleChange}>
      {!isAIOnly && <option value="other">Other...</option>}
      {Object.keys(groupedActivities).map(groupName => (
        <ActivityOptionGroup
          key={groupName}
          groupName={groupName}
          activities={groupedActivities[groupName].activities}
        ></ActivityOptionGroup>
      ))}
    </select>
  );
};

export default ActivitySelectInput;
