import Spinner from "Components/Spinner";
import { Alert } from "react-bootstrap";
import useSuggestedAssistanceRequests from "Hooks/useSuggestedAssistanceRequests";
import SuggestedAssistanceRequest from "./SuggestedAssistanceRequest";
import "./SuggestedAssistanceRequestList.scss";
import SettingsConditional from "Components/SettingsConditional";

const SuggestedArs = ({ isLoading, requests, error, message }) => (
  <div className="suggested-requests-list">
    {isLoading ? (
      <Spinner />
    ) : (
      <>
        {error && <Alert variant="danger">{error.message || "Could not retrieve requests"}</Alert>}
        {requests.map((request, i) => (
          <SuggestedAssistanceRequest key={`${i}-${request.id}`} assistanceRequest={request} />
        ))}
        {!error && requests.length === 0 && <p className="text-muted">{message}</p>}
      </>
    )}
  </div>
);

const SuggestedAssistanceRequestList = ({ resourceUuid, pods }) => {
  const { ongoingRequests, completedRequests, error, isLoading } = useSuggestedAssistanceRequests(
    resourceUuid,
    pods
  );
  return (
    <SettingsConditional flag={SettingsConditional.SUGGEST_ASSISTANCES}>
      <div className="suggested-ars-list">
        {resourceUuid && (
          <>
            <label htmlFor="match-ars-list">Ongoing assistances for this activity:</label>
            <SuggestedArs
              isLoading={isLoading}
              requests={ongoingRequests}
              error={error}
              message="No ongoing requests found"
            />
            <SettingsConditional flag={SettingsConditional.SUGGEST_SOLVED_ASSISTANCES}>
              <label htmlFor="match-ars-list">Completed assistances for this activity:</label>
              <SuggestedArs
                isLoading={isLoading}
                requests={completedRequests}
                error={error}
                message="No completed requests found"
              />
            </SettingsConditional>
          </>
        )}
      </div>
    </SettingsConditional>
  );
};

export default SuggestedAssistanceRequestList;
