import { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import PaddedContent from "Layouts/PaddedContent";
import { useAppContext } from "Context/AppProvider";
import StudentInfo from "Components/StudentInfo";
import Tabs from "Components/Tabs";
import Button from "Components/Buttons/AssistanceButtons";
import Assistances from "./tabs/Assistances";
import Tags from "./tabs/Tags";
import Info from "./tabs/Info";
import NotFound from "Components/NotFound";
import ManualAssistanceModal from "Components/ManualAssistanceModal";
import "./Show.scss";

const tabs = {
  Info: { key: "info" },
  Assistances: { key: "assistances" },
  Tags: { key: "tags", admin: true },
};

const Tab = ({ tab, user, applyTag, removeTag }) => {
  switch (tab) {
    case tabs.Info.key:
      return <Info user={user} />;
    case tabs.Assistances.key:
      return <Assistances uid={user.uid} />;
    case tabs.Tags.key:
      return <Tags user={user} applyTag={applyTag} removeTag={removeTag} />;
  }
};

const Show = () => {
  const {
    currentUser,
    studentHandler: { getStudent, applyTag, removeTag },
  } = useAppContext();
  const { uid, tab: initialTab } = useParams();
  const tab = initialTab || tabs.Info.key;
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const user = getStudent(uid);
  const openModal = () => setShowModal(true);

  const onChangeTab = tab => navigate(`/learners/${uid}/${tab}`);

  const keys = Object.values(tabs)
    .filter(tab => !tab.admin || currentUser.isAdmin)
    .map(tab => tab.key);

  if (user.error) return <NotFound />;

  return (
    <PaddedContent className="learner-page">
      <div className="learner">
        <StudentInfo student={user} when={user.lastAssistedAt} showDetails hideTags />
        <div className="d-flex justify-content-end my-2">
          <Button.Manual onClick={openModal} text="Log an Assistance" />
        </div>
        <Tabs tab={tab} tabs={keys} onChangeTab={onChangeTab}>
          <Tab tab={tab} user={user} applyTag={applyTag} removeTag={removeTag} />
        </Tabs>
      </div>
      {showModal && <ManualAssistanceModal hide={() => setShowModal(false)} student={user} />}
    </PaddedContent>
  );
};

export default Show;
