const inProgressStates = ["in_progress", "needs_review", "claimed", "needs_task_review"];
const secondaryRequestTypes = ["TaskRequest", "Evaluation", "CodeReview"];
const now = new Date();
const feedbackExpiry = new Date(now.getTime() - 10 * 24 * 60 * 60 * 1000); // ten days ago

const requestTypeFilters = {
  "User Request": task =>
    task.assistanceRequest.type === "UserRequest" && !task.assistanceRequest.request.isAIOnly,
  "Code Review": task => task.assistanceRequest.type === "CodeReview",
  "Tech Interview": task => task.assistanceRequest.type === "TechInterview",
  "Project Evaluation": task => task.assistanceRequest.type === "Evaluation",
  "Larry Only Request": task => task.assistanceRequest.request.isAIOnly,
  "Mentor Task": task => task.assistanceRequest.type === "TaskRequest",
};

/**
 * How does this decide if a task is open?
 * @param {Array} tasks Tasks from the server
 * @returns {Array} List of tasks to be displayed in Open Requests
 */

const selectAllOpen = tasks => {
  const matches = {};
  return tasks.filter(task => {
    if (
      task.state === "pending" &&
      task.assistor &&
      task.assistanceRequest.type === "UserRequest"
    ) {
      if (matches[task.assistanceRequest.id]) {
        matches[task.assistanceRequest.id].assistors.push(task.assistor);
      } else {
        task.assistors = [task.assistor];
        matches[task.assistanceRequest.id] = task;
        return true;
      }
    }
    return false;
  });
};

const selectAllOpenSecondaryTasks = tasks => {
  const matches = {};
  return tasks.filter(task => {
    if (
      task.state === "pending" &&
      task.assistor &&
      secondaryRequestTypes.includes(task.assistanceRequest.type)
    ) {
      if (matches[task.assistanceRequest.id]) {
        matches[task.assistanceRequest.id].assistors.push(task.assistor);
      } else {
        task.assistors = [task.assistor];
        matches[task.assistanceRequest.id] = task;
        return true;
      }
    }
    return false;
  });
};

const selectAllOpenForUser = (tasks, user) => {
  return tasks.filter(task => {
    return (
      task.state === "pending" &&
      task.assistor?.uid === user.uid &&
      task.assistanceRequest.type === "UserRequest"
    );
  });
};

const selectAllOpenSecondaryTasksForUser = (tasks, user) => {
  return tasks.filter(task => {
    return (
      task.state === "pending" &&
      task.assistor?.uid === user.uid &&
      secondaryRequestTypes.includes(task.assistanceRequest.type)
    );
  });
};

const selectGeneralRequests = tasks => {
  return tasks.filter(task => {
    return (
      task.state === "pending" &&
      task.assistanceRequest.type !== "TaskRequest" &&
      !task.assistor &&
      !task.assistanceRequest.request?.isAIOnly
    );
  });
};

const selectAIOnlyTasks = tasks =>
  tasks.filter(task => task.state === "pending" && task.assistanceRequest.request?.isAIOnly);

/**
 * The idea is to have one task to get through per AR.
 * Task that gets though should be the one that belongs AR's assistor,
 * to ensure it appears to them with the correct action buttons.
 */
const selectInProgress = tasks => {
  return tasks.filter(task => {
    if (inProgressStates.includes(task.state)) {
      return (
        task.assistor &&
        task.assistance &&
        // unrouted requests only have one QT with an assistor that can be nil, so we can skip checking assistance/QT assistor matches for it - Q
        (!task.assistanceRequest?.request?.route ||
          task.assistor.uid === task.assistance?.assistor?.uid) &&
        !task.skippedAt
      );
    }

    return false;
  });
};

const selectFeedbacks = tasks => tasks.filter(t => t.assistance?.feedback);
const selectAIFeedbacks = tasks => tasks.filter(t => t.assistanceRequest.feedback);

const selectPendingFeedbacks = tasks =>
  tasks.filter(
    t => t.assistance && !t.assistance.feedback && new Date(t.createdAt) > feedbackExpiry
  );

const selectPendingAIFeedbacks = tasks =>
  tasks.filter(
    t =>
      t.assistanceRequest.request?.isAIOnly &&
      !t.assistanceRequest.feedback &&
      new Date(t.createdAt) > feedbackExpiry
  );

const tasksWithUpdates = (tasks, updates) =>
  updates.reduce((acc, { object }) => ({ ...acc, [object.id]: object }), { ...tasks });

const teachersWithUpdates = (teachers, updates) =>
  updates.reduce(
    (acc, { object: teacher }) => {
      if (teacher.onDuty) acc[teacher.uid] = teacher;
      else delete acc[teacher.uid];
      return acc;
    },
    { ...teachers }
  );

const studentsWithUpdates = (students, updates) =>
  updates.reduce((acc, { object: student }) => ({ ...acc, [student.uid]: student }), {
    ...students,
  });

const filteredStudents = (students, searchQuery) =>
  students.filter(student => student.fullName?.toLowerCase().includes(searchQuery.toLowerCase()));

const cohortsWithUpdates = (cohorts, updates) => {
  cohorts = [...cohorts];
  for (let update of updates) {
    const task = update.object;
    if (task.type === "TechInterview" && task.assistanceRequest.state === "closed") {
      for (let [index, cohort] of cohorts.entries()) {
        if (cohort.id === task.assistanceRequest.requestor.cohortId) {
          cohorts[index] = {
            ...cohort,
            interviewStatuses: updateInterviewStatuses(cohort.interviewStatuses, task.taskObject),
          };
        }
      }
    }
  }
  return cohorts;
};

const updateInterviewStatuses = (interviewStatuses, interview) => {
  interviewStatuses = [...interviewStatuses];
  for (let [index, interviewStatus] of interviewStatuses.entries()) {
    if (interview.techInterviewTemplate.week === interviewStatus.week) {
      const incomplete = interviewStatus.incompleteStudentIds;
      const studentPosition = incomplete.indexOf(interview.interviewee.id);

      if (studentPosition >= 0) {
        const incompleteStudentIds = [...incomplete];
        incompleteStudentIds.splice(studentPosition, 1);
        interviewStatuses[index] = {
          ...interviewStatus,
          incompleteStudentIds,
          completedStudentIds: [...interviewStatus.completedStudentIds, interview.interviewee.id],
        };
      }
    }
  }
  return interviewStatuses;
};

const selectByAssistorUid = (tasks, uid) =>
  uid ? tasks.filter(t => t.assistor?.uid === uid) : tasks;

const selectAssignedTaskByAssistorUid = (tasks, uid) =>
  uid ? tasks.filter(t => t.assistors.some(a => a.uid === uid)) : tasks;

const selectNeedsReview = (tasks, uid) =>
  tasks.filter(
    task =>
      inProgressStates.includes(task.state) &&
      task.assistanceRequest.type === "UserRequest" &&
      task.assistance?.assistor?.uid === uid
  );

const selectByRequestType = (tasks, typeName) => {
  const requestTypeFilter = requestTypeFilters[typeName];
  return requestTypeFilter ? tasks.filter(requestTypeFilter) : tasks;
};

const selectByProgram = (tasks, programName) => {
  return programName
    ? tasks.filter(task => task.assistanceRequest.deployment.includes(programName.toLowerCase()))
    : tasks;
};

export {
  selectAllOpen,
  selectAllOpenSecondaryTasks,
  selectAllOpenForUser,
  selectAllOpenSecondaryTasksForUser,
  selectGeneralRequests,
  selectAIOnlyTasks,
  selectInProgress,
  selectFeedbacks,
  selectAIFeedbacks,
  selectPendingFeedbacks,
  selectPendingAIFeedbacks,
  tasksWithUpdates,
  teachersWithUpdates,
  studentsWithUpdates,
  filteredStudents,
  cohortsWithUpdates,
  selectByAssistorUid,
  selectAssignedTaskByAssistorUid,
  selectNeedsReview,
  selectByRequestType,
  selectByProgram,
};
