const conferenceTypes = {
  "Google Meet": {
    name: "Google Meet",
    icon: <i className="fa fab fa-google" />,
  },
  randomlyGeneratedHangout: {
    name: "Google Hangout",
    icon: <i className="fa fa-fw fa-video" />,
  },
  DiscordForum: {
    name: "Discord",
    icon: <i className="fa fab fa-discord" />,
  },
  Chat: {
    name: "Chat",
    icon: <i className="fa fa-fw fa-comment" />,
  },
  VideoConference: {
    name: "Video Conference",
    icon: <i className="fa fa-fw fa-video" />,
  },
};

export const determineConferenceTypeName = type => {
  return conferenceTypes[type]?.name || conferenceTypes["VideoConference"].name;
};

export const determineConferenceTypeIcon = type => {
  return conferenceTypes[type]?.icon || conferenceTypes["VideoConference"].icon;
};

export const assistanceButtonIcons = {
  profileIncomplete: "fa-circle-exclamation",
  isAIOnly: "fa-robot",
  missingExternalChannel: "fa-circle-exclamation",
  default: "fa-user-group",
};
