import "./ExtensionSignInSuccess.scss";

const ExtensionSignInSuccess = () => {
  return (
    <div className="container alert alert-success">
      <i className="succcess-icon fa-solid fa-info-circle fa-2x" />
      <div className="column">
        <p>You have successfully signed in with Rudder Chrome extension!</p>
        <p>Feel free to close this tab now and continue with the extension.</p>
      </div>
    </div>
  );
};

export default ExtensionSignInSuccess;
