export default (axios, errorHandler) => {
  const postIdToken = token => {
    return axios
      .post("/learning_platforms/launch", { id_token: token })
      .then(response => response.data)
      .catch(errorHandler);
  };

  const refreshOAuthToken = platform => {
    return axios
      .get("/o_auth_tokens", { params: { platform } })
      .then(response => response.data)
      .catch(errorHandler);
  };

  const getUser = (id, isLearner) => {
    const params = {};
    if (isLearner) {
      params["is_learner"] = true;
    }
    return axios
      .get(`/users/${id}`, { params })
      .then(response => response.data)
      .catch(errorHandler);
  };

  const getUsers = (keywords, pageQuery, searchQuery) => {
    const params = {
      keywords: keywords,
      page: pageQuery,
      search_query: searchQuery,
    };
    return axios
      .get("/users", { params })
      .then(response => {
        return pageQuery ? response.data : response.data.users;
      })
      .catch(errorHandler);
  };

  const updateUser = (user, update, isLearner = false) => {
    return axios
      .put(`/users/${isLearner ? user.uid : user.id}`, {
        is_learner: isLearner,
        ...update,
      })
      .then(response => response.data)
      .catch(errorHandler);
  };

  const getUserDetails = user => {
    return axios
      .get(`/users/${user.id}`)
      .then(response => response.data.userDetails)
      .catch(errorHandler);
  };

  const deleteUserAttribution = (user, tag, isLearner) => {
    const searchString = new URLSearchParams();
    if (isLearner) searchString.append("is_learner", true);

    return axios
      .delete(`/users/${user.id}/tag_attributions/${tag.id}?${searchString.toString()}`)
      .then(response => response.data)
      .catch(errorHandler);
  };

  const getUserAssistances = (uid, isLearner) => {
    const params = {};
    if (isLearner) params["is_learner"] = true;

    return axios
      .get(`/users/${uid}/assistances`, { params })
      .then(response => response.data)
      .catch(errorHandler);
  };

  // returns current user object for login/auth purposes
  const getCurrentUser = () => {
    return axios
      .get("/current_user")
      .then(response => response.data.currentUser)
      .catch(err => {
        if (err.response?.status === 404) {
          console.warn(
            "No current user found. This is expected if you're not currently logged in."
          );
        } else {
          errorHandler(err);
        }
      });
  };

  const getExternalUser = (uid, userType, platform, deploymentId) => {
    return axios
      .get(
        `/external_user/${uid}?userType=${userType}&platform=${platform}&deploymentId=${deploymentId}`
      )
      .then(response => response.data)
      .catch(errorHandler);
  };

  const deactivateExternalUser = (userUid, platform, userType, deploymentId) => {
    return axios
      .put(`/external_user/deactivate`, { userUid, platform, userType, deploymentId })
      .then(response => response.data)
      .catch(errorHandler);
  };

  return {
    postIdToken,
    refreshOAuthToken,
    getUser,
    getUsers,
    updateUser,
    getUserDetails,
    deleteUserAttribution,
    getUserAssistances,
    getCurrentUser,
    getExternalUser,
    deactivateExternalUser,
  };
};
