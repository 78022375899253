export const Keys = {
  IGNORE_ZOOM_OAUTH: "ignoreZoomOAuth",
};

export const getItem = key => {
  if (key === Keys.IGNORE_ZOOM_OAUTH) {
    return JSON.parse(localStorage.getItem(key) || "false");
  }

  throw `Storage error: "${key}" not among Keys: ${Keys}`;
};

export const setItem = (key, value) => {
  let valueErrorType;

  if (key === Keys.IGNORE_ZOOM_OAUTH) {
    valueErrorType =
      typeof value === "boolean" ? localStorage.setItem(key, JSON.stringify(value)) : "boolean";
  }

  if (valueErrorType) {
    throw `Storage error: value "${value}" for key "${key}" is not of type ${valueErrorType}`;
  }

  if (!Object.values(Keys).includes(key)) {
    throw `Storage error: "${key}" not among Keys: ${Keys}`;
  }
};
