import "./BotRecommendationInput.scss";
import { LLM_recommendation_bot_name as llmBotName } from "../../../constants/token_api";

const BotRecommendationInput = ({ field, disabled }) => {
  const radioLabels = {
    follow: `Follow ${llmBotName}'s recommendation`,
    ignore: `Ignore ${llmBotName}'s recommendation`,
    notApplicable: `Not applicable (no recommendation, error message, ...)`,
  };
  const { value } = field;
  return (
    <div className="column-input d-flex justify-content-between">
      {Object.keys(radioLabels)
        .sort((a, b) => b - a)
        .map(key => {
          return (
            <div key={key} className="form-check form-check-inline">
              <input
                className="form-check-input"
                name="LlmbotRecommendation"
                type="radio"
                id={`llm-recommendation-${key}`}
                {...field}
                disabled={disabled}
                value={key}
                checked={value === key}
              />
              <label className="form-check-label" htmlFor={`llm-recommendation-${key}`}>
                {radioLabels[key]}
              </label>
            </div>
          );
        })}
    </div>
  );
};

export default BotRecommendationInput;
