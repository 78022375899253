import { useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import "./Components.scss";

const renderTooltip = props => (
  <Tooltip id="button-tooltip" {...props}>
    Click to copy to clipboard
  </Tooltip>
);

const CopyToClipBoard = ({ text, icon, iconCopied }) => {
  const [copied, setCopied] = useState(false);

  const copyInviteUrlTopClipboard = () => {
    navigator.clipboard.writeText(text);
    setCopied(true);
  };

  const iconCopiedClasses = `fa-regular ${iconCopied} fa-lg`;
  const iconDefaultClasses = `fa-solid fa-fw ${icon} fa-lg`;

  return (
    <OverlayTrigger placement="right" delay={{ show: 250, hide: 400 }} overlay={renderTooltip}>
      <div onClick={() => copyInviteUrlTopClipboard()}>
        {copied ? (
          <i className={`copy ${iconCopiedClasses}`} />
        ) : (
          <i className={`copy ${iconDefaultClasses}`} />
        )}
      </div>
    </OverlayTrigger>
  );
};

export default CopyToClipBoard;
