import { createContext, useContext } from "react";
import useQueueChannel from "Hooks/useQueueChannel";
import useTeachers from "Hooks/useTeachers";
import useTasks from "Hooks/useTasks";
import useCurrentUser from "Hooks/useCurrentUser";
import useUserActivity from "Hooks/useUserActivity";
import useSettings from "Hooks/useSettings";
import useStudents from "Hooks/useStudents";

const AppContext = createContext(null);
export const useAppContext = () => useContext(AppContext);

const AppProvider = ({ children, clientType }) => {
  const path = process.env.REACT_APP_SERVER + "/api";

  const queueChannel = useQueueChannel();
  const currentUser = useCurrentUser(
    queueChannel.teacherUpdates,
    queueChannel.userUpdates,
    queueChannel.rejected
  );
  const teacherHandler = useTeachers(queueChannel.teacherUpdates, currentUser, path);
  const studentHandler = useStudents(queueChannel.studentUpdates, currentUser);
  const tasksHandler = useTasks(queueChannel.queueUpdates, currentUser, queueChannel.pendingArs);
  const userActivityHandler = useUserActivity(queueChannel, currentUser);
  const settingsHandler = useSettings();

  const value = {
    queueChannel,
    teacherHandler,
    studentHandler,
    tasksHandler,
    currentUser,
    userActivityHandler,
    settingsHandler,
    clientType,
  };

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};

export default AppProvider;
