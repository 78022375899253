import { useEffect, createContext, useContext, useState } from "react";
import { storageSet, storageGet } from "BrowserExtension/utils/storage";

const LoadingContext = createContext();

export const useLoadingContext = () => useContext(LoadingContext);

const LoadingProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isFirstLoad, setIsFirstLoad] = useState(true);

  const [depsLoaded, setDepsLoaded] = useState({
    userUpdated: false,
    requestUpdated: false,
    feedbackCountUpdated: false,
    teachersUpdated: false,
  });

  // stop the loading phase when al deps are loaded
  useEffect(() => {
    setIsLoading(true);
    if (Object.values(depsLoaded).every(d => d)) {
      setIsLoading(false);
      setIsFirstLoad(false);
      storageSet({ isFirstAppLoad: false });
    }
  }, [depsLoaded]);

  // stop the loading phase when auth is dropped
  useEffect(() => {
    storageGet("authStatus").then(res => {
      const { authStatus } = res || {};
      if (authStatus !== "logged-in") {
        setIsFirstLoad(false);
        setIsLoading(false);
        storageSet({ isFirstAppLoad: false });
      }
    });
  }, [isFirstLoad]);

  return (
    <LoadingContext.Provider
      value={{
        isLoading,
        setIsLoading,
        isFirstLoad,
        setIsFirstLoad,
        setDepsLoaded,
      }}
    >
      {children}
    </LoadingContext.Provider>
  );
};

export default LoadingProvider;
