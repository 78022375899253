import { Formik, Form } from "formik";
import InputField from "Components/Form/InputField";
import ErrorList from "Components/Form/ErrorList";
import * as Yup from "yup";
import ModalButton from "./Buttons/ModalButtons";
import "Components/Form/Form.scss";

const SkipField = props => <InputField prefix="cancel" {...props} />;

const SkipRequestForm = ({ requestType, onSubmit }) => {
  const initialValues = {
    reason: "",
    notes: "",
  };

  const mentorFacingReasons = [
    {
      id: 1,
      value: "uncomfortable_activity",
      displayText: "This is not my area of expertise.",
      request: true,
      task: true,
    },
    {
      id: 2,
      value: "uncomfortable_student",
      displayText: "I'm not comfortable with this student.",
      request: true,
      task: false,
    },
    {
      id: 3,
      value: "shouldnt_be_assigned",
      displayText: "This shouldn't be assigned to me.",
      request: true,
      task: true,
    },
    {
      id: 4,
      value: "another-mentor",
      displayText: "The student wants to work with another mentor than me.",
      request: true,
      task: false,
    },
    {
      id: 5,
      value: "invalid-task",
      displayText: "This task seems erroneous or invalid.",
      request: false,
      task: true,
    },
    {
      id: 6,
      value: "prefer-not",
      displayText: "I would prefer not to work on this.",
      request: false,
      task: true,
    },
  ];

  const filteredReasonsByType = mentorFacingReasons.filter(reason => {
    if (requestType === "request") {
      return reason.request === true;
    } else if (requestType === "task") {
      return reason.task === true;
    }
    return true;
  });

  const requestSchema = Yup.object().shape({
    reason: Yup.string().required("Please select a reason for skipping"),
    notes: Yup.lazy((_, schemaContext) => {
      if (schemaContext.parent.reason === "shouldnt_be_assigned") {
        return Yup.string().required(
          "An explanation is required for why the request should not be assigned to you"
        );
      }
      if (schemaContext.parent.reason === "other") {
        return Yup.string().required("An explanation is required when selecting 'Other'");
      }
      return Yup.string();
    }),
  });

  const customValidate = values => {
    try {
      requestSchema.validateSync(values, {
        abortEarly: false,
        context: values,
      });
    } catch (error) {
      return error.inner.reduce((errors, err) => ({ ...errors, [err.path]: err.message }), {});
    }
  };

  return (
    <Formik initialValues={initialValues} validate={customValidate} onSubmit={onSubmit}>
      {({ isSubmitting, touched, errors, values }) => (
        <>
          <ErrorList touched={touched} errors={errors} />
          <Form>
            <SkipField name="reason" label="Why are you skipping this request?" as="select">
              <option key="" value="">
                Select a reason
              </option>
              <>
                {filteredReasonsByType?.map(reason => (
                  <option key={reason.id} value={reason.value}>
                    {reason.displayText}
                  </option>
                ))}

                <option key="other" value="other">
                  Other
                </option>
              </>
            </SkipField>
            {values.reason === "shouldnt_be_assigned" && (
              <SkipField
                name="notes"
                label="Please specify why this shouldn't be assigned to you"
                as="textarea"
              />
            )}
            {values.reason === "other" && (
              <SkipField name="notes" label="Please specify for 'Other'" as="textarea" />
            )}
            <div className="actions float-right">
              <ModalButton.Submit type="submit" text="Submit" disabled={isSubmitting} />
            </div>
          </Form>
        </>
      )}
    </Formik>
  );
};

export default SkipRequestForm;
