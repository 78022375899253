import { useState } from "react";
import { Formik, Form } from "formik";
import InputField from "Components/Form/InputField";
import ErrorList from "Components/Form/ErrorList";
import * as Yup from "yup";
import _ from "lodash";
import { Button, Tooltip, OverlayTrigger } from "react-bootstrap";
import useCategories from "Hooks/useCategories";
import "Components/Form/Form.scss";
import { OptionButtonInput, TagSelectInput } from "Components/Form/CustomInputs";
import StepIndicator from "Components/Form/StepIndicator";
import isEmpty from "lodash/isEmpty";
import { ActivitySelectInput } from "Components/Form/CustomInputs";
import { MINIMUM_DESCRIPTION_LENGTH } from "constants/assistanceRequest";
import SuggestedAssistanceRequestList from "./SuggestedAssistanceRequest/SuggestedAssistanceRequestList";

const RequestField = props => <InputField prefix="request" {...props} />;

const RequestForm = ({
  workContext,
  onSubmit,
  hide,
  chatAssistanceEnabled,
  settings,
  currentUser,
  isAIOnly,
  showEnhancedRequestPrompt,
  showOptionalRequestInputs,
}) => {
  const { selectableActivities, recentActivities, currentActivity, pods } = workContext;
  const hasActivities = selectableActivities && !isEmpty(selectableActivities);
  const canMatch = chatAssistanceEnabled && hasActivities;
  const [resourceId, setResourceId] = useState(currentActivity?.uuid);
  const stepCount = 2;
  const [step, setStep] = useState(hasActivities ? 0 : 1);
  const { categories } = useCategories();

  // i believe learner selectable tags were for Saskpoly... are they still needed for anything? - Q 2023-12-15
  const learnerSelectableTagsType = settings?.features?.learnerSelectableTagsType ?? null;
  const learnerSelectableTags = currentUser.getTagsBySettings(["learnerSelectable"]);

  const firstActivity =
    (recentActivities?.length > 0 && recentActivities[0]) ||
    (selectableActivities &&
      Object.values(selectableActivities).flatMap(group => group.activities)[0]);

  const activityIdValue = currentActivity?.uuid || (isAIOnly ? firstActivity?.uuid : "") || "";

  const requestReasonPlaceholderText = `To get the most effective assistance, describe your problem in detail, as well as what you have already tried to solve it.${
    showEnhancedRequestPrompt
      ? " Remember to include any code snippets or error messages you are encountering here as well!"
      : ""
  }`;

  const initialValues = {
    selectedTags: [],
    activityId: activityIdValue,
    categoryId: "",
    reason: "",
    pods,
  };

  const validateActivityId = selectableActivities => {
    return Yup.string().test(
      "no-bot-check",
      "Larry is disabled for this activity as it is an assessment. You will be able to request assistance from mentors about technical questions during mentoring hours.",
      value =>
        !isAIOnly ||
        !Object.values(selectableActivities)
          .flatMap(day => day.activities)
          .find(activity => activity.uuid === value)
          ?.tags.includes("no-bot")
    );
  };

  const requestSchema = Yup.object().shape({
    activityId: validateActivityId(selectableActivities),
    selectedTags: Yup.array().of(
      Yup.object().shape({
        id: Yup.number(),
        name: Yup.string(),
      })
    ),
    categoryId: Yup.number().required("Please select a category"),
    reason: Yup.string()
      .required("Please describe the reason for your request")
      .min(MINIMUM_DESCRIPTION_LENGTH, "Please include more detail in your description"),
  });

  const nextStep = event => {
    event.preventDefault();
    setStep(step + 1);
  };
  const prevStep = event => {
    event.preventDefault();
    setStep(step - 1);
  };

  const updateActivityId = (id, setFieldValue) => {
    setFieldValue("activityId", id);
    setResourceId(id);
  };

  return (
    <Formik
      initialValues={initialValues}
      initialTouched={{ activityId: isAIOnly }}
      validationSchema={requestSchema}
      onSubmit={onSubmit}
      validateOnMount={isAIOnly}
    >
      {({ isSubmitting, touched, errors, setFieldValue }) => {
        const isButtonDisabled = !!errors.activityId && touched.activityId;

        const renderTooltip = props => (
          <Tooltip id="button-tooltip" {...props}>
            {isButtonDisabled && "Please select a different activity"}
          </Tooltip>
        );

        const nextButton = (
          <Button onClick={nextStep} disabled={isButtonDisabled}>
            {step === 0 ? "New Request..." : "Next"}
          </Button>
        );

        return (
          <>
            {hasActivities && (
              <div className="step-indicator-container">
                <StepIndicator step={step} stepCount={stepCount} />
              </div>
            )}
            <ErrorList touched={touched} errors={_.omit(errors, "activityId")} />
            <Form>
              {step === 0 && hasActivities && (
                <>
                  <RequestField
                    name="activityId"
                    label="Which activity do you need help with?"
                    component={ActivitySelectInput}
                    selectableActivities={selectableActivities}
                    recentActivities={recentActivities}
                    onChange={e => updateActivityId(e.target.value, setFieldValue)}
                    isAIOnly={isAIOnly}
                  />
                  {canMatch && resourceId !== "other" && (
                    <SuggestedAssistanceRequestList
                      hide={hide}
                      id="suggested-ars-list"
                      resourceUuid={resourceId}
                      pods={pods}
                    />
                  )}
                </>
              )}
              {step === 1 && (
                <>
                  {!isEmpty(learnerSelectableTags) && learnerSelectableTagsType && (
                    <RequestField
                      name="selectedTags"
                      label="What does this request pertain to?"
                      component={TagSelectInput}
                      selectableTags={learnerSelectableTags}
                      isMulti={learnerSelectableTagsType === "multi"}
                    />
                  )}
                  <RequestField
                    name="categoryId"
                    label="What kind of assistance are you looking for?"
                    component={OptionButtonInput}
                    options={categories}
                  />
                  <RequestField
                    name="reason"
                    label="What do you need assistance with?"
                    as="textarea"
                    rows={4}
                    placeholder={requestReasonPlaceholderText}
                  />
                  {showOptionalRequestInputs && (
                    <>
                      <RequestField
                        name="requestCode"
                        label="If applicable, paste your relevant code snippet here"
                        as="textarea"
                        placeholder="Paste the code you're working on here..."
                        rows={4}
                      />
                      <RequestField
                        name="requestErrorMessage"
                        label="If applicable, paste your error message(s) here"
                        as="textarea"
                        placeholder="Paste the error message you're encountering here..."
                        rows={4}
                      />
                    </>
                  )}
                </>
              )}
              <div className="step-button-container">
                {hasActivities && step > 0 ? (
                  <Button onClick={prevStep} variant="secondary">
                    Back
                  </Button>
                ) : (
                  <div />
                )}
                {step === stepCount - 1 ? (
                  <Button type="submit" disabled={isSubmitting}>
                    Submit
                  </Button>
                ) : isButtonDisabled ? (
                  <OverlayTrigger
                    placement="right"
                    delay={{ show: 250, hide: 400 }}
                    overlay={renderTooltip}
                  >
                    <span className="d-inline-block">{nextButton}</span>
                  </OverlayTrigger>
                ) : (
                  nextButton
                )}
              </div>
            </Form>
          </>
        );
      }}
    </Formik>
  );
};

export default RequestForm;
