import TimeAgoWithTooltip from "Components/TimeAgoWithTooltip";
import Avatar from "Components/Avatar";
import { useAppContext } from "Context/AppProvider";
import { generateProfileUrl } from "util/user";

const TeacherInfo = ({ assistor, when }) => {
  const {
    currentUser,
    teacherHandler: { isTeacherOnDuty, isTeacherBusy },
  } = useAppContext();

  return (
    <div className="assistor clearfix">
      <Avatar
        to={generateProfileUrl({ currentUser, userId: assistor.id })}
        src={assistor.avatarUrl}
        busy={isTeacherBusy(assistor.uid)}
        onDuty={isTeacherOnDuty(assistor.uid)}
        name={assistor.fullName}
        assistor
      />
      <div className="name">
        {assistor.fullName} is assisting
        <br />
        {when && <TimeAgoWithTooltip when={when} />}
      </div>
    </div>
  );
};

export default TeacherInfo;
