import { useEffect, useReducer, useState, useCallback } from "react";
import useNotifications from "Hooks/useNotifications";
import useSocketConnection from "Hooks/useSocketConnection";
import useWebPushUpdates from "Hooks/useWebPushUpdates";
import { useLoadingContext } from "Context/LoadingProvider";
import requests from "Requests";
import { isBrowserExtension, isEmbedded } from "util/env";
import { storageGet, storageSet } from "BrowserExtension/utils/storage";
import { snakeCaseKeys } from "util/string";

// TODO: should remove refresh and rejected when working on issue #1134
const initialState = {
  rejected: false,
  refresh: 0,
  connected: false,
  pendingFeedbackCount: 0,
  userUpdates: [],
  requestUpdates: [],
  queueUpdates: [],
  teacherUpdates: [],
  studentUpdates: [],
  pendingArs: [],
  feedbackUpdates: [],
  errors: [],
};

const socketMessageHandlers = {
  queueUpdate: (state, updates) => ({ ...state, queueUpdates: [...updates] }),
  teacherUpdate: (state, updates) => ({ ...state, teacherUpdates: [...updates] }),
  studentUpdate: (state, updates) => ({ ...state, studentUpdates: [...updates] }),
  requestUpdate: (state, updates) => ({
    ...state,
    requestUpdates: [...state.requestUpdates, ...updates],
  }),
  pendingArUpdate: (state, updates) => ({ ...state, pendingArs: updates }),
  feedbackCountUpdate: (state, updates) => ({
    ...state,
    pendingFeedbackCount: updates[updates.length - 1].object,
  }),
  feedbackUpdate: (state, updates) => ({
    ...state,
    feedbackUpdates: [...state.feedbackUpdates, updates[updates.length - 1].object],
  }),
  userUpdate: (state, updates) => ({ ...state, userUpdates: [...state.userUpdates, ...updates] }),
  refresh: state => ({ ...state, queueUpdates: [], refresh: new Date() }),
  errorUpdate: (state, updates) => ({ ...state, errors: updates.map(u => u.object) }),
};

const queueChannelReducer = (state, action) => {
  const currentTime = new Date();

  const handleSocketMessage = (type, object) => {
    logSocketMessage(type, object);
    const updates = Array.isArray(object) ? object : [{ object }];

    return type === "pendingArUpdate" || (updates.length > 0 && socketMessageHandlers[type])
      ? socketMessageHandlers[type](state, updates)
      : state;
  };

  switch (action.type) {
    case "connect":
      return { ...state, connected: true };
    case "rejected":
      return { ...state, rejected: true };
    case "socketMessage": {
      const { object, type } = action.data;
      return handleSocketMessage(type, object);
    }
    case "web-push-updates": {
      const { type, object } = JSON.parse(action.data);
      return handleSocketMessage(type, object);
    }
    case "refresh":
      return currentTime - state.refresh > 1000
        ? { ...state, queueUpdates: [], refresh: currentTime }
        : state;
    default:
      throw new Error("Reducer failed to match action.type");
  }
};

const useQueueChannel = () => {
  const [authStatus, setAuthStatus] = useState("dropped");
  const [isFetchingStudentData, setIsFetchingStudentData] = useState();
  const { isLoading, setIsLoading, setIsFirstLoad, setDepsLoaded } = useLoadingContext();
  const [queueChannel, dispatchQueueChannel] = useReducer(queueChannelReducer, initialState);
  const { notificationHandler } = useNotifications();
  const socketConnection = useSocketConnection(
    "NationalQueueChannel",
    process.env.REACT_APP_WS_SERVER
  );

  const webPushUpdates = useWebPushUpdates();

  const setInitialStateLoad = data => {
    const validTypes = new Set(["userUpdate", "requestUpdate", "feedbackCountUpdate"]);

    if (validTypes.has(data.type)) {
      setDepsLoaded(state => ({ ...state, [`${data.type}d`]: true }));
    }
  };

  useEffect(() => {
    if (isBrowserExtension()) {
      const storeChanged = changes => {
        for (let [key, { newValue }] of Object.entries(changes)) {
          if (key === "authStatus") setAuthStatus(newValue);
        }
      };
      chrome.storage.onChanged.addListener(storeChanged);
      return () => chrome.storage.onChanged.removeListener(storeChanged);
    }
  }, []);

  useEffect(() => {
    const dispatchMessage = data => {
      setInitialStateLoad(data);
      dispatchQueueChannel({ type: "socketMessage", data });
    };

    socketConnection.subscribeToReceived([dispatchMessage, notificationHandler]);
    socketConnection.subscribeToRejected(() => {
      dispatchQueueChannel({ type: "rejected" });
    });
  }, [notificationHandler]);

  useEffect(() => {
    if (socketConnection.connected) {
      socketConnection.socket.perform("assistance_request_state"); // This is for fetching student-specific details, for the most part.
      dispatchQueueChannel({ type: "connect", data: socketConnection }); // This will cause a getTasks ie a full queue refresh for teachers.
    }
    // need to dispatch so queueChannel.connected is true for the rest of the UI to work properly, this should change when refactoring this hook - W
    if (isBrowserExtension()) dispatchQueueChannel({ type: "connect" });
  }, [socketConnection.connected]);

  const getStudentData = useCallback(async () => {
    setIsFetchingStudentData(true);

    try {
      const [ar, count] = await Promise.all([
        requests.getOngoingAssistanceRequest(),
        requests.getPendingFeedbackCount(),
      ]);

      dispatchQueueChannel({
        type: "socketMessage",
        data: { type: "requestUpdate", object: ar },
      });
      dispatchQueueChannel({
        type: "socketMessage",
        data: { type: "feedbackCountUpdate", object: count },
      });
    } catch (error) {
      console.error("Error fetching student data:", error);
    } finally {
      const data = await storageGet("isFirstAppLoad");

      if (data?.isFirstAppLoad) setIsFirstLoad(false);
      setIsLoading(false);
      setIsFetchingStudentData(false);
      setDepsLoaded(state => ({
        ...state,
        requestUpdated: true,
        feedbackCountUpdated: true,
      }));
    }
  }, [setDepsLoaded, setIsFirstLoad, setIsLoading]);

  useEffect(() => {
    let isComponentUnmounted = false;

    const fetchCurrentUser = async () => {
      try {
        const user = await requests.getCurrentUser();
        if (isComponentUnmounted || !user) return;

        storageSet({
          currentUser: JSON.stringify(user),
          authStatus: "logged-in",
        });
        dispatchQueueChannel({
          type: "socketMessage",
          data: { type: "userUpdate", object: user },
        });
        setAuthStatus("logged-in");
        getStudentData();
      } catch (error) {
        console.error("Error fetching current user:", error);
      } finally {
        if (!isComponentUnmounted) {
          setDepsLoaded(state => ({ ...state, userUpdated: true }));
        }
      }
    };

    fetchCurrentUser();

    return () => {
      isComponentUnmounted = true;
    };
  }, [setDepsLoaded, getStudentData]);

  useEffect(() => {
    if (isBrowserExtension()) {
      if (authStatus !== "logged-in") return;

      const interval = setInterval(() => {
        if (isFetchingStudentData) return;
        getStudentData();
      }, 5000);

      return () => clearInterval(interval);
    }
  }, [authStatus, isLoading, setIsLoading, isFetchingStudentData, getStudentData]);

  useEffect(() => {
    if (webPushUpdates) {
      dispatchQueueChannel({ type: "web-push-updates", data: webPushUpdates });
      notificationHandler(webPushUpdates);
    }
  }, [webPushUpdates, notificationHandler]);

  // COMMENT: when using react-query, it does auto update on page focus/click. (Wagner)
  // cause queue data to be reloaded whenever tab is focused
  /* TODO: uncomment this section once our queue route is resolving faster and we're ready to try reload on focus again - Q 2022-05-02

  useEffect(() => {
    const refreshQueueData = () => dispatchQueueChannel({ type: "refresh" });
    // return focusManager.setup(refreshQueueData);
  }, [dispatchQueueChannel]);
  */

  /**
   * Functions that emit socket messages to the server.
   */

  const requestAssistance = async ({
    reason,
    activity = {},
    categoryId,
    selectedTags,
    pods = [],
    route = true,
    isAIOnly = null,
    requestCode,
    requestErrorMessage,
    platform,
  }) => {
    const getCurrentARSource = () => {
      if (isBrowserExtension()) return "extension:chrome";
      if (isEmbedded()) return "web:compass";
      return "web:rudder";
    };

    const request = {
      reason,
      categoryId,
      pods,
      resourceUuid: activity.uuid,
      resourceLink: activity.link,
      resourceName: activity.name,
      resourceType: activity.type,
      intake_source: getCurrentARSource(),
      route,
      isAIOnly,
      info: { day: activity.day, tags: activity.tags, platform },
      selected_tags: selectedTags,
      requestCode,
      requestErrorMessage,
    };

    try {
      const assistanceRequest = await requests.createAssistanceRequest({ request });
      dispatchQueueChannel({
        type: "socketMessage",
        data: { type: "requestUpdate", object: assistanceRequest },
      });
      return assistanceRequest;
    } catch (error) {
      console.error("Error creating assistance request:", error);
    }
  };

  const cancelAssistanceRequest = async ({ id, cancellationReason, cancellationNotes }) => {
    const assistanceRequest = await requests.cancelAssistanceRequest(id, {
      cancellation: { reason: cancellationReason, notes: cancellationNotes },
    });
    dispatchQueueChannel({
      type: "socketMessage",
      data: { type: "requestUpdate", object: assistanceRequest },
    });
  };

  const claimAssistance = async ar => {
    const assistanceRequest = await requests.claimAssistanceRequest(ar.id);
    dispatchQueueChannel({
      type: "socketMessage",
      data: { type: "requestUpdate", object: assistanceRequest },
    });
  };
  const startAssistance = async ar => {
    const assistanceRequest = await requests.startAssistance(ar.id);
    dispatchQueueChannel({
      type: "socketMessage",
      data: { type: "requestUpdate", object: assistanceRequest },
    });
  };

  const cancelAssistance = async request => {
    const assistanceRequest = await requests.cancelAssistance(request.id);
    dispatchQueueChannel({
      type: "socketMessage",
      data: { type: "requestUpdate", object: assistanceRequest },
    });
  };

  const finishAssistance = async ({ id, request_id, assistance_id }) => {
    const assistanceRequest = await requests.finishAssistance(id, {
      finish: { request_id, assistance_id },
    });
    dispatchQueueChannel({
      type: "socketMessage",
      data: { type: "requestUpdate", object: assistanceRequest },
    });
  };

  const closeAssistance = async id => {
    const assistanceRequest = await requests.closeAssistanceRequest(id);
    dispatchQueueChannel({
      type: "socketMessage",
      data: { type: "requestUpdate", object: assistanceRequest },
    });
  };

  const finishRequest = async ar => {
    const assistanceRequest = await requests.finishAssistanceRequest(ar.id);
    dispatchQueueChannel({
      type: "socketMessage",
      data: { type: "requestUpdate", object: assistanceRequest },
    });
  };

  const reviewAssistance = async feedbackData => {
    for (const data of Object.values(feedbackData)) {
      const assistanceRequest = await requests.reviewAssistance(data);
      dispatchQueueChannel({
        type: "socketMessage",
        data: { type: "requestUpdate", object: assistanceRequest },
      });
    }
  };

  const provideManualAssistance = async (student, assistanceDetails) => {
    const assistanceRequest = await requests.provideManualAssistance(
      student,
      snakeCaseKeys(assistanceDetails)
    );
    dispatchQueueChannel({
      type: "socketMessage",
      data: { type: "requestUpdate", object: assistanceRequest },
    });
  };

  const prioritizeRequest = async assistanceRequestId => {
    const assistanceRequest = await requests.prioritizeRequest(assistanceRequestId);
    dispatchQueueChannel({
      type: "socketMessage",
      data: { type: "requestUpdate", object: assistanceRequest },
    });
    return assistanceRequest.priority;
  };

  const provideFeedback = async (task, feedback) => {
    const attitudeKeywords = feedback.attitudeKeywords || [];
    const technicalKeywords = feedback.technicalKeywords || [];

    const data = await requests.createFeedback({
      feedback: {
        task_id: task.id,
        ...snakeCaseKeys(feedback),
        keywords: JSON.stringify([...attitudeKeywords, ...technicalKeywords]),
      },
    });
    dispatchQueueChannel({
      type: "socketMessage",
      data: { type: "queueUpdate", object: data.queueUpdate },
    });
    dispatchQueueChannel({
      type: "socketMessage",
      data: { type: "feedbackUpdate", object: data.queueUpdate },
    });
  };

  const toggleDuty = user => requests.toggleDuty({ toggle: { user_uid: user.uid } });

  const sendUserActive = () => socketConnection.socket.perform("user_active");

  const perform = (action, data) => {
    logSocketMessage(`outgoing - ${action}`, data);
    socketConnection.socket.perform(action, data);
  };

  return {
    requestAssistance,
    cancelAssistanceRequest,
    claimAssistance,
    startAssistance,
    cancelAssistance,
    finishAssistance,
    reviewAssistance,
    finishRequest,
    closeAssistance,
    provideManualAssistance,
    prioritizeRequest,
    provideFeedback,
    toggleDuty,
    sendUserActive,
    connected: queueChannel.connected,
    refresh: queueChannel.refresh,
    rejected: queueChannel.rejected,
    userUpdates: queueChannel.userUpdates,
    requestUpdates: queueChannel.requestUpdates,
    queueUpdates: queueChannel.queueUpdates,
    pendingArs: queueChannel.pendingArs,
    teacherUpdates: queueChannel.teacherUpdates,
    studentUpdates: queueChannel.studentUpdates,
    pendingFeedbackCount: queueChannel.pendingFeedbackCount,
    feedbackUpdates: queueChannel.feedbackUpdates,
    errors: queueChannel.errors,

    // make socket functions available
    subscribeToConnect: socketConnection.subscribeToConnect,
    subscribeToDisconnect: socketConnection.subscribeToDisconnect,
    subscribeToReceived: socketConnection.subscribeToReceived,
    subscribeToRejected: socketConnection.subscribeToRejected,
    perform,
  };
};

function logSocketMessage(type, object) {
  const hostname = window.location.hostname;
  if (hostname.includes("local") || hostname.includes("staging")) {
    console.log(`SOCKET: [${type}]: `, object);
  }
}

export default useQueueChannel;
