import { useState } from "react";
import { useAppContext } from "Context/AppProvider";
import useUser from "Hooks/useUser";
import Avatar from "Components/Avatar";
import NotFound from "Components/NotFound";
import Splash from "Components/Splash";
import PaddedContent from "Layouts/PaddedContent";
import Tabs from "Components/Tabs";
import Info from "./Info";
import Tags from "./Tags";
import Assistances from "./Assistances";

const ProfileView = () => {
  const { currentUser } = useAppContext();
  const [showTab, setShowTab] = useState("info");
  const { user, userDetails, applyTag, removeTag } = useUser(currentUser.id, currentUser.isStudent);

  const Tab = ({ tab }) => {
    switch (tab) {
      case "info":
        return <Info user={user} userDetails={userDetails} />;
      case "tags":
        return <Tags user={user} applyTag={applyTag} removeTag={removeTag} />;
      case "assistances":
        return <Assistances uid={user.uid} />;
    }
  };

  const onChangeTab = tab => setShowTab(tab);

  if (Object.keys(user).length < 1) {
    return <Splash />;
  } else if (user.error) {
    if (user.error.response && user.error.response.status === 404) return <NotFound />;
    return <p>{user.error.toString()}</p>;
  }

  return (
    <div className="d-flex flex-column">
      <PaddedContent>
        <h1>
          <Avatar src={user.avatarUrl} busy={user.busy} onDuty={user.onDuty} name={user.fullName} />
          {`${user.fullName}'s Profile`}
        </h1>
        <Tabs tab={showTab} tabs={["info", "tags", "assistances"]} onChangeTab={onChangeTab}>
          <Tab tab={showTab} />
        </Tabs>
      </PaddedContent>
    </div>
  );
};

export default ProfileView;
