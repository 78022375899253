import SettingsConditional from "Components/SettingsConditional";
import useCategories from "Hooks/useCategories";
import useQueueWaitTime from "Hooks/useQueueWaitTime";
import { QueueWaitTableRows, QueueWaitAlerts } from "Components/QueueWaitTime";
import "./ViewRequestModal.scss";
import { STATES } from "constants/assistanceRequest";

const ViewRequest = ({ statusText, assistanceRequest, queueClosed }) => {
  const { resourceName: activityName, resourceLink: activityLink } =
    assistanceRequest.request || {};

  const activityText =
    activityLink && activityName ? (
      <a href={activityLink} target="_blank" rel="noreferrer">
        {activityName}
      </a>
    ) : (
      activityName
    );

  const isRequestClosed = assistanceRequest.state === STATES.CLOSED_STATE;
  const showQueueWaitTime =
    assistanceRequest.queueWaitTimeApiUrl && !isRequestClosed && !queueClosed;

  const { category } = useCategories(assistanceRequest.request.categoryId);

  const {
    queuePosition,
    formattedWaitTime,
    errorMessage: waitTimeError,
    loading: waitTimeLoading,
    longWait,
    maxWaitHours,
  } = useQueueWaitTime(
    assistanceRequest.id,
    assistanceRequest.queueWaitTimeApiUrl,
    assistanceRequest.queueMaxWaitTime
  );

  return (
    <>
      <h3>Request Details:</h3>
      {showQueueWaitTime && (
        <SettingsConditional flag={SettingsConditional.QUEUE_WAIT_TIME_UI}>
          <QueueWaitAlerts
            errorMessage={waitTimeError}
            alerts={
              longWait
                ? [
                    {
                      variant: "warning",
                      message: `Sorry, all of our mentors are currently assisting other students, and you could be waiting for more than ${maxWaitHours} hour${
                        maxWaitHours > 1 ? "s" : ""
                      }.`,
                    },
                  ]
                : []
            }
          />
        </SettingsConditional>
      )}
      <table className="table table-sm table-bordered">
        <tbody>
          <SettingsConditional flag={SettingsConditional.QUEUE_WAIT_TIME_UI}>
            <QueueWaitTableRows
              rows={[
                {
                  label: "Estimated Wait Time",
                  value: formattedWaitTime,
                },
                {
                  label: "Queue Position",
                  value: queuePosition,
                },
              ]}
              loading={waitTimeLoading}
              show={showQueueWaitTime && waitTimeError === ""}
            />
          </SettingsConditional>
          <tr>
            <th className="aligned">Category</th>
            <td>{category?.displayText}</td>
          </tr>
          <tr>
            <th className="aligned">Reason</th>
            <td>{assistanceRequest.request.reason}</td>
          </tr>
          <tr>
            <th className="aligned">Status</th>
            <td>{statusText}</td>
          </tr>
          {activityText && (
            <tr>
              <th className="aligned">Activity</th>
              <td>{activityText}</td>
            </tr>
          )}
        </tbody>
      </table>
      <SettingsConditional flag={SettingsConditional.CHAT_ASSISTANCE}>
        <span className="blurb">Your assistance will take place in Discord.</span>
      </SettingsConditional>
    </>
  );
};

export default ViewRequest;
