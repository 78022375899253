import ModalButton from "Components/Buttons/ModalButtons";
import useLocations from "Hooks/useLocations";
import { Table } from "react-bootstrap";
import { Link } from "react-router-dom";

const Info = ({ user, userDetails }) => {
  const { locations } = useLocations();
  const userLocation = locations.filter(location => location.id === userDetails.locationId)[0];

  const userInfoRow = (label, data) => (
    <tr>
      <td>{label}</td>
      <td>{data}</td>
    </tr>
  );

  return (
    <div>
      <Table bordered>
        <tbody>
          {userInfoRow("Name", user.fullName)}
          {userInfoRow("Email", user.socials?.email)}
          {userInfoRow(
            "Github",
            <a href={`https://github.com/${user.socials?.github}`} target="_blank" rel="noreferrer">
              {user.socials?.github}
            </a>
          )}
          {userInfoRow("Pronoun", user.pronoun)}
          {userInfoRow("Phone Number", userDetails.phoneNumber)}
          {userInfoRow("Location", userLocation?.name)}
          {userInfoRow("Bio", userDetails.bio)}
          {userInfoRow("Quirky Fact", userDetails.quirkyFact)}
          {userInfoRow(
            "Company",
            userDetails.companyurl ? (
              <a href={userDetails.companyUrl} target="_blank" rel="noreferrer">
                {userDetails.companyName}
              </a>
            ) : (
              userDetails.companyName
            )
          )}
        </tbody>
      </Table>
      <div style={{ display: "flex", justifyContent: "space-around" }}>
        <Link to="/profile/edit">
          <ModalButton.Outline text="Edit Your Profile" />
        </Link>
        <Link to="/queue">
          <ModalButton.Outline type="secondary" text="Visit Your Queue" />
        </Link>
      </div>
    </div>
  );
};

export default Info;
