import { useState } from "react";
import ListGroup from "Components/ListGroup";
import Student from "Components/Student";
import ListFilter from "Components/ListFilter";
import { filteredStudents } from "Selectors/queue_selectors";

const StudentsList = ({ students, displayByDefault }) => {
  const [searchQuery, setSearchQuery] = useState("");

  return (
    <ListGroup icon={students.length} title="Students" className="learners">
      <ListFilter
        query={searchQuery}
        onChange={setSearchQuery}
        placeholder="Search by first or last name..."
      />
      {(displayByDefault || searchQuery) &&
        filteredStudents(students, searchQuery).map(student => (
          <Student key={`student-${student.uid}`} student={student} />
        ))}
    </ListGroup>
  );
};

export default StudentsList;
