import PaddedContent from "Layouts/PaddedContent";
import Attribution from "./Attribution";

const Attributions = ({ attributions, onDelete }) => {
  const count = attributions.length;

  return (
    <PaddedContent>
      <div className={`list-group card card-default open`}>
        <div className="card-header">
          <h4 className="card-title">
            <span className="count">{count}</span>
            <span className="title">Attributions</span>
          </h4>
        </div>
        <ul className="list-group list-group-flush">
          {attributions.map(attr => (
            <Attribution onDelete={onDelete} attribution={attr} key={`attr-${attr.id}`} />
          ))}
        </ul>
      </div>
    </PaddedContent>
  );
};

export default Attributions;
