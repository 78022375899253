import { useState } from "react";
import QueueItem from "Components/QueueItem";
import StudentInfo from "Components/StudentInfo";
import ManualAssistanceModal from "Components/ManualAssistanceModal";
import Button from "./Buttons/AssistanceButtons";

const Student = ({ student }) => {
  const [showModal, setShowModal] = useState(false);

  const openModal = () => {
    setShowModal(true);
  };

  return (
    <QueueItem type="Student">
      <StudentInfo student={student} when={student.lastAssistedAt} showDetails={true} />

      <div className="actions float-right">
        <Button.Manual onClick={openModal} text="Log an Assistance" />
      </div>

      {showModal && <ManualAssistanceModal hide={() => setShowModal(false)} student={student} />}
    </QueueItem>
  );
};

export default Student;
