export default (axios, errorHandler) => {
  const signOut = () => {
    return axios
      .delete(`/auth/sign-out`)
      .then(response => {
        return response.data;
      })
      .catch(err => {
        errorHandler(err);
      });
  };

  return {
    signOut,
  };
};
