import InputField from "Components/Form/InputField";

const AssistanceField = props => <InputField prefix="assistance" {...props} />;

const PreRequestReviewQuestions = ({ preRequestQuestions, companyQuestions, optOut }) => {
  return (
    <>
      {preRequestQuestions && (
        <AssistanceField
          name="studentPreRequestEffort"
          label="How hard did the learner work to resolve the task before filing an AR?"
          as="select"
          disabled={optOut}
        >
          <option value="" selected="selected" disabled>
            Please select an option...
          </option>
          <option value="no_thought">They barely thought about the task</option>
          <option value="some_thought">
            They thought about it, but didn't know where to start
          </option>
          <option value="got_stuck">They started but got stuck before finishing a solution</option>
          <option value="broken_solution">They completed a solution but it didn't work</option>
          <option value="imperfect_solution">
            They had a working solution they wanted to improve
          </option>
        </AssistanceField>
      )}
      {companyQuestions && (
        <AssistanceField
          name="requestSpecificity"
          label="How much was the request about general tech skills vs. something specific to their company/stack?"
          as="select"
          disabled={optOut}
        >
          <option value="" selected="selected" disabled>
            Please select an option...
          </option>
          <option value="1">Entirely general skills</option>
          <option value="2">Mostly general skills</option>
          <option value="3">Mostly company-specific</option>
          <option value="4">Entirely company-specific</option>
        </AssistanceField>
      )}
    </>
  );
};

export default PreRequestReviewQuestions;
