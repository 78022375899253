import { Alert } from "react-bootstrap";

const InviteError = ({ errorType }) => {
  return (
    <Alert variant="danger">
      <h3>
        <i className="fa fa-fw fa-circle-exclamation" />
        &nbsp;Invite Error
      </h3>
      <hr />
      {errors[errorType]}
    </Alert>
  );
};

const errors = {
  not_found: (
    <>
      <p>Could not create account: invite token missing or invalid.</p>
      <span>Please contact an administrator for a new invite.</span>
    </>
  ),
  expired: (
    <>
      <p>Could not create account: invite token already used.</p>
      <span>Please contact an administrator for a new invite.</span>
    </>
  ),
  user_exists: (
    <>
      <p>Could not use invite: your Github profile is already linked to a Rudder account.</p>
      <span>Please use the button below to sign in normally.</span>
    </>
  ),
  unexpected: (
    <>
      <p>Could not use invite: an unexpected error occurred.</p>
      <span>Please contact an administrator to let them know about this.</span>
    </>
  ),
};

export default InviteError;
