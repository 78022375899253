import { useEffect } from "react";
import { toast } from "react-toastify";
import { TOAST_OPTIONS } from "constants/toast";
import { ERROR_MESSAGE_TYPES } from "constants/errors";
import { useAppContext } from "Context/AppProvider";
import { ArCreateFail } from "Components/ToastMessages";

const errorMessages = {
  [ERROR_MESSAGE_TYPES.arCreateFail]: <ArCreateFail />,
};

// simple component for rendering errors received by useQueueChannel
const ErrorHandler = () => {
  const {
    queueChannel: { errors },
  } = useAppContext();

  useEffect(() => {
    errors.forEach(err => {
      toast.error(errorMessages[err.type] || err.message, TOAST_OPTIONS.error);
    });
  }, [errors]);

  // doesn't need to render anything on its own
  return null;
};

export default ErrorHandler;
