import ModalBackdrop from "./ModalBackdrop";
import StudentInfo from "Components/StudentInfo";
import ManualAssistanceForm from "Components/ManualAssistanceForm";
import { useAppContext } from "Context/AppProvider";
import { toast } from "react-toastify";
import { TOAST_OPTIONS } from "constants/toast";
import Modal from "./Modal";

const ManualAssistanceModal = ({ student, hide }) => {
  const { queueChannel } = useAppContext();

  const logAssistance = formData => {
    queueChannel.provideManualAssistance(student, formData).catch(err => {
      toast.error(err.response?.data?.error, TOAST_OPTIONS.error);
    });
    hide();
  };

  return (
    <ModalBackdrop>
      <Modal title="Log Assistance Manually" closeModal={hide}>
        <div>
          <b>Feedback for student:</b>
          <StudentInfo student={student} when={student.lastAssistedAt} showDetails={true} />
        </div>
        <ManualAssistanceForm student={student} onSubmit={logAssistance} />
      </Modal>
    </ModalBackdrop>
  );
};

export default ManualAssistanceModal;
