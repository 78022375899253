import classNames from "classnames";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const QueueSelector = ({ teachers, selectedTeacher, selectTeacher }) => {
  return (
    <div className={`card card-default queue-selector open mb-3`}>
      <div className="card-header-back"></div>
      <div className="card-header-border"></div>
      <div className="card-header">
        <h4 className="card-title">
          <span className="count">{teachers.length}</span>
          <span className="title">Mentors</span>
        </h4>
        <div className="options">
          <OverlayTrigger placement="bottom" overlay={<Tooltip>National Queue</Tooltip>}>
            <div
              className={classNames("option", "all", {
                selected: !selectedTeacher,
              })}
              onClick={() => selectTeacher(null)}
            >
              <i className="fa-solid fa-globe" />
            </div>
          </OverlayTrigger>
          <div className="mentors">
            {teachers.map(teacher => {
              return (
                <OverlayTrigger
                  key={teacher.id}
                  placement="bottom"
                  overlay={<Tooltip>{teacher.fullName}</Tooltip>}
                >
                  <img
                    key={teacher.id}
                    alt={teacher.fullName}
                    onClick={() => selectTeacher(teacher)}
                    src={teacher.avatarUrl}
                    className={classNames("option", "mentor", {
                      busy: teacher.busy,
                      selected: selectedTeacher && selectedTeacher.id === teacher.id,
                    })}
                  />
                </OverlayTrigger>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default QueueSelector;
