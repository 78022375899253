import ModalBackdrop from "Components/ModalBackdrop";
import "Assets/styles/scrollable-modal.scss";
import { isBrowserExtension } from "util/env";
import { ModalTitle } from "Components/Typography/Heading";
import Spinner from "Components/Spinner";

const NoPendingFeedbacksModal = ({ hasPermission, noPermission, loading }) => {
  return (
    <ModalBackdrop>
      <div className="modal-dialog" style={{ marginTop: isBrowserExtension() ? "2rem" : "5rem" }}>
        {hasPermission && (
          <div className="modal-content">
            <div className="modal-header">
              <ModalTitle>Feedback already provided</ModalTitle>
            </div>
            <div className="modal-body">
              <p>Thanks for having provided feedback on this assistance!</p>
            </div>
          </div>
        )}
        {noPermission && (
          <div className="modal-content">
            <div className="modal-header">
              <ModalTitle>Feedback not available</ModalTitle>
            </div>
            <div className="modal-body">
              <p>You do not have permission to view this feedback.</p>
            </div>
          </div>
        )}
        {loading && (
          <div className="modal-content">
            <div className="modal-body" style={{ textAlign: "center", padding: "3rem" }}>
              <Spinner />
              <ModalTitle style={{ paddingTop: "1rem" }}>Loading ...</ModalTitle>
            </div>
          </div>
        )}
      </div>
    </ModalBackdrop>
  );
};

export default NoPendingFeedbacksModal;
