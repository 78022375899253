import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Alert } from "react-bootstrap";
import _ from "lodash";
import Requests from "Requests";
import PaddedContent from "Layouts/PaddedContent";
import MainNavigation from "Components/MainNavigation";
import ChatTranscriptMessage from "./ChatTranscriptMessage";
import "./ChatTranscript.scss";

const endPhrases = [
  "You can reply to me by clicking this button",
  "Here's a brand new recommendation for you",
  "Would you like me to dive into a new recommendation for you?",
];

const ChatTranscript = () => {
  const { id } = useParams();
  const [transcript, setTranscript] = useState(null);
  const [error, setError] = useState(null);
  const transcriptMessages = transcript?.chatAssistanceMessages;
  const sortedMessages = transcriptMessages?.slice().sort((a, b) => {
    const timestampA = new Date(a.timestamp);
    const timestampB = new Date(b.timestamp);
    return timestampA - timestampB;
  });
  let insideRecommendationRange = false;

  useEffect(() => {
    const fetchTranscript = async () => {
      try {
        const transcript = await Requests.getChatAssistanceTranscript(id);
        setTranscript(transcript);
      } catch (err) {
        setError(err?.response?.data?.error);
      }
    };

    fetchTranscript();
  }, [id]);

  const getDisplayName = (msg, messages) => {
    if (msg.role === "mentor") return "Mentor";
    if (msg.role !== "student") return msg.author;

    const uniqueStudentNames = _(messages)
      .filter(message => message.role === "student")
      .map("author")
      .uniq()
      .value();

    const studentNumber = uniqueStudentNames.indexOf(msg.author) + 1;

    return uniqueStudentNames.length === 1 ? "Student" : `Student ${studentNumber}`;
  };

  return (
    <main>
      <MainNavigation hideActions />
      <div className="chat-container">
        <h1 className="chat-title">{transcript?.threadName}</h1>
        {transcript && (
          <PaddedContent>
            {sortedMessages?.length > 0 &&
              sortedMessages.map((msg, i) => {
                if (msg.content.includes("Previous Similar Assistance"))
                  insideRecommendationRange = true;
                if (endPhrases.some(phrase => msg.content.includes(phrase)))
                  insideRecommendationRange = false;

                if (insideRecommendationRange) msg.role = "recommended-ar";

                return (
                  <ChatTranscriptMessage
                    key={`${i}-${msg.id}`}
                    message={{
                      ...msg,
                      author: getDisplayName(msg, sortedMessages),
                    }}
                    sameSender={i > 0 && msg.author === sortedMessages[i - 1].author}
                  />
                );
              })}
          </PaddedContent>
        )}
        {error && <Alert variant="danger">{error}</Alert>}
      </div>
    </main>
  );
};

export default ChatTranscript;
