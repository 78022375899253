export default (axios, errorHandler) => {
  const getExternalChannel = podUuid => {
    const params = { pod_uuid: podUuid };
    return axios
      .get("/external_channels", { params })
      .then(response => response.data)
      .catch(errorHandler);
  };

  return {
    getExternalChannel,
  };
};
