import { Route } from "react-router-dom";
import { ProtectedRoute } from "Components/Routers";
import New from "./Tags/New";
import Edit from "./Tags/Edit";
import Attributions from "./Attributions";
import Browse from "./Tags/Browse";

export default [
  <Route key="tags-new" path="/tags/new" element={<ProtectedRoute superAdmin component={New} />} />,
  <Route
    key="tags-edit"
    path="/tags/:id/edit"
    element={<ProtectedRoute superAdmin component={Edit} />}
  />,
  <Route
    key="tags-edit-attributions"
    path="/tags/:id/edit/attributions"
    element={<ProtectedRoute admin component={Attributions} />}
  />,
  <Route key="tags-index" path="/tags" element={<ProtectedRoute admin component={Browse} />} />,
];
