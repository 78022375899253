import { useState } from "react";
import Attributions from "./Attributions";
import AllUsers from "./AllUsers";
import { useParams, Link } from "react-router-dom";
import { Alert } from "react-bootstrap";
import useTag from "Hooks/useTag";
import Splash from "Components/Splash";
import NotFound from "Components/NotFound";
import { useAppContext } from "Context/AppProvider";
import PaddedContent from "Layouts/PaddedContent";

const AttributionManager = () => {
  const { id } = useParams();
  const { tag, addAttribution, deleteAttribution } = useTag(id);
  const { currentUser } = useAppContext();
  const alreadyTaggedUsers = tag.userAttributions
    ? tag.userAttributions.map(attr => attr.taggable[attr.taggable.type])
    : [];
  const [errorMsg, setErrorMsg] = useState("");

  // handle tag loading and loading related errors
  if (Object.keys(tag).length < 1) {
    return <Splash />;
  } else if (tag.error) {
    if (tag.error.response && tag.error.response.status === 404) {
      return <NotFound />;
    }
    return <p>{tag.error.toString()}</p>;
  }

  const handleAddAttribution = (user, onComplete) => {
    setErrorMsg("");
    addAttribution(user)
      .catch(err => {
        displayAxiosError(err.response);
      })
      .finally(onComplete);
  };

  const handleDeleteAttribution = (user, onError) => {
    setErrorMsg("");
    deleteAttribution(user).catch(err => {
      displayAxiosError(err.response);
      onError && onError();
    });
  };

  const displayAxiosError = response => {
    setErrorMsg(`${response.status} - ${response.statusText}: ${response.data.error}`);
  };

  return (
    <PaddedContent>
      {errorMsg && (
        <Alert variant="danger">
          <i className="fa fa-fw fa-circle-exclamation" />
          &nbsp;{errorMsg}
        </Alert>
      )}
      <Link to={currentUser.isSuperAdmin ? `/tags/${tag.id}/edit` : `/tags`}>
        <i className="fa fa-fw fa-arrow-left" />
      </Link>
      <h2>{tag.name}</h2>
      <div className={`row`}>
        <div className={`col-6`}>
          <Attributions
            attributions={tag.userAttributions || []}
            onDelete={handleDeleteAttribution}
          />
        </div>

        <div className={`col-6`}>
          <div className="card">
            <div className="card-header">
              <nav className="nav nav-tabs card-header-tabs">
                <p className="nav-item nav-link active">Users</p>
              </nav>
            </div>
            <AllUsers onAdd={handleAddAttribution} alreadyTaggedUsers={alreadyTaggedUsers} />
          </div>
        </div>
      </div>
    </PaddedContent>
  );
};

export default AttributionManager;
