import { useNavigate } from "react-router-dom";
import ResponsiveLabel from "Components/ResponsiveLabel";
import HoverBadge from "Components/HoverBadge";
import { Nav } from "react-bootstrap";
import { isBrowserExtension, inIframe } from "util/env";

const FeedbackButton = ({ count, nav }) => {
  const navigate = useNavigate();

  const toFeedbacks = () => {
    if (inIframe()) {
      window.open("/feedbacks", "_blank");
    } else if (
      process.env.REACT_APP_SERVER.includes(window.location.host) ||
      isBrowserExtension()
    ) {
      navigate("/feedbacks");
    } else {
      window.location.assign("/feedbacks/rudder");
    }
  };

  return (
    <>
      <li className="nav-item nav-item-feedback">
        <Nav.Link onClick={toFeedbacks} title="Feedback">
          <i className="fa fa-fw fa-comment" />
          {count !== 0 && <HoverBadge pill bg="danger" title={count} />}
          <ResponsiveLabel sizes={nav ? ["small", "xsmall"] : ["small", "medium", "large"]}>
            Feedback
          </ResponsiveLabel>
        </Nav.Link>
      </li>
    </>
  );
};

export default FeedbackButton;
