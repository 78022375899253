import TimeAgo from "Components/TimeAgo";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { DateTime } from "luxon";

const TimeAgoWithTooltip = ({ when }) => {
  const formattedDate = DateTime.fromISO(when).toFormat("ff");

  return (
    <OverlayTrigger placement="bottom" overlay={<Tooltip>{formattedDate}</Tooltip>}>
      <span>
        <TimeAgo date={when} />
      </span>
    </OverlayTrigger>
  );
};

export default TimeAgoWithTooltip;
