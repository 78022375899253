import { Route } from "react-router-dom";
import { ProtectedRoute } from "Components/Routers";
import Browse from "./Browse";
import Show from "./Show";
import Edit from "./Edit";
import Learner from "./Learner/Show";
import ProfileView from "./Edit/ProfileView";

export default [
  <Route key="users-show" path="/users/:id" element={<ProtectedRoute admin component={Show} />} />,
  <Route key="users-browse" path="/users" element={<ProtectedRoute admin component={Browse} />} />,
  <Route
    key="users-edit"
    path="/users/:id/edit"
    element={<ProtectedRoute admin component={Edit} />}
  />,
  <Route
    key="profile-view"
    path="/profile/view"
    element={<ProtectedRoute component={ProfileView} />}
  />,
  <Route key="profile-edit" path="/profile/edit" element={<ProtectedRoute component={Edit} />} />,
  <Route
    key="learner-show"
    path="/learners/:uid"
    element={<ProtectedRoute teacher component={Learner} />}
  />,
  <Route
    key="learner-show-tab"
    path="/learners/:uid/:tab"
    element={<ProtectedRoute teacher component={Learner} />}
  />,
];
