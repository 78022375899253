import classNames from "classnames";
import "./StepIndicator.scss";

const StepIndicator = ({ step, stepCount, ...props }) => {
  const stepDots = Array(stepCount)
    .fill()
    .map((_, i) => {
      return (
        <div
          key={i}
          className={classNames("step", {
            active: i === step,
          })}
        />
      );
    });

  return <div {...props}>{stepDots}</div>;
};

export default StepIndicator;
