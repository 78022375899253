import { Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import StudentInfo from "Components/StudentInfo";
import ModalButton from "Components/Buttons/ModalButtons";
import { useAppContext } from "Context/AppProvider";

const UsersList = ({ users = [], edit, isUpdating }) => {
  const { currentUser } = useAppContext();

  const access = user => {
    return (
      (user.access.includes("system-admin") && "system-admin") ||
      (user.access.includes("super-admin") && "super-admin") ||
      (user.access.includes("admin") && "admin") ||
      (user.access.includes("teacher") && "teacher") ||
      (user.access.includes("student") && "student") ||
      "user"
    );
  };
  const userTypes = ["user", "teacher", "admin", "super-admin", "system-admin"];

  const outRank = userLevel =>
    userLevel === -1 || currentUser.access.includes(userTypes[userLevel + 1]);

  const rows = users.map(user => {
    const usersAccess = access(user);
    const userLevel = userTypes.findIndex(access => access === usersAccess);
    return (
      <tr key={user.uid} className="queue-show">
        <td>
          <StudentInfo student={user} details={{ type: usersAccess }} showDetails nonLearner />
        </td>
        <td>
          <div className="edit-options">
            <Link
              to={`/users/${user.id}`}
              hidden={user.id !== currentUser.id && !outRank(userLevel)}
            >
              <ModalButton.Outline text="Manage Tags" />
            </Link>
            {outRank(userLevel) && (
              // using "Link" tag here causes React to handle the link instead of Rails, skipping the session controller
              <a href={`/impersonate/${user.id}`}>
                <ModalButton.Outline text="Impersonate" />
              </a>
            )}
            <Link
              to={`/users/${user.id}/edit`}
              hidden={user.id !== currentUser.id && !outRank(userLevel)}
            >
              <ModalButton.Outline text="Edit Profile" />
            </Link>
          </div>
        </td>
        <td>
          <div className="edit-options">
            {userLevel < 4 && (
              <ModalButton.Outline
                type="danger"
                text={`Promote to ${userTypes[userLevel + 1]}`}
                disabled={!outRank(userLevel) || isUpdating}
                onClick={() => edit(user, { type: userTypes[userLevel + 1] })}
              />
            )}
            {userLevel > 0 && (
              <ModalButton.Outline
                type="danger"
                text={`Demote to ${userTypes[userLevel - 1]}`}
                disabled={!outRank(userLevel) || isUpdating}
                onClick={() => edit(user, { type: userTypes[userLevel - 1] })}
              />
            )}
          </div>
        </td>
      </tr>
    );
  });

  return (
    <Table striped bordered hover>
      <thead>
        <tr>
          <th>User</th>
          <th></th>
          <th></th>
        </tr>
      </thead>
      <tbody>{rows}</tbody>
    </Table>
  );
};

export default UsersList;
