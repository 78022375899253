import { useEffect, useState } from "react";
import { throttle } from "lodash";
import notify from "util/notify";
import { DateTime } from "luxon";

// throttle user activity updates to once every ... ms
const ACTIVITY_UPDATE_INTERVAL_MS = 15000;
const eventsOfInterest = ["mousemove", "touchstart", "touchmove", "scroll", "keydown", "click"];
const socketMsgTypesOfInterest = ["idleWarning", "idleCleared", "teacherUpdate"];

export default function useUserActivity(socket, user) {
  const [showIdleAlert, setShowIdleAlert] = useState(false);
  const [forcedOffDuty, setForcedOffDuty] = useState(false);

  useEffect(() => {
    if (!socket.connected || !user.onDuty) return;

    // only send events every ACTIVITY_UPDATE_INTERVAL_MS
    const throttledSend = throttle(() => {
      socket.sendUserActive();

      setShowIdleAlert(false);
    }, ACTIVITY_UPDATE_INTERVAL_MS);

    // add listeners for common user events
    addEventListeners(throttledSend);

    // listen to socket messsages
    socket.subscribeToReceived([
      msg => {
        const { type, object, forced_off_duty, forcing_off_duty_at } = msg;
        const { onDuty, uid } = object ? object : { onDuty: undefined, uid: undefined };
        const idleOutTime = DateTime.fromISO(forcing_off_duty_at).toFormat("t");

        // ignore spurious socket messages
        if (!socketMsgTypesOfInterest.includes(type)) return;

        switch (type) {
          // if the teacher has been sent an warning that they've been
          // flagged as idle, display the warning
          case "idleWarning":
            setShowIdleAlert(forcing_off_duty_at);
            notify("Idle Warning", {
              body: `You've been marked idle due to inactivity and will be taken off-duty at ${idleOutTime}. To avoid going off-duty, please visit Rudder again to indicate that you're still active.`,
              icon: "images/logo.png",
            });
            break;
          // if the teacher has been flagged as idle but dismissed the
          // warning before they were marked as off duty, this message
          // would remove the warning on all clients/tabs they might
          // be logged-in on.
          case "idleCleared":
            setShowIdleAlert(false);
            break;
          // listen for the teacher going off duty to show the modal
          // that informs them that they have been marked off duty
          case "teacherUpdate":
            if (user.uid !== uid) return;

            if (forced_off_duty) {
              setForcedOffDuty(true);
              setShowIdleAlert(false);
            } else if (onDuty) {
              setForcedOffDuty(false);
              setShowIdleAlert(false);
            }
            break;
          // this should never happen
          default:
            console.error("Stange mismatched type bug.");
        }
      },
    ]);

    // remove event listeners on unmount
    return () => removeEventListeners(throttledSend);
  }, [socket.connected, user.onDuty]);

  return {
    getShowIdleAlertValue: () => showIdleAlert,
    hideIdleAlert: () => setShowIdleAlert(false),
    getShowForcedOffDutyAlertValue: () => forcedOffDuty,
    hideForcedOffDutyAlert: () => setForcedOffDuty(false),
  };
}

/**
 * Helper functions to add/remove event listeners
 */
function addEventListeners(cb) {
  eventsOfInterest.forEach(e => window.addEventListener(e, cb));
}

function removeEventListeners(cb) {
  eventsOfInterest.forEach(e => window.removeEventListener(e, cb));
}
