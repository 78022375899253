import { useAppContext } from "Context/AppProvider";
import FeedbackList from "./FeedbackList";
import PaddedContent from "Layouts/PaddedContent";
import "./Feedbacks.scss";
import SettingsConditional from "Components/SettingsConditional";

const Feedbacks = () => {
  const {
    tasksHandler: { error, feedbacks, pendingFeedbacks },
  } = useAppContext();

  const pending = pendingFeedbacks();
  const completedFeedbacks = feedbacks();
  return (
    <PaddedContent className="queue-show">
      {error && (
        <div className="alert alert-danger">
          <strong>{error}</strong>
        </div>
      )}
      <div className="queue-by-location">
        <div className="queue-header">
          <div className="navigation">
            <SettingsConditional flag={SettingsConditional.HOME_BUTTON} />
            <h2 className="queue-title">Feedbacks</h2>
            {pending.length === 0 && completedFeedbacks.length === 0 && (
              <i className="fa-solid fa-spinner text-primary queue-loader" />
            )}
          </div>
        </div>
        <div className="queue-column queue-top mt-3 ">
          <FeedbackList tasks={pending} title="Pending Feedbacks" />
        </div>
        <div className="queue-column queue-bottom">
          <FeedbackList tasks={completedFeedbacks} title="Finished Feedbacks" />
        </div>
      </div>
    </PaddedContent>
  );
};

export default Feedbacks;
