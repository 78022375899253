import requests from "Requests";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";

const useTags = (pageNumber, searchQuery, category) => {
  const queryClient = useQueryClient();
  const onSuccess = () => queryClient.invalidateQueries({ queryKey: ["tags"] });
  const { isLoading, error, data, refetch } = useQuery({
    queryKey: ["tags"],
    queryFn: () => requests.getTags(pageNumber, searchQuery, category),
  });
  const addMutation = useMutation({ mutationFn: requests.addTag, ...{ onSuccess } });
  const editMutation = useMutation({ mutationFn: requests.editTag, ...{ onSuccess } });
  const deleteMutation = useMutation({ mutationFn: requests.deleteTag, ...{ onSuccess } });
  const tags = data?.tags || [];

  return {
    tags: tags,
    lastPage: data?.last_page,
    learnerSelectableTags: tags.filter(t => t.settings["learnerSelectable"]),
    addTag: addMutation.mutate,
    editTag: editMutation.mutate,
    deleteTag: deleteMutation.mutate,
    isLoading,
    isUpdating: addMutation.isLoading || editMutation.isLoading || deleteMutation.isLoading,
    error,
    refetch,
  };
};

export default useTags;
