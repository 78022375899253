import { useEffect } from "react";
import classNames from "classnames";
import "./ModalBackdrop.scss";

const ModalBackdrop = ({ blur, center, children, className, ...props }) => {
  useEffect(() => {
    const handleKeydown = function (event) {
      // source can be found: https://uxdesign.cc/how-to-trap-focus-inside-modal-to-make-it-ada-compliant-6a50f9a70700
      // add all the elements inside modal which you want to make focusable
      const focusableElements =
        'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])';
      const modalContent = document.querySelector(".modal-content");
      const focusableContent = modalContent.querySelectorAll(focusableElements);
      // get first element to be focused inside modal
      const firstFocusableElement = focusableContent[0];
      // get last element to be focused inside modal
      const lastFocusableElement = focusableContent[focusableContent.length - 1];
      const isTabPressed = event.key === "Tab";
      if (!isTabPressed) {
        return;
      }
      if (event.shiftKey) {
        // if shift key pressed for shift + tab combination
        if (document.activeElement === firstFocusableElement) {
          lastFocusableElement.focus(); // add focus for the last focusable element
          event.preventDefault();
        }
      } else {
        // if tab key is pressed
        if (document.activeElement === lastFocusableElement) {
          // if focused has reached to last focusable element then focus first focusable element after pressing tab
          firstFocusableElement.focus(); // add focus for the first focusable element
          event.preventDefault();
        }
      }
    };

    document.addEventListener("keydown", handleKeydown);
    return () => document.removeEventListener("keydown", handleKeydown);
  }, []);
  return (
    <div
      className={classNames(
        "modal-backdrop",
        {
          "backdrop-blur": blur,
          "backdrop-center": center,
        },
        className
      )}
      {...props}
      role="dialog"
      aria-modal="true"
    >
      {children}
    </div>
  );
};

export default ModalBackdrop;
