import { useAppContext } from "Context/AppProvider";
import MentorDutyButton from "Components/MentorDutyButton";

const MentorNavigation = () => {
  const {
    settingsHandler: { settings },
    currentUser,
  } = useAppContext();
  if (!settings.features?.onDuty) return <></>;

  return <MentorDutyButton user={currentUser} />;
};

export default MentorNavigation;
