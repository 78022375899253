import { useEffect, useReducer } from "react";
import { createConsumer } from "@rails/actioncable";
import { isBrowserExtension } from "util/env";

const initialState = {
  socket: null,
  connected: false,
  action: null,
  lastMessage: null,
  onRecieved: [],
  onDisconnect: [],
  onRejected: [],
  onConnect: [],
};

const socketReducer = (state, action) => {
  switch (action.type) {
    case "recieved":
      return { ...state, action: "recieved", lastMessage: action.data };
    case "disconnected":
      return { ...state, connected: false, action: "disconnected" };
    case "connected":
      return { ...state, connected: true, action: "connected" };
    case "rejected":
      return { ...state, connected: false, action: "rejected" };
    case "reset":
      return { ...state, action: null };
    case "setRecievedSubscription":
      if (!Array.isArray(action.data)) {
        action.data = [action.data];
      }

      return { ...state, onRecieved: [...state.onRecieved, ...action.data] };
    case "setDisconnectedSubscription":
      if (!Array.isArray(action.data)) {
        action.data = [action.data];
      }
      return {
        ...state,
        onDisconnect: [...state.onDisconnect, ...action.data],
      };
    case "setConnectedSubscription":
      if (!Array.isArray(action.data)) {
        action.data = [action.data];
      }
      return { ...state, onConnect: [...state.onConnect, ...action.data] };
    case "setRejectedSubscription":
      if (!Array.isArray(action.data)) {
        action.data = [action.data];
      }
      return { ...state, onRejected: [...state.onRejected, ...action.data] };
    case "setSocket":
      return {
        ...state,
        socket: action.data,
        connected: !action.data.consumer.connection.disconnected,
      };
    default:
      throw new Error();
  }
};

const useSocketConnection = (channel, path) => {
  const [socketHandler, dispatchSocketHandler] = useReducer(socketReducer, initialState);

  useEffect(() => {
    // do not try to connect to WS in the sign-in page
    // if (location.pathname === "/sign-in") {
    //   return;
    // }
    if (isBrowserExtension()) {
      return;
    }

    if (!socketHandler.socket && channel && path) {
      const fullPath = `${path}/cable`;
      const consumer = window.rudderSocketConnection || createConsumer(fullPath);
      // NOTE: Using window obj for storing the WS consumer is important and deliberate here. Otherwise our socket connection is not TRULY singleton. For example (and we got burnt by this) if turbolinks is enabled on the site, this Effect could be triggered multiple times, in which case it would create a socket connection each time. That is not desirable. - KV
      window.rudderSocketConnection = consumer;
      let socket;

      const callbacks = {
        received(data) {
          dispatchSocketHandler({ type: "recieved", data });
        },
        disconnected() {
          dispatchSocketHandler({ type: "disconnected" });
        },
        connected() {
          dispatchSocketHandler({ type: "connected" });
        },
        rejected() {
          dispatchSocketHandler({ type: "rejected" });
        },
      };

      if (consumer.subscriptions.subscriptions[0]) {
        socket = consumer.subscriptions.subscriptions[0];
        socket.received = callbacks.received;
        socket.disconnected = callbacks.disconnected;
        socket.connected = callbacks.connected;
        socket.rejected = callbacks.rejected;
      } else {
        socket = consumer.subscriptions.create(channel, callbacks);
      }

      dispatchSocketHandler({ type: "setSocket", data: socket });

      return () => {
        if (consumer) {
          if (socket) {
            consumer.subscriptions.remove(socket);
          }
          consumer.disconnect();
        }
        window.rudderSocketConnection = null;
      };
    }
  }, [channel, path]);

  useEffect(() => {
    if (isBrowserExtension()) {
      return;
    }
    // handle subscriptions, seperate from reducer to keep it pure
    let subscriptions;
    let payload;
    switch (socketHandler.action) {
      case "recieved":
        subscriptions = socketHandler.onRecieved;
        payload = socketHandler.lastMessage;
        break;
      case "disconnected":
        subscriptions = socketHandler.onDisconnect;
        break;
      case "connected":
        subscriptions = socketHandler.onConnect;
        break;
      case "rejected":
        subscriptions = socketHandler.onRejected;
        break;
      default:
        break;
    }

    if (subscriptions) {
      for (let subscription of subscriptions) {
        subscription(payload);
      }
    }
  }, [
    socketHandler.action,
    socketHandler.lastMessage,
    socketHandler.onRecieved,
    socketHandler.onDisconnect,
    socketHandler.onConnect,
    socketHandler.onRejected,
  ]);

  const subscribeToConnect = callback => {
    dispatchSocketHandler({ type: "setConnectedSubscription", data: callback });
  };

  const subscribeToDisconnect = callback => {
    dispatchSocketHandler({
      type: "setDisconnectedSubscription",
      data: callback,
    });
  };

  const subscribeToReceived = callback => {
    dispatchSocketHandler({ type: "setRecievedSubscription", data: callback });
  };

  const subscribeToRejected = callback => {
    dispatchSocketHandler({ type: "setRejectedSubscription", data: callback });
  };

  return {
    subscribeToConnect,
    subscribeToDisconnect,
    subscribeToReceived,
    subscribeToRejected,
    connected: socketHandler.connected,
    socket: socketHandler.socket,
  };
};

export default useSocketConnection;
