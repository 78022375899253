import { useEffect, useState, useCallback } from "react";
import requests from "Requests";
import { useQuery } from "@tanstack/react-query";
import { isBrowserExtension } from "util/env";
import { storageSet } from "BrowserExtension/utils/storage";

/**
 * useQueueWaitTime Hook
 *
 * @param {number} arId
 * @param {string} apiUrl
 * @returns {{ waitTimeMinutes: number, queuePosition: number, formattedWaitTime: string, errorMessage: string, loading: boolean }}
 */
const useQueueWaitTime = (arId, apiUrl, maxWaitHours) => {
  const [error, setError] = useState(null);

  const getQueueWaitTime = useCallback(() => {
    if (!arId || !apiUrl) {
      return Promise.resolve({});
    }

    setError(null);
    return requests.getWaitTime(arId, apiUrl);
  }, [arId, apiUrl]);

  const {
    isFetching: loading,
    data: {
      position_in_queue: queuePosition,
      wait_time: waitTimeMinutes,
      error_code: errorCode,
    } = {},
  } = useQuery({
    queryKey: ["queueWaitInfo"],
    queryFn: getQueueWaitTime,
    ...{
      refetchInterval: 2 * 60 * 1000,
      refetchIntervalInBackground: true,
      onError: error => {
        console.error("Failed to fetch data from the Queue Predictor API", error);
        setError(error);
      },
    },
  });

  useEffect(() => {
    if (isBrowserExtension()) storageSet({ arId: arId, queueWaitTimeApiUrl: apiUrl });
  }, [apiUrl, arId]);

  const longWait =
    waitTimeMinutes !== undefined && maxWaitHours !== undefined
      ? waitTimeMinutes / 60 > maxWaitHours
      : false;

  const getRemainingMinutes = waitMinutes => {
    const remainderMinutes = waitMinutes % 60;
    if (remainderMinutes) return `${remainderMinutes}min`;

    return "";
  };

  const getFormattedWaitTime = waitMinutes => {
    if (waitTimeMinutes === undefined) return "";

    if (waitMinutes < 60) {
      return `${waitMinutes}min`;
    } else {
      return `${Math.floor(waitMinutes / 60)}h ${getRemainingMinutes(waitMinutes)}`;
    }
  };

  const getErrorMessage = errorCode => {
    if (errorCode === undefined) return "";

    switch (errorCode) {
      case 1:
        return "Assistance request cancelled";
      case 2:
        return "A mentor is currently taking care of your assistance request";
      case 3:
        return "The assistance is complete!";
      case 4:
        return "Sorry, there are no mentors available at the moment";
      case 5:
        return "Estimations are only available for 'Activity' type assistance requests";
      default:
        return "Sorry, we've encountered an error, no wait time estimations are available at this time";
    }
  };

  return {
    waitTimeMinutes,
    queuePosition,
    formattedWaitTime: getFormattedWaitTime(waitTimeMinutes),
    errorMessage: getErrorMessage(error || errorCode),
    loading,
    longWait,
    maxWaitHours,
  };
};

export default useQueueWaitTime;
