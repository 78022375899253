import { useState } from "react";
import { useAppContext } from "Context/AppProvider";
import ModalBackdrop from "./ModalBackdrop";
import StudentInfo from "./StudentInfo";
import { Button, Alert } from "react-bootstrap";
import AddStudentsSelect from "Components/AddStudentsSelect";
import "./AddStudentsModal.scss";
import Modal from "./Modal";
import ModalButton from "./Buttons/ModalButtons";

const AddStudentsModal = ({ taskARs, addStudents, hide, skip }) => {
  const {
    studentHandler: { students, error: studentsError },
  } = useAppContext();
  const [willAddStudents, setWillAddStudents] = useState(false);
  const [step, setStep] = useState(0);
  const [selectedStudents, setSelectedStudents] = useState([]);
  const [addStudentsError, setAddStudentsError] = useState("");
  const [submitting, setSubmitting] = useState(false);

  const assistedStudents = taskARs
    .map(ar => {
      const student = students.find(s => s.uid === ar.requestor.uid);
      if (!student) {
        console.error("Student could not be found for AR: ", ar);
      }
      return student;
    })
    .filter(s => !!s);
  const assistedStudentsInfo = assistedStudents.map((student, i) => (
    <StudentInfo
      student={student}
      when={student.lastAssistedAt}
      is_lead={i === 0}
      key={i}
      showDetails
    />
  ));

  const closeModal = () => {
    setWillAddStudents(false);
    setSelectedStudents([]);
    hide();
  };

  const selectStudent = student => {
    setSelectedStudents([...selectedStudents, student]);
  };

  const deselectStudent = student => {
    setSelectedStudents(selectedStudents.filter(s => s.uid !== student.uid));
  };

  const handleAddStudents = () => {
    setAddStudentsError("");
    setSubmitting(true);
    addStudents(selectedStudents).catch(() => {
      setSubmitting(false);
      setAddStudentsError("Could not add students to assistance! Please try again.");
    });
  };

  const selectedStudentButtons = selectedStudents.map((student, i) => (
    <Button
      className="student-button"
      variant="info"
      size="sm"
      onClick={() => deselectStudent(student)}
      key={i}
    >
      {student.fullName}&nbsp;&times;
    </Button>
  ));

  const assistedStudentButtons = assistedStudents.map((student, i) => (
    <Button className="student-button" variant="secondary" size="sm" disabled key={i}>
      {student.fullName}
    </Button>
  ));

  const assistedStudentsPage = (
    <>
      <p>You assisted the following students:</p>
      <div className="students-assisted">
        <div className="student-info">{assistedStudentsInfo}</div>
      </div>
      <div className="form-check">
        <input
          type="checkbox"
          className="form-check-input"
          checked={willAddStudents}
          onChange={e => setWillAddStudents(e.target.checked)}
          id="add-students-input"
        />
        <label htmlFor="add-students-input" className="form-check-label">
          Were other students present for this assistance?
        </label>
      </div>
      <div className="button-container">
        <div></div>
        {willAddStudents ? (
          <ModalButton.Submit onClick={() => setStep(1)} text="Select Students" />
        ) : (
          <ModalButton.Submit onClick={skip} text="Continue" />
        )}
      </div>
    </>
  );

  const selectStudentsPage = (
    <>
      {studentsError && <Alert variant="danger">{studentsError}</Alert>}
      <AddStudentsSelect
        assistedStudentUIDs={assistedStudents.map(s => s.uid)}
        selectedStudentUIDs={selectedStudents.map(s => s.uid)}
        selectStudent={selectStudent}
        students={students}
      />
      <div>Students in assistance:</div>
      <div className="student-button-list">{assistedStudentButtons}</div>
      <div>Students to add:</div>
      <div className="student-button-list">{selectedStudentButtons}</div>
      <div className="button-container">
        <ModalButton.Back text="Back" onClick={() => setStep(0)} />
        <ModalButton.Submit
          text="Add Students and Continue"
          onClick={handleAddStudents}
          disabled={submitting || selectedStudents.length < 1}
        />
      </div>
    </>
  );

  const pages = [assistedStudentsPage, selectStudentsPage];
  const pageTitles = ["Add Students?", "Select Students"];

  return (
    <ModalBackdrop>
      <Modal title={pageTitles[step]} closeModal={closeModal}>
        {addStudentsError && <Alert variant="danger">{addStudentsError}</Alert>}
        {pages[step]}
      </Modal>
    </ModalBackdrop>
  );
};

export default AddStudentsModal;
