import "Assets/styles/whole-screen.scss";
import Spinner from "Components/Spinner";

const Loading = () => {
  return (
    <div className="whole-screen d-flex justify-content-center align-items-center">
      <Spinner />
      &nbsp;Loading...
    </div>
  );
};

export default Loading;
