import { useQuery } from "@tanstack/react-query";
import requests from "Requests";

const useLearnerWorkContext = () => {
  const { isFetching: loading, data: { errors = {}, ...workContext } = {} } = useQuery({
    queryKey: ["workContext"],
    queryFn: requests.getLearnerWorkContext,
    ...{
      refetchOnWindowFocus: false,
    },
  });

  const findActivityByUuid = uuid => {
    const { selectableActivities } = workContext;
    if (selectableActivities === undefined || Object.keys(selectableActivities) < 1) return {};

    const activityList = Object.keys(selectableActivities).reduce(
      (list, group) => list.concat(selectableActivities[group].activities),
      []
    );

    const result = activityList.find(a => a.uuid === uuid);
    return result || {};
  };

  return {
    workContext,
    findActivityByUuid,
    loading,
    errors,
  };
};

export default useLearnerWorkContext;
