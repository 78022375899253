import { Tooltip, OverlayTrigger } from "react-bootstrap";
import { determineConferenceTypeName, determineConferenceTypeIcon } from "util/styles";
import "./AssistanceButtons.scss";

const Main = ({ text, onClick, disabled }) => {
  return (
    <button className={"queue-btn main"} onClick={onClick} disabled={disabled}>
      {text}
    </button>
  );
};

const External = ({ text, url, disabled }) => {
  return (
    <button className="queue-btn external" onClick={() => window.open(url)} disabled={disabled}>
      {text || "Open"}
    </button>
  );
};

const Light = ({ text, onClick, disabled }) => {
  return (
    <button className="queue-btn light" onClick={onClick} disabled={disabled}>
      {text}
    </button>
  );
};

const Warn = ({ text, onClick, disabled }) => {
  return (
    <button className="queue-btn warning" onClick={onClick} disabled={disabled}>
      {text}
    </button>
  );
};

const Success = ({ text, onClick, disabled }) => {
  return (
    <button className="queue-btn success" onClick={onClick} disabled={disabled}>
      {text}
    </button>
  );
};

const Danger = ({ text, onClick, disabled }) => {
  return (
    <button className="queue-btn danger" onClick={onClick} disabled={disabled}>
      {text}
    </button>
  );
};

const Info = ({ text, onClick, disabled }) => {
  return (
    <button className="queue-btn info" onClick={onClick} disabled={disabled}>
      {text}
    </button>
  );
};

const Skip = ({ text, onClick, disabled }) => {
  return (
    <button
      className={`queue-btn skip ${disabled ? "disabled" : ""}`}
      onClick={onClick}
      disabled={disabled}
    >
      {text || "Skip"}
    </button>
  );
};

const Manual = ({ text, onClick }) => {
  return (
    <button className="queue-btn manual" onClick={onClick}>
      {text}
    </button>
  );
};

const ConferenceButton = ({ url, disabled, type, tooltip }) => (
  <OverlayTrigger placement="top" overlay={<Tooltip>{tooltip}</Tooltip>}>
    <a href={url} target="_blank" rel="noopener noreferrer" disabled={disabled}>
      <button
        className={`queue-btn conference ${type?.toLowerCase() + " " + tooltip?.toLowerCase()}`}
        disabled={disabled}
      >
        {determineConferenceTypeIcon(type)} &nbsp; {tooltip}
      </button>
    </a>
  </OverlayTrigger>
);

const ConferenceLink = ({ url, disabled, type, title }) => {
  if (type === "DiscordForum") {
    return (
      <>
        <ConferenceButton url={url} disabled={disabled} type={type} tooltip="Browser" />
        <ConferenceButton
          url={url.replace(/https?:\/\//, "discord://")}
          disabled={disabled}
          type={type}
          tooltip="App"
        />
      </>
    );
  }

  return (
    <ConferenceButton
      url={url}
      disabled={disabled}
      type={type}
      tooltip={title || determineConferenceTypeName(type)}
    />
  );
};

const OpenModal = ({ type, onClick, disabled }) => {
  const determineIcon = type => {
    switch (type) {
      case "code":
        return <i className="fa-solid fa-code" />;
      case "error":
        return <i className="fa-solid fa-triangle-exclamation" />;
      default:
        return <i className="fa-solid fa-circle-info" />;
    }
  };

  return (
    <button className="queue-btn view-details" onClick={onClick} disabled={disabled}>
      {determineIcon(type)}
    </button>
  );
};

const Button = {
  Main,
  External,
  Light,
  Warn,
  Success,
  Danger,
  Info,
  ConferenceLink,
  OpenModal,
  Skip,
  Manual,
};

export default Button;
