import QueueItem from "Components/QueueItem";
import StudentInfo from "Components/StudentInfo";
import RequestDetails from "Components/RequestDetails";
import SkipRequestModal from "Components/SkipRequestModal";
import Button from "Components/Buttons/AssistanceButtons";
import { useState } from "react";
import { useAppContext } from "Context/AppProvider";
import { toast } from "react-toastify";
import { TOAST_OPTIONS } from "constants/toast";
import Requests from "Requests";
import { Tooltip, OverlayTrigger } from "react-bootstrap";

const PendingEvaluation = ({ assistanceRequest }) => {
  const {
    queueChannel: { claimAssistance },
    tasksHandler,
    settingsHandler: { settings },
  } = useAppContext();

  const [disabled, setDisabled] = useState(false);
  const [showSkipRequestModal, setShowSkipRequestModal] = useState(false);
  const mentorSkip = settings?.featureFlags?.mentorSkip;
  const createdAt = new Date(assistanceRequest.createdAt);
  const twentyFourHoursAgo = new Date(Date.now() - 24 * 60 * 60 * 1000); // 24 hours in milliseconds
  const isPriority = assistanceRequest.priority === -1 || createdAt < twentyFourHoursAgo;

  const handleSkipAR = () => setShowSkipRequestModal(true);
  const handleClaimAR = () => {
    setDisabled(true);
    claimAssistance(assistanceRequest)
      .then(() => tasksHandler.claimAssistance(assistanceRequest.id))
      .catch(err => {
        setDisabled(false);
        toast.error(err.response?.data?.error, TOAST_OPTIONS.error);
      });
  };

  const skipRequest = (reason, notes) => {
    setDisabled(true);

    const queueTask = tasksHandler
      .allOpenSecondaryTasksForUser()
      .find(task => task.assistanceRequest.id === assistanceRequest.id);

    Requests.skipRequest(queueTask.id, reason, notes)
      .then(() => tasksHandler.skipRequest(queueTask.id))
      .catch(err => {
        setDisabled(false);
        toast.error(err.response?.data?.error, TOAST_OPTIONS.error);
      })
      .finally(setDisabled(false));
  };

  const isSinglePendingRequest =
    tasksHandler
      .pendingAssistanceRequests()
      .filter(ar =>
        tasksHandler
          .allOpenSecondaryTasks()
          .some(task => task.assistanceRequest.id === ar.id && !task.skippedAt)
      ).length === 1;

  const hoverText = (
    <Tooltip id="tooltip">
      <strong>Cannot skip</strong> when you have only one assigned task remaining.
    </Tooltip>
  );

  const skipButton = (
    <Button.Skip
      onClick={isSinglePendingRequest || disabled ? null : handleSkipAR}
      disabled={isSinglePendingRequest}
    />
  );

  const renderSkipButton = () =>
    isSinglePendingRequest ? (
      <OverlayTrigger placement="left" overlay={hoverText}>
        <span>{skipButton}</span>
      </OverlayTrigger>
    ) : (
      skipButton
    );

  return (
    <>
      <QueueItem label="evaluation" type="Evaluation" priority={isPriority}>
        <StudentInfo
          student={assistanceRequest.requestor}
          showDetails={true}
          when={assistanceRequest.createdAt}
          tags={assistanceRequest.tags}
          excludeStudentTags
        />
        <div className="blurb">
          <RequestDetails
            categoryName={assistanceRequest.request.categoryName}
            day={assistanceRequest.day}
            activityUrl={assistanceRequest.request.resourceLink}
            activityTitle={assistanceRequest.request.resourceName}
            reason={assistanceRequest.request.reason}
            resubmission={assistanceRequest.request.resubmission}
            priority={assistanceRequest.priority}
          />
        </div>
        <div className="actions float-right">
          {mentorSkip && renderSkipButton()}
          <Button.Warn text="Start" onClick={handleClaimAR} disabled={disabled} />
        </div>
      </QueueItem>
      {showSkipRequestModal && (
        <SkipRequestModal
          requestType="task"
          skip={skipRequest}
          hide={() => setShowSkipRequestModal(false)}
        ></SkipRequestModal>
      )}
    </>
  );
};

export default PendingEvaluation;
