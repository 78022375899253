import { useQuery } from "@tanstack/react-query";
import { useState, useEffect } from "react";
import requests from "Requests";
import { DateTime } from "luxon";

const useLearnerQueueSchedule = () => {
  const { data: queueSchedule } = useQuery({
    queryKey: ["queueSchedule"],
    queryFn: requests.getLearnerQueueSchedule,
  });
  const [queueClosed, setQueueClosed] = useState(false);

  useEffect(() => {
    // if there is no queue schedule for the deployment, or if qs is not enabled for the deployment
    // queue should always be open
    if (!queueSchedule || !queueSchedule.enabled) return setQueueClosed(false);

    // if force schedule closed is turned on queue should always be closed
    if (queueSchedule.forceScheduleClose) return setQueueClosed(true);

    const updateAccessStatus = () => {
      const time = DateTime.now().setZone(queueSchedule.timezone);
      const integerTime = time.hour * 100 + time.minute;

      setQueueClosed(integerTime < queueSchedule.start || integerTime >= queueSchedule.end);
    };

    const interval = setInterval(updateAccessStatus, 60000);
    updateAccessStatus();

    return () => clearInterval(interval);
  }, [queueSchedule]);

  const getQueueSchedule = () => {
    if (!queueSchedule) return undefined;

    return {
      ...queueSchedule,
      startTime: queueSchedule.enabled ? new Date(queueSchedule.startTime) : undefined,
    };
  };

  return {
    queueClosed,
    queueSchedule: getQueueSchedule(),
  };
};

export default useLearnerQueueSchedule;
